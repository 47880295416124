const satnarayanaKatha = [
    {
        title: `पहला अध्याय`,
        description: `एक समय की बात है नैमिषारण्य तीर्थ में शौनिकादि, अठ्ठासी हजार ऋषियों ने श्री सूतजी से पूछा हे प्रभु! इस कलियुग में वेद विद्या रहित मनुष्यों को प्रभु भक्ति किस प्रकार मिल सकती है? 
                              तथा उनका उद्धार कैसे होगा? हे मुनि श्रेष्ठ ! कोई ऐसा तप बताइए जिससे थोड़े समय में ही पुण्य मिलें और मनवांछित फल भी मिल जाए। इस प्रकार की कथा सुनने की हम इच्छा रखते हैं।
                              सर्व शास्त्रों के ज्ञाता सूत जी बोले - हे वैष्णवों में पूज्य ! आप सभी ने प्राणियों के हित की बात पूछी है इसलिए मैं एक ऐसे श्रेष्ठ व्रत को आप लोगों को बताऊँगा जिसे नारद जी ने 
                              लक्ष्मीनारायण जी से पूछा था और लक्ष्मीपति ने मुनिश्रेष्ठ नारद जी से कहा था। आप सब इसे ध्यान से सुनिए -
                              एक समय की बात है, योगीराज नारद जी दूसरों के हित की इच्छा लिए अनेकों लोको में घूमते हुए मृत्युलोक में आ पहुँचे। यहाँ उन्होंने अनेक योनियों में जन्मे प्राय: 
                              सभी मनुष्यों को अपने कर्मों द्वारा अनेकों दुखों से पीड़ित देखा। उनका दुःख देख नारद जी सोचने लगे कि कैसा यत्न किया जाए जिसके करने से निश्चित रुप से मानव के दुखों का 
                              अंत हो जाए। इसी विचार पर मनन करते हुए वह विष्णुलोक में गए। वहाँ वह देवों के ईश भगवान् नारायण की स्तुति करने लगे जिनके हाथों में शंख, चक्र, गदा और पद्म थे, 
                              गले में वरमाला पहने हुए थे।
                              स्तुति करते हुए नारद जी बोले - हे भगवान! आप अत्यंत शक्ति से संपन्न हैं, मन तथा वाणी भी आपको नहीं पा सकती हैं। आपका आदि, मध्य तथा अंत नहीं है। निर्गुण स्वरुप सृष्टि के 
                              कारण भक्तों के दुःख को दूर करने वाले, आपको मेरा नमस्कार है।
                              नारद जी की स्तुति सुन विष्णु भगवान बोले - हे मुनिश्रेष्ठ! आपके मन में क्या बात है? आप किस काम के लिए पधारे हैं? उसे नि:संकोच कहो। इस पर नारद मुनि बोले कि मृत्युलोक में 
                              अनेक योनियों में जन्मे मनुष्य अपने कर्मों के द्वारा अनेको दुःख से दुखी हो रहे हैं। हे नाथ! आप मुझ पर दया रखते हैं तो बताइए कि वो मनुष्य थोड़े प्रयास से ही अपने दुखों से कैसे 
                              छुटकारा पा सकते हैं।
                              श्रीहरि बोले - हे नारद! मनुष्यों की भलाई के लिए तुमने बहुत अच्छी बात पूछी है। जिसके करने से मनुष्य मोह से छूट जाता है, वह बात मैं कहता हूँ उसे सुनो। स्वर्ग लोक व मृत्युलोक दोनों 
                              में एक दुर्लभ उत्तम व्रत है जो पुण्य देने वाला है। आज प्रेमवश होकर मैं उसे तुमसे कहता हूँ। श्रीसत्यनारायण भगवान का यह व्रत अच्छी तरह विधानपूर्वक करके मनुष्य तुरंत ही यहाँ सुख भोग कर, मरने पर मोक्ष पाता है।
                              श्रीहरि के वचन सुन नारद जी बोले कि उस व्रत का फल क्या है? और उसका विधान क्या है? यह व्रत किसने किया था? इस व्रत को किस दिन करना चाहिए? सभी कुछ विस्तार से बताएँ।
                              नारद की बात सुनकर श्रीहरि बोले - दुःख व शोक को दूर करने वाला यह व्रत सभी स्थानों पर विजय दिलाने वाला है। मानव को भक्ति व श्रद्धा के साथ शाम को श्रीसत्यनारायण की पूजा धर्म 
                              परायण होकर ब्राह्मणों व बंधुओं के साथ करनी चाहिए। भक्ति भाव से ही नैवेद्य, केले का फल, घी, दूध और गेहूँ का आटा सवाया लें। गेहूँ के स्थान पर साठी का आटा, शक्कर तथा गुड़ 
                              लेकर व सभी भक्षण योग्य पदार्थो को मिलाकर भगवान का भोग लगाएँ।..
                              ..ब्राह्मणों सहित बंधु-बाँधवों को भी भोजन कराएँ, उसके बाद स्वयं भोजन करें। भजन, कीर्तन के साथ भगवान की भक्ति में लीन हो जाएं। इस तरह से सत्यनारायण भगवान का यह व्रत करने
                               पर मनुष्य की सारी इच्छाएँ निश्चित रुप से पूरी होती हैं। इस कलि काल अर्थात कलियुग में मृत्युलोक में मोक्ष का यही एक सरल उपाय बताया गया है।`,
        end: `॥ इति श्री सत्यनारायण व्रत कथा का प्रथम अध्याय संपूर्ण ।।`
    },
    {
        title: `दूसरा अध्याय `,
        description: `सूत जी बोले - हे ऋषियों ! जिसने पहले समय में इस व्रत को किया था उसका इतिहास कहता हूँ, ध्यान से सुनो! सुंदर काशीपुरी नगरी में एक अत्यंत निर्धन ब्राह्मण रहता था। 
                              भूख प्यास से परेशान वह धरती पर घूमता रहता था। ब्राह्मणों से प्रेम से प्रेम करने वाले भगवान ने एक दिन ब्राह्मण का वेश धारण कर उसके पास जाकर पूछा - हे विप्र! नित्य दुखी होकर तुम पृथ्वी पर क्यूँ घूमते हो?
                              दीन ब्राह्मण बोला - मैं निर्धन ब्राह्मण हूँ। भिक्षा के लिए धरती पर घूमता हूँ। हे भगवान !यदि आप इसका कोई उपाय जानते हो तो बताइए। वृद्ध ब्राह्मण कहता है कि सत्यनारायण भगवान मनोवांछित फल देने 
                              वाले हैं इसलिए तुम उनका पूजन करो। इसे करने से मनुष्य सभी दुखों से मुक्त हो जाता है। वृद्ध ब्राह्मण बनकर आए सत्यनारायण भगवान उस निर्धन ब्राह्मण को व्रत का सारा विधान बताकर अन्तर्धान हो गए।
                                ब्राह्मण मन ही मन सोचने लगा कि जिस व्रत को वृद्ध ब्राह्मण करने को कह गया है मैं उसे जरुर करूँगा। यह निश्चय करने के बाद उसे रात में नींद नहीं आई। वह सवेरे उठकर सत्यनारायण भगवान के व्रत का निश्चय कर 
                                भिक्षा के लिए चला गया। उस दिन निर्धन ब्राह्मण को भिक्षा में बहुत धन मिला। जिससे उसने बंधु-बाँधवों के साथ मिलकर श्री सत्यनारायण भगवान का व्रत संपन्न किया।
                                भगवान सत्यनारायण का व्रत संपन्न करने के बाद वह निर्धन ब्राह्मण सभी दुखों से छूट गया और अनेक प्रकार की संपत्तियों से युक्त हो गया। उसी समय से यह ब्राह्मण हर माह इस व्रत को करने लगा। 
                                इस तरह से सत्यनारायण भगवान के व्रत को जो मनुष्य करेगा वह सभी प्रकार के पापों से छूटकर मोक्ष को प्राप्त होगा। जो मनुष्य इस व्रत को करेगा वह भी सभी दुखों से मुक्त हो जाएगा।
                                सूत जी बोले कि इस तरह से नारद जी से नारायण जी का कहा हुआ श्रीसत्यनारायण व्रत को मैने तुमसे कहा। हे विप्रो ! मैं अब और क्या कहूँ?
                                ऋषि बोले - हे मुनिवर ! संसार में उस विप्र से सुनकर और किस-किस ने इस व्रत को किया, हम सब इस बात को सुनना चाहते हैं। इसके लिए हमारे मन में श्रद्धा का भाव है।
                                सूत जी बोले - हे मुनियों! जिस-जिस ने इस व्रत को किया है, वह सब सुनो!
                                एक समय वही विप्र धन व ऐश्वर्य के अनुसार अपने बंधु-बाँधवों के साथ इस व्रत को करने को तैयार हुआ। उसी समय एक लकड़ी बेचने वाला बूढ़ा आदमी आया और लकड़ियाँ बाहर रखकर अंदर ब्राह्मण के घर में गया। 
                                प्यास से दुखी वह लकड़हारा उनको व्रत करते देख विप्र को नमस्कार कर पूछने लगा कि आप यह क्या कर रहे हैं तथा इसे करने से क्या फल मिलेगा? कृपया मुझे भी बताएँ।
                                ब्राह्मण ने कहा कि सब मनोकामनाओं को पूरा करने वाला यह सत्यनारायण भगवान का व्रत है। इनकी कृपा से ही मेरे घर में धन धान्य आदि की वृद्धि हुई है।
                                विप्र से सत्यनारायण व्रत के बारे में जानकर लकड़हारा बहुत प्रसन्न हुआ। चरणामृत लेकर व प्रसाद खाने के बाद वह अपने घर गया। लकड़हारे ने अपने मन में संकल्प किया कि आज लकड़ी बेचने से जो धन मिलेगा 
                                उसी से श्रीसत्यनारायण भगवान का उत्तम व्रत करूँगा। मन में इस विचार को ले बूढ़ा आदमी सिर पर लकड़ियाँ रख उस नगर में बेचने गया जहाँ धनी लोग ज्यादा रहते थे। उस नगर में उसे अपनी लकड़ियों का दाम पहले से चार गुना अधिक मिलता है।
                                बूढ़ा प्रसन्नता के साथ दाम लेकर केले, शक्कर, घी, दूध, दही और गेहूँ का आटा ले और सत्यनारायण भगवान के व्रत की अन्य सामग्रियाँ लेकर अपने घर गया। वहाँ उसने अपने बंधु-बाँधवों को बुलाकर 
                                विधि विधान से सत्यनारायण भगवान का पूजन और व्रत किया। इस व्रत के प्रभाव से वह बूढ़ा लकड़हारा धन पुत्र आदि से युक्त होकर संसार के समस्त सुख भोग अंत काल में बैकुंठ धाम चला गया।`,
        end: `॥ इति श्री सत्यनारायण व्रत कथा का द्वितीय अध्याय संपूर्ण ।।`
    },
    {
        title: `तीसरा अध्याय`,
        description: `सूतजी बोले: हे श्रेष्ठ मुनियों, अब आगे की कथा कहता हूँ। पहले समय में उल्कामुख नाम का एक बुद्धिमान राजा था। वह सत्यवक्ता और जितेन्द्रिय था। प्रतिदिन देव स्थानों पर जाता और निर्धनों को धन देकर उनके कष्ट दूर करता था। उसकी पत्नी कमल के समान मुख वाली तथा सती साध्वी थी। भद्रशीला नदी के तट पर उन दोनो ने श्रीसत्यनारायण भगवान का व्रत किया। उसी समय साधु नाम का एक वैश्य आया। उसके पास व्यापार करने के लिए बहुत सा धन भी था। राजा को व्रत करते देखकर वह विनय के साथ पूछने लगा: हे राजन ! भक्तिभाव से पूर्ण होकर आप यह क्या कर रहे हैं? मैं सुनने की इच्छा रखता हूँ तो आप मुझे बताएँ।
                                राजा बोला: हे साधु! अपने बंधु-बाँधवों के साथ पुत्रादि की प्राप्ति के लिए एक महाशक्तिमान श्रीसत्यनारायण भगवान का व्रत व पूजन कर रहा हूँ। राजा के वचन सुन साधु आदर से बोला: हे राजन ! मुझे इस व्रत का सारा विधान कहिए। आपके कथनानुसार मैं भी इस व्रत को करुँगा। मेरी भी संतान नहीं है और इस व्रत को करने से निश्चित रुप से मुझे संतान की प्राप्ति होगी। राजा से व्रत का सारा विधान सुन, व्यापार से निवृत हो वह अपने घर गया।
                                साधु वैश्य ने अपनी पत्नी को संतान देने वाले इस व्रत का वर्णन कह सुनाया और कहा कि जब मेरी संतान होगी तब मैं इस व्रत को करुँगा। साधु ने इस तरह के वचन अपनी पत्नी लीलावती से कहे। एक दिन लीलावती पति के साथ आनन्दित हो सांसारिक धर्म में प्रवृत होकर सत्यनारायण भगवान की कृपा से गर्भवती हो गई। दसवें महीने में उसके गर्भ से एक सुंदर कन्या ने जन्म लिया। दिनोंदिन वह ऎसे बढ़ने लगी जैसे कि शुक्ल पक्ष का चंद्रमा बढ़ता है। माता-पिता ने अपनी कन्या का नाम कलावती रखा।
                                एक दिन लीलावती ने मीठे शब्दों में अपने पति को याद दिलाया कि आपने सत्यनारायण भगवान के जिस व्रत को करने का संकल्प किया था उसे करने का समय आ गया है, आप इस व्रत को करिये। साधु बोला कि हे प्रिये! इस व्रत को मैं उसके विवाह पर करुँगा। इस प्रकार अपनी पत्नी को आश्वासन देकर वह नगर को चला गया। कलावती पिता के घर में रह वृद्धि को प्राप्त हो गई। साधु ने एक बार नगर में अपनी कन्या को सखियों के साथ देखा तो तुरंत ही दूत को बुलाया और कहा कि मेरी कन्या के योग्य वर देख कर आओ। साधु की बात सुनकर दूत कंचन नगर में पहुंचा और वहाँ देखभाल कर लड़की के सुयोग्य वाणिक पुत्र को ले आया। सुयोग्य लड़के को देख साधु ने बंधु-बाँधवों को बुलाकर अपनी पुत्री का विवाह कर दिया लेकिन दुर्भाग्य की बात ये कि साधु ने अभी भी श्रीसत्यनारायण भगवान का व्रत नहीं किया।
                                इस पर श्री भगवान क्रोधित हो गए और श्राप दिया कि साधु को अत्यधिक दुख मिले। अपने कार्य में कुशल साधु बनिया जमाई को लेकर समुद्र के पास स्थित होकर रत्नासारपुर नगर में गया। वहाँ जाकर दामाद-ससुर दोनों मिलकर चन्द्रकेतु राजा के नगर में व्यापार करने लगे। एक दिन भगवान सत्यनारायण की माया से एक चोर राजा का धन चुराकर भाग रहा था। उसने राजा के सिपाहियों को अपना पीछा करते देख चुराया हुआ धन वहाँ रख दिया जहाँ साधु अपने जमाई के साथ ठहरा हुआ था। राजा के सिपाहियों ने साधु वैश्य के पास राजा का धन पड़ा देखा तो वह ससुर-जमाई दोनों को बाँधकर प्रसन्नता से राजा के पास ले गए और कहा कि उन दोनों चोरों हम पकड़ लाएं हैं, आप आगे की कार्यवाही की आज्ञा दें।
                                राजा की आज्ञा से उन दोनों को कठिन कारावास में डाल दिया गया और उनका सारा धन भी उनसे छीन लिया गया। श्रीसत्यनारायण भगवान से श्राप से साधु की पत्नी भी बहुत दुखी हुई। घर में जो धन रखा था उसे चोर चुरा ले गए। शारीरिक तथा मानसिक पीड़ा व भूख प्यास से अति दुखी हो अन्न की चिन्ता में कलावती के ब्राह्मण के घर गई। वहाँ उसने श्रीसत्यनारायण भगवान का व्रत होते देखा फिर कथा भी सुनी वह प्रसाद ग्रहण कर वह रात को घर वापिस आई। माता के कलावती से पूछा कि हे पुत्री अब तक तुम कहाँ थी़? तेरे मन में क्या है?
                                कलावती ने अपनी माता से कहा: हे माता ! मैंने एक ब्राह्मण के घर में श्रीसत्यनारायण भगवान का व्रत देखा है। कन्या के वचन सुन लीलावती भगवान के पूजन की तैयारी करने लगी। लीलावती ने परिवार व बंधुओं सहित सत्यनारायण भगवान का पूजन किया और उनसे वर माँगा कि मेरे पति तथा जमाई शीघ्र घर आ जाएँ। साथ ही यह भी प्रार्थना की कि हम सब का अपराध क्षमा करें। श्रीसत्यनारायण भगवान इस व्रत से संतुष्ट हो गए और राजा चन्द्रकेतु को सपने में दर्शन दे कहा कि: हे राजन ! तुम उन दोनो वैश्यों को छोड़ दो और तुमने उनका जो धन लिया है उसे वापिस कर दो। अगर ऎसा नहीं किया तो मैं तुम्हारा धन राज्य व संतान सभी को नष्ट कर दूँगा। राजा को यह सब कहकर वह अन्तर्धान हो गए।
                                प्रात:काल सभा में राजा ने अपना सपना सुनाया फिर बोले कि बणिक पुत्रों को कैद से मुक्त कर सभा में लाओ। दोनो ने आते ही राजा को प्रणाम किया। राजा मीठी वाणी में बोला: हे महानुभावों ! भाग्यवश ऎसा कठिन दुख तुम्हें प्राप्त हुआ है लेकिन अब तुम्हें कोई भय नहीं है। ऎसा कह राजा ने उन दोनों को नए वस्त्राभूषण भी पहनाए और जितना धन उनका लिया था उससे दुगुना धन वापिस कर दिया। दोनो वैश्य अपने घर को चल दिए।`,
        end: `॥ श्रीसत्यनारायण भगवान व्रत कथा का तृतीय अध्याय संपूर्ण ।।`
    },
    {
        title: `चतुर्थ अध्याय`,
        description: `सूतजी बोले: वैश्य ने मंगलाचार कर अपनी यात्रा आरंभ की और अपने नगर की ओर चल दिए। उनके थोड़ी दूर जाने पर एक दण्डी वेशधारी श्रीसत्यनारायण ने उनसे पूछा: हे साधु तेरी नाव में क्या है? अभिवाणी वणिक हंसता हुआ बोला: हे दण्डी ! आप क्यों पूछते हो? क्या धन लेने की इच्छा है? मेरी नाव में तो बेल व पत्ते भरे हुए हैं। वैश्य के कठोर वचन सुन भगवान बोले: तुम्हारा वचन सत्य हो! दण्डी ऎसे वचन कह वहाँ से दूर चले गए। कुछ दूर जाकर समुद्र के किनारे बैठ गए। दण्डी के जाने के बाद साधु वैश्य ने नित्य क्रिया के पश्चात नाव को ऊँची उठते देखकर अचंभा माना और नाव में बेल-पत्ते आदि देख वह मूर्छित हो जमीन पर गिर पड़ा।
                            मूर्छा खुलने पर वह अत्यंत शोक में डूब गया तब उसका दामाद बोला कि आप शोक ना मनाएँ, यह दण्डी का शाप है इसलिए हमें उनकी शरण में जाना चाहिए तभी हमारी मनोकामना पूरी होगी। दामाद की बात सुनकर वह दण्डी के पास पहुँचा और अत्यंत भक्तिभाव नमस्कार कर के बोला: मैंने आपसे जो जो असत्य वचन कहे थे उनके लिए मुझे क्षमा दें, ऎसा कह कहकर महान शोकातुर हो रोने लगा तब दण्डी भगवान बोले: हे वणिक पुत्र ! मेरी आज्ञा से बार-बार तुम्हें दुख प्राप्त हुआ है। तू मेरी पूजा से विमुख हुआ। साधु बोला: हे भगवान ! आपकी माया से ब्रह्मा आदि देवता भी आपके रूप को नहीं जानते तब मैं अज्ञानी कैसे जान सकता हूँ। आप प्रसन्न होइए, अब मैं सामर्थ्य के अनुसार आपकी पूजा करूँगा। मेरी रक्षा करो और पहले के समान नौका में धन भर दो।
                            साधु वैश्य के भक्तिपूर्वक वचन सुनकर भगवान प्रसन्न हो गए और उसकी इच्छानुसार वरदान देकर अन्तर्धान हो गए। ससुर-जमाई जब नाव पर आए तो नाव धन से भरी हुई थी फिर वहीं अपने अन्य साथियों के साथ सत्यनारायण भगवान का पूजन कर अपने नगर को चल दिए। जब नगर के नजदीक पहुँचे तो दूत को घर पर खबर करने के लिए भेज दिया। दूत साधु की पत्नी को प्रणाम कर कहता है कि मालिक अपने दामाद सहित नगर के निकट आ गए हैं।
                            दूत की बात सुन साधु की पत्नी लीलावती ने बड़े हर्ष के साथ सत्यनारायण भगवान का पूजन कर अपनी पुत्री कलावती से कहा कि मैं अपने पति के दर्शन को जाती हूँ तू कार्य पूर्ण कर शीघ्र आ जा! माता के ऎसे वचन सुन कलावती जल्दी में प्रसाद छोड़ अपने पति के पास चली गई। प्रसाद की अवज्ञा के कारण श्रीसत्यनारायण भगवान रुष्ट हो गए और नाव सहित उसके पति को पानी में डुबो दिया। कलावती अपने पति को वहाँ ना पाकर रोती हुई जमीन पर गिर गई। नौका को डूबा हुआ देख व कन्या को रोता देख साधु दुखी होकर बोला कि हे प्रभु ! मुझसे तथा मेरे परिवार से जो भूल हुई है उसे क्षमा करें।
                            साधु के दीन वचन सुनकर श्रीसत्यनारायण भगवान प्रसन्न हो गए और आकाशवाणी हुई: हे साधु! तेरी कन्या मेरे प्रसाद को छोड़कर आई है इसलिए उसका पति अदृश्य हो गया है। यदि वह घर जाकर प्रसाद खाकर लौटती है तो इसे इसका पति अवश्य मिलेगा। ऎसी आकाशवाणी सुन कलावती घर पहुंचकर प्रसाद खाती है और फिर आकर अपने पति के दर्शन करती है। उसके बाद साधु अपने बंधु-बाँधवों सहित श्रीसत्यनारायण भगवान का विधि-विधान से पूजन करता है। इस लोक का सुख भोग वह अंत में स्वर्ग जाता है।`,
        end: `॥ इति श्री सत्यनारायण व्रत कथा का चतुर्थ अध्याय संपूर्ण ।।`
    },
    {
        title: `पाँचवां अध्याय`,
        description: `सूतजी बोले: हे ऋषियों ! मैं और भी एक कथा सुनाता हूँ, उसे भी ध्यानपूर्वक सुनो! प्रजापालन में लीन तुंगध्वज नाम का एक राजा था। उसने भी भगवान का प्रसाद त्याग कर बहुत ही दुख सान किया। एक बार वन में जाकर वन्य पशुओं को मारकर वह बड़ के पेड़ के नीचे आया। वहाँ उसने ग्वालों को भक्ति-भाव से अपने बंधुओं सहित सत्यनारायण भगवान का पूजन करते देखा। अभिमानवश राजा ने उन्हें देखकर भी पूजा स्थान में नहीं गया और ना ही उसने भगवान को नमस्कार किया। ग्वालों ने राजा को प्रसाद दिया लेकिन उसने वह प्रसाद नहीं खाया और प्रसाद को वहीं छोड़ वह अपने नगर को चला गया।
                            जब वह नगर में पहुंचा तो वहाँ सबकुछ तहस-नहस हुआ पाया तो वह शीघ्र ही समझ गया कि यह सब भगवान ने ही किया है। वह दुबारा ग्वालों के पास पहुंचा और विधि पूर्वक पूजा कर के प्रसाद खाया तो श्रीसत्यनारायण भगवान की कृपा से सब कुछ पहले जैसा हो गया। दीर्घकाल तक सुख भोगने के बाद मरणोपरांत उसे स्वर्गलोक की प्राप्ति हुई।
                            जो मनुष्य परम दुर्लभ इस व्रत को करेगा तो भगवान सत्यनारायण की अनुकंपा से उसे धन-धान्य की प्राप्ति होगी। निर्धन धनी हो जाता है और भयमुक्त हो जीवन जीता है। संतान हीन मनुष्य को संतान सुख मिलता है और सारे मनोरथ पूर्ण होने 
                            पर मानव अंतकाल में बैकुंठधाम को जाता है।
                            सूतजी बोले: जिन्होंने पहले इस व्रत को किया है अब उनके दूसरे जन्म की कथा कहता हूँ। वृद्ध शतानन्द ब्राह्मण ने सुदामा का जन्म लेकर मोक्ष की प्राप्ति की। लकड़हारे ने अगले जन्म में निषाद बनकर मोक्ष प्राप्त किया। उल्कामुख नाम का राजा दशरथ होकर बैकुंठ को गए। साधु नाम के वैश्य ने मोरध्वज बनकर अपने पुत्र को आरे से चीरकर मोक्ष पाया। महाराज तुंगध्वज ने स्वयंभू होकर भगवान में भक्तियुक्त हो कर्म कर मोक्ष पाया।`,
        end: `॥ इति श्री सत्यनारायण व्रत कथा का पाँचवां अध्याय संपूर्ण ।।`
    }


]

const vatSavitriKatha = [
    {
        title: `वट सावित्री व्रत कथा आरंभ`,
        description: `भद्र देश के एक राजा थे, जिनका नाम अश्वपति था। भद्र देश के राजा अश्वपति के कोई संतान न थी। उन्होंने संतान की प्राप्ति के लिए मंत्रोच्चारण के साथ प्रतिदिन एक लाख आहुतियाँ दीं। अठारह वर्षों तक यह क्रम जारी रहा।
                        इसके बाद सावित्रीदेवी ने प्रकट होकर वर दिया कि: राजन तुझे एक तेजस्वी कन्या पैदा होगी। सावित्रीदेवी की कृपा से जन्म लेने के कारण से कन्या का नाम सावित्री रखा गया। कन्या बड़ी होकर बेहद रूपवान हुई। योग्य वर न मिलने की वजह से सावित्री के पिता दुःखी थे। उन्होंने कन्या को स्वयं वर तलाशने भेजा। सावित्री तपोवन में भटकने लगी। वहाँ साल्व देश के राजा द्युमत्सेन रहते थे, क्योंकि उनका राज्य किसी ने छीन लिया था। उनके पुत्र सत्यवान को देखकर सावित्री ने पति के रूप में उनका वरण किया।
                        ऋषिराज नारद को जब यह बात पता चली तो वह राजा अश्वपति के पास पहुंचे और कहा कि हे राजन! यह क्या कर रहे हैं आप? सत्यवान गुणवान हैं, धर्मात्मा हैं और बलवान भी हैं, पर उसकी आयु बहुत छोटी है, वह अल्पायु हैं। एक वर्ष के बाद ही उसकी मृत्यु हो जाएगी।
                        ऋषिराज नारद की बात सुनकर राजा अश्वपति घोर चिंता में डूब गए। सावित्री ने उनसे कारण पूछा, तो राजा ने कहा, पुत्री तुमने जिस राजकुमार को अपने वर के रूप में चुना है वह अल्पायु हैं। तुम्हे किसी और को अपना जीवन साथी बनाना चाहिए।
                        इस पर सावित्री ने कहा कि पिताजी, आर्य कन्याएं अपने पति का एक बार ही वरण करती हैं, राजा एक बार ही आज्ञा देता है और पंडित एक बार ही प्रतिज्ञा करते हैं और कन्यादान भी एक ही बार किया जाता है।
                        सावित्री हठ करने लगीं और बोलीं मैं सत्यवान से ही विवाह करूंगी। राजा अश्वपति ने सावित्री का विवाह सत्यवान से कर दिया।
                        सावित्री अपने ससुराल पहुंचते ही सास-ससुर की सेवा करने लगी। समय बीतता चला गया। नारद मुनि ने सावित्री को पहले ही सत्यवान की मृत्यु के दिन के बारे में बता दिया था। वह दिन जैसे-जैसे करीब आने लगा, सावित्री अधीर होने लगीं। उन्होंने तीन दिन पहले से ही उपवास शुरू कर दिया। नारद मुनि द्वारा कथित निश्चित तिथि पर पितरों का पूजन किया।
                        हर दिन की तरह सत्यवान उस दिन भी लकड़ी काटने जंगल चले गये साथ में सावित्री भी गईं। जंगल में पहुंचकर सत्यवान लकड़ी काटने के लिए एक पेड़ पर चढ़ गये। तभी उसके सिर में तेज दर्द होने लगा, दर्द से व्याकुल सत्यवान पेड़ से नीचे उतर गये। सावित्री अपना भविष्य समझ गईं।
                        सत्यवान के सिर को गोद में रखकर सावित्री सत्यवान का सिर सहलाने लगीं। तभी वहां यमराज आते दिखे। यमराज अपने साथ सत्यवान को ले जाने लगे। सावित्री भी उनके पीछे-पीछे चल पड़ीं।
                        यमराज ने सावित्री को समझाने की कोशिश की कि यही विधि का विधान है। लेकिन सावित्री नहीं मानी।
                        सावित्री की निष्ठा और पतिपरायणता को देख कर यमराज ने सावित्री से कहा कि हे देवी, तुम धन्य हो। तुम मुझसे कोई भी वरदान मांगो।
                        1) सावित्री ने कहा कि मेरे सास-ससुर वनवासी और अंधे हैं, उन्हें आप दिव्य ज्योति प्रदान करें। यमराज ने कहा ऐसा ही होगा। जाओ अब लौट जाओ।
                        लेकिन सावित्री अपने पति सत्यवान के पीछे-पीछे चलती रहीं। यमराज ने कहा देवी तुम वापस जाओ। सावित्री ने कहा भगवन मुझे अपने पतिदेव के पीछे-पीछे चलने में कोई परेशानी नहीं है। पति के पीछे चलना मेरा कर्तव्य है। यह सुनकर उन्होने फिर से उसे एक और वर मांगने के लिए कहा।
                        2) सावित्री बोलीं हमारे ससुर का राज्य छिन गया है, उसे पुन: वापस दिला दें।
                        यमराज ने सावित्री को यह वरदान भी दे दिया और कहा अब तुम लौट जाओ। लेकिन सावित्री पीछे-पीछे चलती रहीं।
                        यमराज ने सावित्री को तीसरा वरदान मांगने को कहा।
                        3) इस पर सावित्री ने 100 संतानों और सौभाग्य का वरदान मांगा। यमराज ने इसका वरदान भी सावित्री को दे दिया।
                        सावित्री ने यमराज से कहा कि प्रभु मैं एक पतिव्रता पत्नी हूं और आपने मुझे पुत्रवती होने का आशीर्वाद दिया है। यह सुनकर यमराज को सत्यवान के प्राण छोड़ने पड़े। यमराज अंतध्यान हो गए और सावित्री उसी वट वृक्ष के पास आ गई जहां उसके पति का मृत शरीर पड़ा था।
                        सत्यवान जीवंत हो गया और दोनों खुशी-खुशी अपने राज्य की ओर चल पड़े। दोनों जब घर पहुंचे तो देखा कि माता-पिता को दिव्य ज्योति प्राप्त हो गई है। इस प्रकार सावित्री-सत्यवान चिरकाल तक राज्य सुख भोगते रहे।
                        अतः पतिव्रता सावित्री के अनुरूप ही, प्रथम अपने सास-ससुर का उचित पूजन करने के साथ ही अन्य विधियों को प्रारंभ करें। वट सावित्री व्रत करने और इस कथा को सुनने से उपवासक के वैवाहिक जीवन या जीवन साथी की आयु पर किसी प्रकार का कोई संकट आया भी हो तो वो टल जाता है`,
        end: `॥ इति श्री वट सावित्री व्रत कथा संपूर्ण ।।`
    }
]

const vrihaspatiKatha = [
    {
        title: `पहला भाग`,
        description: `भारतवर्ष में एक प्रतापी और दानी राजा राज्य करता था। वह नित्य गरीबों और ब्राह्मणों की सहायता करता था। यह बात उसकी रानी को अच्छी नहीं लगती थी, वह न ही गरीबों को दान देती, न ही भगवान का पूजन करती थी और राजा को भी दान देने से मना किया करती थी।
                    एक दिन राजा शिकार खेलने वन को गए हुए थे, तो रानी महल में अकेली थी। उसी समय बृहस्पतिदेव साधु वेष में राजा के महल में भिक्षा के लिए गए और भिक्षा माँगी रानी ने भिक्षा देने से इन्कार किया और कहा: हे साधु महाराज मैं तो दान पुण्य से तंग आ गई हूँ। मेरा पति सारा धन लुटाते रहिते हैं। मेरी इच्छा है कि हमारा धन नष्ट हो जाए फिर न रहेगा बांस न बजेगी बांसुरी।
                    साधु ने कहा: देवी तुम तो बड़ी विचित्र हो। धन, सन्तान तो सभी चाहते हैं। पुत्र और लक्ष्मी तो पापी के घर भी होने चाहिए। यदि तुम्हारे पास अधिक धन है तो भूखों को भोजन दो, प्यासों के लिए प्याऊ बनवाओ, मुसाफिरों के लिए धर्मशालाएं खुलवाओ। जो निर्धन अपनी कुंवारी कन्याओं का विवाह नहीं कर सकते उनका विवाह करा दो। ऐसे और कई काम हैं जिनके करने से तुम्हारा यश लोक-परलोक में फैलेगा।
                    परन्तु रानी पर उपदेश का कोई प्रभाव न पड़ा। वह बोली: महाराज आप मुझे कुछ न समझाएं। मैं ऐसा धन नहीं चाहती जो हर जगह बाँटती फिरूं।
                    साधु ने उत्तर दिया यदि तुम्हारी ऐसी इच्छा है तो तथास्तु! तुम ऐसा करना कि बृहस्पतिवार को घर लीपकर पीली मिट्‌टी से अपना सिर धोकर स्नान करना, भट्‌टी चढ़ाकर कपड़े धोना, ऐसा करने से आपका सारा धन नष्ट हो जाएगा। इतना कहकर वह साधु महाराज वहाँ से आलोप हो गये।
                    साधु के अनुसार कही बातों को पूरा करते हुए रानी को केवल तीन बृहस्पतिवार ही बीते थे, कि उसकी समस्त धन-संपत्ति नष्ट हो गई। भोजन के लिए राजा का परिवार तरसने लगा।
                    तब एक दिन राजा ने रानी से बोला कि हे रानी, तुम यहीं रहो, मैं दूसरे देश को जाता हूँ, क्योंकि यहाँ पर सभी लोग मुझे जानते हैं। इसलिए मैं कोई छोटा कार्य नहीं कर सकता। ऐसा कहकर राजा परदेश चला गया। वहाँ वह जंगल से लकड़ी काटकर लाता और शहर में बेचता। इस तरह वह अपना जीवन व्यतीत करने लगा। इधर, राजा के परदेश जाते ही रानी और दासी दुःखी रहने लगी।
                    एक बार जब रानी और दासी को सात दिन तक बिना भोजन के रहना पड़ा, तो रानी ने अपनी दासी से कहा: हे दासी! पास ही के नगर में मेरी बहिन रहती है। वह बड़ी धनवान है। तू उसके पास जा और कुछ ले आ, ताकि थोड़ी-बहुत गुजर-बसर हो जाए। दासी रानी की बहिन के पास गई।
                    उस दिन गुरुवार था और रानी की बहिन उस समय बृहस्पतिवार व्रत की कथा सुन रही थी। दासी ने रानी की बहिन को अपनी रानी का संदेश दिया, लेकिन रानी की बड़ी बहिन ने कोई उत्तर नहीं दिया। जब दासी को रानी की बहिन से कोई उत्तर नहीं मिला तो वह बहुत दुःखी हुई और उसे क्रोध भी आया। दासी ने वापस आकर रानी को सारी बात बता दी। सुनकर रानी ने अपने भाग्य को कोसा।
                    उधर, रानी की बहिन ने सोचा कि मेरी बहिन की दासी आई थी, परंतु मैं उससे नहीं बोली, इससे वह बहुत दुःखी हुई होगी।
                    कथा सुनकर और पूजन समाप्त करके वह अपनी बहिन के घर आई और कहने लगी: हे बहिन! मैं बृहस्पतिवार का व्रत कर रही थी। तुम्हारी दासी मेरे घर आई थी परंतु जब तक कथा होती है, तब तक न तो उठते हैं और न ही बोलते हैं, इसलिए मैं नहीं बोली। कहो दासी क्यों गई थी?
                    रानी बोली: बहिन, तुमसे क्या छिपाऊं, हमारे घर में खाने तक को अनाज नहीं था। ऐसा कहते-कहते रानी की आंखें भर आई। उसने दासी समेत पिछले सात दिनों से भूखे रहने तक की बात अपनी बहिन को विस्तार पूर्वक सुना दी।
                    रानी की बहिन बोली: देखो बहिन! भगवान बृहस्पतिदेव सबकी मनोकामना को पूर्ण करते हैं। देखो, शायद तुम्हारे घर में अनाज रखा हो।
                    पहले तो रानी को विश्वास नहीं हुआ पर बहिन के आग्रह करने पर उसने अपनी दासी को अंदर भेजा तो उसे सचमुच अनाज से भरा एक घड़ा मिल गया। यह देखकर दासी को बड़ी हैरानी हुई।
                    दासी रानी से कहने लगी: हे रानी! जब हमको भोजन नहीं मिलता तो हम व्रत ही तो करते हैं, इसलिए क्यों न इनसे व्रत और कथा की विधि पूछ ली जाए, ताकि हम भी व्रत कर सकें। तब रानी ने अपनी बहिन से बृहस्पतिवार व्रत के बारे में पूछा।
                    उसकी बहिन ने बताया, बृहस्पतिवार के व्रत में चने की दाल और मुनक्का से विष्णु भगवान का केले की जड़ में पूजन करें तथा दीपक जलाएं, व्रत कथा सुनें और पीला भोजन ही करें। इससे बृहस्पतिदेव प्रसन्न होते हैं। व्रत और पूजन विधि बताकर रानी की बहिन अपने घर को लौट गई।
                    सात दिन के बाद जब गुरुवार आया, तो रानी और दासी ने व्रत रखा। घुड़साल में जाकर चना और गुड़ लेकर आईं। फिर उससे केले की जड़ तथा विष्णु भगवान का पूजन किया। अब पीला भोजन कहाँ से आए इस बात को लेकर दोनों बहुत दुःखी थे। चूंकि उन्होंने व्रत रखा था, इसलिए बृहस्पतिदेव उनसे प्रसन्न थे। इसलिए वे एक साधारण व्यक्ति का रूप धारण कर दो थालों में सुन्दर पीला भोजन दासी को दे गए। भोजन पाकर दासी प्रसन्न हुई और फिर रानी के साथ मिलकर भोजन ग्रहण किया।
                    उसके बाद वे सभी गुरुवार को व्रत और पूजन करने लगी। बृहस्पति भगवान की कृपा से उनके पास फिर से धन-संपत्ति आ गई, परंतु रानी फिर से पहले की तरह आलस्य करने लगी।
                    तब दासी बोली: देखो रानी! तुम पहले भी इस प्रकार आलस्य करती थी, तुम्हें धन रखने में कष्ट होता था, इस कारण सभी धन नष्ट हो गया और अब जब भगवान बृहस्पति की कृपा से धन मिला है तो तुम्हें फिर से आलस्य होता है।
                    रानी को समझाते हुए दासी कहती है कि बड़ी मुसीबतों के बाद हमने यह धन पाया है, इसलिए हमें दान-पुण्य करना चाहिए, भूखे मनुष्यों को भोजन कराना चाहिए, और धन को शुभ कार्यों में खर्च करना चाहिए, जिससे तुम्हारे कुल का यश बढ़ेगा, स्वर्ग की प्राप्ति होगी और पित्र प्रसन्न होंगे। दासी की बात मानकर रानी अपना धन शुभ कार्यों में खर्च करने लगी, जिससे पूरे नगर में उसका यश फैलने लगा।
                    बृहस्पतिवार व्रत कथा के बाद श्रद्धा के साथ आरती की जानी चाहिए। इसके बाद प्रसाद बांटकर उसे ग्रहण करना चाहिए।
                    एक दिन राजा दुःखी होकर जंगल में एक पेड़ के नीचे आसन जमाकर बैठ गया। वह अपनी दशा को याद करके व्याकुल होने लगा। बृहस्पतिवार का दिन था, एकाएक उसने देखा कि निर्जन वन में एक साधु प्रकट हुए। वह साधु वेष में स्वयं बृहस्पति देवता थे।
                    लकड़हारे के सामने आकर बोले: हे लकड़हारे! इस सुनसान जंगल में तू चिन्ता मग्न क्यों बैठा है?
                    लकड़हारे ने दोनों हाथ जोड़ कर प्रणाम किया और उत्तर दिया: महात्मा जी! आप सब कुछ जानते हैं, मैं क्या कहूँ। यह कहकर रोने लगा और साधु को अपनी आत्मकथा सुनाई।
                    महात्मा जी ने कहा: तुम्हारी स्त्री ने बृहस्पति के दिन बृहस्पति भगवान का निरादर किया है जिसके कारण रुष्ट होकर उन्होंने तुम्हारी यह दशा कर दी। अब तुम चिन्ता को दूर करके मेरे कहने पर चलो तो तुम्हारे सब कष्ट दूर हो जायेंगे और भगवान पहले से भी अधिक सम्पत्ति देंगे। तुम बृहस्पति के दिन कथा किया करो। दो पैसे के चने मुनक्का लाकर उसका प्रसाद बनाओ और शुद्ध जल से लोटे में शक्कर मिलाकर अमृत तैयार करो। कथा के पश्चात अपने सारे परिवार और सुनने वाले प्रेमियों में अमृत व प्रसाद बांटकर आप भी ग्रहण करो। ऐसा करने से भगवान तुम्हारी सब मनोकामनाएँ पूरी करेंगे।
                    साधु के ऐसे वचन सुनकर लकड़हारा बोला: हे प्रभो! मुझे लकड़ी बेचकर इतना पैसा नहीं मिलता, जिससे भोजन के उपरान्त कुछ बचा सकूं। मैंने रात्रि में अपनी स्त्री को व्याकुल देखा है। मेरे पास कुछ भी नहीं जिससे मैं उसकी खबर मंगा सकूं।
                    साधु ने कहा: हे लकड़हारे! तुम किसी बात की चिन्ता मत करो। बृहस्पति के दिन तुम रोजाना की तरह लकड़ियाँ लेकर शहर को जाओ। तुमको रोज से दुगुना धन प्राप्त होगा, जिससे तुम भली-भांति भोजन कर लोगे तथा बृहस्पतिदेव की पूजा का सामान भी आ जायेगा।
                    इतना कहकर साधु अन्तर्ध्यान हो गए। धीरे-धीरे समय व्यतीत होने पर फिर वही बृहस्पतिवार का दिन आया। लकड़हारा जंगल से लकड़ी काटकर किसी शहर में बेचने गया, उसे उस दिन और दिन से अधिक पैसा मिला। राजा ने चना गुड आदि लाकर गुरुवार का व्रत किया। उस दिन से उसके सभी क्लेश दूर हुए, परन्तु जब दुबारा गुरुवार का दिन आया तो बृहस्पतिवार का व्रत करना भूल गया। इस कारण बृहस्पति भगवान नाराज हो गए।
                    उस दिन उस नगर के राजा ने विशाल यज्ञ का आयोजन किया तथा शहर में यह घोषणा करा दी कि कोई भी मनुष्य अपने घर में भोजन न बनावे न आग जलावे समस्त जनता मेरे यहाँ भोजन करने आवे। इस आज्ञा को जो न मानेगा उसे फाँसी की सजा दी जाएगी। इस तरह की घोषणा सम्पूर्ण नगर में करवा दी गई।
                    राजा की आज्ञानुसार शहर के सभी लोग भोजन करने गए। लेकिन लकड़हारा कुछ देर से पहुँचा इसलिए राजा उसको अपने साथ घर लिवा ले गए और ले जाकर भोजन करा रहे थे तो रानी की दृष्टि उस खूंटी पर पड़ी जिस पर उसका हार लटका हुआ था। वह वहाँ पर दिखाई नहीं दिया। रानी ने निश्चय किया कि मेरा हार इस मनुष्य ने चुरा लिया है। उसी समय सिपाहियों को बुलाकर उसको कारागार में डलवा दिया।
                    जब लकड़हारा कारागार में पड़ गया और बहुत दुःखी होकर विचार करने लगा कि न जाने कौन से पूर्व जन्म के कर्म से मुझे यह दुःख प्राप्त हुआ है, और उसी साधु को याद करने लगा जो कि जंगल में मिला था।
                    उसी समय तत्काल बृहस्पतिदेव साधु के रूप में प्रकट हुए और उसकी दशा को देखकर कहने लगे: अरे मूर्ख! तूने बृहस्पतिदेव की कथा नहीं करी इस कारण तुझे दुःख प्राप्त हुआ है। अब चिन्ता मत कर बृहस्पतिवार के दिन कारागार के दरवाजे पर चार पैसे पड़े मिलेंगे। उनसे तू बृहस्पतिदेव की पूजा करना तेरे सभी कष्ट दूर हो जायेंगे।
                    बृहस्पति के दिन उसे चार पैसे मिले। लकड़हारे ने कथा कही उसी रात्रि को बृहस्पतिदेव ने उस नगर के राजा को स्वप्न में कहा: हे राजा! तूमने जिस आदमी को कारागार में बन्द कर दिया है वह निर्दोष है। वह राजा है उसे छोड़ देना। रानी का हार उसी खूंटी पर लटका है। अगर तू ऐसा नही करेगा तो मैं तेरे राज्य को नष्ट कर दूंगा
                    इस तरह रात्रि के स्वप्न को देखकर राजा प्रातःकाल उठा और खूंटी पर हार देखकर लकड़हारे को बुलाकर क्षमा मांगी तथा लकड़हारे को योग्य सुन्दर वस्त्र-आभूषण देकर विदा कर दिया। बृहस्पतिदेव की आज्ञानुसार लकड़हारा अपने नगर को चल दिया।
                    राजा जब अपने नगर के निकट पहुँचा तो उसे बड़ा आश्चर्य हुआ। नगर में पहले से अधिक बाग, तालाब, कुएं तथा बहुत सी धर्मशाला मन्दिर आदि बन गई हैं। राजा ने पूछा यह किसका बाग और धर्मशाला हैं, तब नगर के सब लोग कहने लगे यह सब रानी और बांदी के हैं। तो राजा को आश्चर्य हुआ और गुस्सा भी आया।
                    जब रानी ने यह खबर सुनी कि राजा आरहे हैं, तो उन्होंने बाँदी से कहा कि: हे दासी! देख राजा हमको कितनी बुरी हालत में छोड़ गए थे। हमारी ऐसी हालत देखकर वह लौट न जायें, इसलिए तू दरवाजे पर खड़ी होजा। आज्ञानुसार दासी दरवाजे पर खड़ी हो गई। राजा आए तो उन्हें अपने साथ लिवा लाई। तब राजा ने क्रोध करके अपनी रानी से पूछा कि यह धन तुम्हें कैसे प्राप्त हुआ है, तब उन्होंने कहा: हमें यह सब धन बृहस्पतिदेव के इस व्रत के प्रभाव से प्राप्त हुआ है।
                    राजा ने निश्चय किया कि सात रोज बाद तो सभी बृहस्पतिदेव का पूजन करते हैं परन्तु मैं प्रतिदिन दिन में तीन बार कहानी तथा रोज व्रत किया करूँगा। अब हर समय राजा के दुपट्‌टे में चने की दाल बँधी रहती तथा दिन में तीन बार कहानी कहता।
                    एक रोज राजा ने विचार किया कि चलो अपनी बहिन के यहाँ हो आवें। इस तरह निश्चय कर राजा घोड़े पर सवार हो अपनी बहिन के यहाँ को चलने लगा। मार्ग में उसने देखा कि कुछ आदमी एक मुर्दे को लिए जा रहे हैं, उन्हें रोककर राजा कहने लगा: अरे भाइयों! मेरी बृहस्पतिदेव की कथा सुन लो।
                    वे बोले: लो! हमारा तो आदमी मर गया है, इसको अपनी कथा की पड़ी है। परन्तु कुछ आदमी बोले: अच्छा कहो हम तुम्हारी कथा भी सुनेंगे। राजा ने दाल निकाली और जब कथा आधी हुई थी कि मुर्दा हिलने लग गया और जब कथा समाप्त हो गई तो राम-राम करके मनुष्य उठकर खड़ा हो गया।
                    आगे मार्ग में उसे एक किसान खेत में हल चलाता मिला। राजा ने उसे देख और उससे बोले: अरे भईया! तुम मेरी बृहस्पतिवार की कथा सुन लो। किसान बोला जब तक मैं तेरी कथा सुनूंगा तब तक चार हरैया जोत लूंगा। जा अपनी कथा किसी और को सुनाना। इस तरह राजा आगे चलने लगा। राजा के हटते ही बैल पछाड़ खाकर गिर गए तथा किसान के पेट में बड़ी जोर का दर्द होने लगा।
                    उस समय उसकी माँ रोटी लेकर आई, उसने जब यह देखा तो अपने पुत्र से सब हाल पूछा और बेटे ने सभी हाल कह दिया तो बुढ़िया दौड़ी-दौड़ी उस घुड़सवार के पास गई और उससे बोली कि मैं तेरी कथा सुनूंगी तू अपनी कथा मेरे खेत पर चलकर ही कहना। राजा ने बुढ़िया के खेत पर जाकर कथा कही, जिसके सुनते ही वह बैल उठ खड़ हुए तथा किसान के पेट का दर्द भी बन्द हो गया।
                    राजा अपनी बहिन के घर पहुँचा। बहिन ने भाई की खूब मेहमानी की। दूसरे रोज प्रातःकाल राजा जगा तो वह देखने लगा कि सब लोग भोजन कर रहे हैं।
                    राजा ने अपनी बहिन से कहा: ऐसा कोई मनुष्य है जिसने भोजन नहीं किया हो, मेरी बृहस्पतिवार की कथा सुन ले।
                    बहिन बोली: हे भैया! यह देश ऐसा ही है कि पहले यहाँ लोग भोजन करते हैं, बाद में अन्य काम करते हैं। अगर कोई पड़ोस में हो तो देख आउं।
                    वह ऐसा कहकर देखने चली गई परन्तु उसे कोई ऐसा व्यक्ति नहीं मिला, जिसने भोजन न किया हो अतः वह एक कुम्हार के घर गई जिसका लड़का बीमार था। उसे मालूम हुआ कि उनके यहाँ तीन रोज से किसी ने भोजन नहीं किया है। रानी ने अपने भाई की कथा सुनने के लिए कुम्हार से कहा वह तैयार हो गया। राजा ने जाकर बृहस्पतिवार की कथा कही जिसको सुनकर उसका लड़का ठीक होगया, अब तो राजा की प्रशंसा होने लगी।
                    एक रोज राजा ने अपनी बहिन से कहा कि हे बहिन! हम अपने घर को जायेंगे। तुम भी तैयार हो जाओ। राजा की बहिन ने अपनी सास से कहा। सास ने कहा हाँ चली जा। परन्तु अपने लड़कों को मत ले जाना क्योंकि तेरे भाई के कोई औलाद नहीं है।
                    बहिन ने अपने भईया से कहा: हे भईया! मैं तो चलूंगी पर कोई बालक नहीं जाएगा।
                    राजा बोला: जब कोई बालक नहीं चलेगा, तब तुम ही क्या करोगी।
                    बड़े दुःखी मन से राजा अपने नगर को लौट आया।
                    राजा ने अपनी रानी से कहा: हम निरवंशी हैं। हमारा मुंह देखने का धर्म नहीं है और कुछ भोजन आदि नहीं किया।
                    रानी बोली: हे प्रभो! बृहस्पतिदेव ने हमें सब कुछ दिया है, वह हमें औलाद अवश्य देंगे।
                    उसी रात को बृहस्पतिदेव ने राजा से स्वप्न में कहा: हे राजा उठ। सभी सोच त्याग दे, तेरी रानी गर्भ से है। राजा की यह बात सुनकर बड़ी खुशी हुई।
                    नवें महीने में उसके गर्भ से एक सुन्दर पुत्र पैदा हुआ। तब राजा बोला: हे रानी! स्त्री बिना भोजन के रह सकती है, पर बिना कहे नहीं रह सकती। जब मेरी बहिन आवे तुम उससे कुछ कहना मत। रानी ने सुनकर हाँ कर दिया।
                    जब राजा की बहिन ने यह शुभ समाचार सुना तो वह बहुत खुश हुई तथा बधाई लेकर अपने भाई के यहाँ आई, तभी रानी ने कहा: घोड़ा चढ़कर तो नहीं आई, गधा चढ़ी आई।
                    राजा की बहिन बोली: भाभी मैं इस प्रकार न कहती तो तुम्हें औलाद कैसे मिलती।
                    बृहस्पतिदेव ऐसे ही हैं, जैसी जिसके मन में कामनाएँ हैं, सभी को पूर्ण करते हैं, जो सदभावनापूर्वक बृहस्पतिवार का व्रत करता है एवं कथा पढता है, अथवा सुनता है, दूसरो को सुनाता है, बृहस्पतिदेव उसकी सभी मनोकामना पूर्ण करते हैं।
                    भगवान बृहस्पतिदेव उसकी सदैव रक्षा करते हैं, संसार में जो मनुष्य सदभावना से भगवान जी का पूजन व्रत सच्चे हृदय से करते हैं, तो उनकी सभी मनोकामनाएं पूर्ण करते है।
                    जैसी सच्ची भावना से रानी और राजा ने उनकी कथा का गुणगान किया तो उनकी सभी इच्छायें बृहस्पतिदेव जी ने पूर्ण की थीं। इसलिए पूर्ण कथा सुनने के बाद प्रसाद लेकर जाना चाहिए। हृदय से उसका मनन करते हुए जयकारा बोलना चाहिए।
`,
        end: `॥ बोलो बृहस्पतिदेव की जय। भगवान विष्णु की जय ।।`
    },
    {
        title: `दूसरा भाग`,
        description: `प्राचीन काल में एक ब्राह्‌मण रहता था, वह बहुत निर्धन था। उसके कोई सन्तान नहीं थी। उसकी स्त्री बहुत मलीनता के साथ रहती थी। वह स्नान न करती, किसी देवता का पूजन न करती, 
        इससे ब्राह्‌मण देवता बड़े दुःखी थे। बेचारे बहुत कुछ कहते थे किन्तु उसका कुछ परिणाम न निकला। भगवान की कृपा से ब्राह्‌मण की स्त्री के कन्या रूपी रत्न पैदा हुआ। कन्या बड़ी होने पर प्रातः स्नान करके 
        विष्णु भगवान का जाप व बृहस्पतिवार का व्रत करने लगी। अपने पूजन-पाठ को समाप्त करके विद्यालय जाती तो अपनी मुट्‌ठी में जौ भरके ले जाती और पाठशाला के मार्ग में डालती जाती। तब ये जौ स्वर्ण के 
        जो जाते लौटते समय उनको बीन कर घर ले आती थी।
        एक दिन वह बालिका सूप में उस सोने के जौ को फटककर साफ कर रही थी कि उसके पिता ने देख लिया और कहा - हे बेटी! सोने के जौ के लिए सोने का सूप होना चाहिए। दूसरे दिन बृहस्पतिवार था इस कन्या ने 
        व्रत रखा और बृहस्पतिदेव से प्रार्थना करके कहा- मैंने आपकी पूजा सच्चे मन से की हो तो मेरे लिए सोने का सूप दे दो। बृहस्पतिदेव ने उसकी प्रार्थना स्वीकार कर ली। रोजाना की तरह वह कन्या जौ फैलाती हुई जाने 
        लगी जब लौटकर जौ बीन रही थी तो बृहस्पतिदेव की कृपा से सोने का सूप मिला। उसे वह घर ले आई और उसमें जौ साफ करने लगी। परन्तु उसकी मां का वही ढंग रहा। एक दिन की बात है कि वह कन्या सोने के 
        सूप में जौ साफ कर रही थी। उस समय उस शहर का राजपुत्र वहां से होकर निकला। इस कन्या के रूप और कार्य को देखकर मोहित हो गया तथा अपने घर आकर भोजन तथा जल त्याग कर उदास होकर लेट गया। 
        राजा को इस बात का पता लगा तो अपने प्रधानमंत्री के साथ उसके पास गए और बोले- हे बेटा तुम्हें किस बात का कष्ट है? किसी ने अपमान किया है अथवा और कारण हो सो कहो मैं वही कार्य करूंगा जिससे तुम्हें
        प्रसन्नता हो। अपने पिता की राजकुमार ने बातें सुनी तो वह बोला- मुझे आपकी कृपा से किसी बात का दुःख नहीं है किसी ने मेरा अपमान नहीं किया है परन्तु मैं उस लड़की से विवाह करना चाहता हूं जो सोने के सूप 
        में जौ साफ कर रही थी। यह सुनकर राजा आश्चर्य में पड ा और बोला- हे बेटा! इस तरह की कन्या का पता तुम्हीं लगाओ। मैं उसके साथ तेरा विवाह अवश्य ही करवा दूंगा। राजकुमार ने उस लड की के घर का पता 
        बतलाया। तब मंत्री उस लड की के घर गए और ब्राह्‌मण देवता को सभी हाल बतलाया। ब्राह्‌मण देवता राजकुमार के साथ अपनी कन्या का विवाह करने के लिए तैयार हो गए तथा विधि-विधान के अनुसार ब्राह्‌मण की 
        कन्या का विवाह राजकुमार के साथ हो गया।
        कन्या के घर से जाते ही पहले की भांति उस ब्राह्‌मण देवता के घर में गरीबी का निवास हो गया। अब भोजन के लिए भी अन्न बड़ी मुश्किल से मिलता था। एक दिन दुःखी होकर ब्राह्‌मण देवता अपनी पुत्री के पास गए। 
        बेटी ने पिता की दुःखी अवस्था को देखा और अपनी मां का हाल पूछा। तब ब्राह्‌मण ने सभी हाल कहा। कन्या ने बहुत सा धन देकर अपने पिता को विदा कर दिया। इस तरह ब्राह्‌मण का कुछ समय सुखपूर्वक व्यतीत हुआ। 
        कुछ दिन बाद फिर वही हाल हो गया। ब्राह्‌मण फिर अपनी कन्या के यहां गया और सारा हाल कहा तो लड की बोली- हे पिताजी! आप माताजी को यहां लिवा लाओ। मैं उसे विधि बता दूंगी जिससे गरीबी दूर हो जाए। 
        वह ब्राह्‌मण देवता अपनी स्त्री को साथ लेकर पहुंचे तो अपनी मां को समझाने लगी- हे मां तुम प्रातःकाल प्रथम स्नानादि करके विष्णु भगवान का पूजन करो तो सब दरिद्रता दूर हो जावेगी। परन्तु उसकी मांग ने एक भी 
        बात नहीं मानी और प्रातःकाल उठकर अपनी पुत्री के बच्चों की जूठन को खा लिया। इससे उसकी पुत्री को भी बहुत गुस्सा आया और एक रात को कोठरी से सभी सामान निकाल दिया और अपनी मां को उसमें बंद कर 
        दिया। प्रातःकाल उसे निकाला तथा स्नानादि कराके पाठ करवाया तो उसकी मां की बुद्धि ठीक हो गई और फिर प्रत्येक बृहस्पतिवार को व्रत रखने लगी। इस व्रत के प्रभाव से उसके मां बाप बहुत ही धनवान और पुत्रवान 
        हो गए और बृहस्पतिजी के प्रभाव से इस लोक के सुख भोगकर स्वर्ग को प्राप्त हुए।`,
        end: `॥ बोलो बृहस्पतिदेव की जय। भगवान विष्णु की जय ॥`
    },
]

const somvarKatha = [
    {
        title: `सोमवार व्रत कथा आरंभ`,
        description: `किसी नगर में एक धनी व्यापारी रहता था। दूर-दूर तक उसका व्यापार फैला हुआ था। नगर के सभी लोग उस व्यापारी का सम्मान करते थे। इतना सब कुछ संपन्न होने के बाद भी वह व्यापारी बहुत दुःखी था, क्योंकि उसका कोई पुत्र नहीं था। जिस कारण अपने मृत्यु के पश्चात् व्यापार के उत्तराधिकारी की चिंता उसे हमेशा सताती रहती थी।
                    पुत्र प्राप्ति की इच्छा से व्यापरी प्रत्येक सोमवार भगवान् शिव की व्रत-पूजा किया करता था और शाम के समय शिव मंदिर में जाकर शिवजी के सामने घी का दीपक जलाया करता था। उसकी भक्ति देखकर माँ पार्वती प्रसन्न हो गई और भगवान शिव से उस व्यापारी की मनोकामना पूर्ण करने का निवेदन किया।
                    भगवान शिव बोले: इस संसार में सबको उसके कर्म के अनुसार फल की प्राप्ति होती है। जो प्राणी जैसा कर्म करते हैं, उन्हें वैसा ही फल प्राप्त होता है।
                    शिवजी द्वारा समझाने के बावजूद माँ पार्वती नहीं मानी और उस व्यापारी की मनोकामना पूर्ति हेतु वे शिवजी से बार-बार अनुरोध करती रही। अंततः माता के आग्रह को देखकर भगवान भोलेनाथ को उस व्यापारी को पुत्र प्राप्ति का वरदान देना पड़ा।
                    वरदान देने के पश्चात् भोलेनाथ माँ पार्वती से बोले: आपके आग्रह पर मैंने पुत्र प्राप्ति का वरदान तो दे दिया परन्तु इसका यह पुत्र 16 वर्ष से अधिक समय तक जीवित नहीं रहेगा। उसी रात भगवान शिव उस व्यापारी के स्वप्न में आए और उसे पुत्र प्राप्ति का वरदान दिया और उसके पुत्र के 16 वर्ष तक जीवित रहने की भी बात बताई।
                    भगवान के वरदान से व्यापारी को खुशी तो हुई, लेकिन पुत्र की अल्पायु की चिंता ने उस खुशी को नष्ट कर दिया। व्यापारी पहले की तरह सोमवार के दिन भगवान शिव का विधिवत व्रत करता रहा। कुछ महीनों के बाद उसके घर एक अति सुन्दर बालक ने जन्म लिया, और घर में खुशियां ही खुशियां भर गई।
                    बहुत धूमधाम से पुत्र जन्म का समारोह मनाया गया परन्तु व्यापारी को पुत्र-जन्म की अधिक खुशी नहीं हुई क्योंकि उसे पुत्र की अल्प-आयु के रहस्य का पता था। जब पुत्र 12 वर्ष का हुआ तो व्यापारी ने उसे उसके मामा के साथ पढ़ने के लिए वाराणसी भेज दिया। लड़का अपने मामा के साथ शिक्षा प्राप्ति हेतु चल दिया। रास्ते में जहाँ भी मामा-भांजे विश्राम हेतु रुकते, वहीं यज्ञ करते और ब्राह्मणों को भोजन कराते।
                    लम्बी यात्रा के बाद मामा-भांजे एक नगर में पहुंचे। उस दिन नगर के राजा की कन्या का विवाह था, जिस कारण पूरे नगर को सजाया गया था। निश्चित समय पर बारात आ गई लेकिन वर का पिता अपने बेटे के एक आंख से काने होने के कारण बहुत चिंतित था। उसे भय था कि इस बात का पता चलने पर कहीं राजा विवाह से इनकार न कर दे।
                    इससे उसकी बदनामी भी होगी। जब वर के पिता ने व्यापारी के पुत्र को देखा तो उसके मस्तिष्क में एक विचार आया। उसने सोचा क्यों न इस लड़के को दूल्हा बनाकर राजकुमारी से विवाह करा दूँ। विवाह के बाद इसको धन देकर विदा कर दूंगा और राजकुमारी को अपने नगर ले जाऊंगा।
                    वर के पिता ने लड़के के मामा से इस सम्बन्ध में बात की। मामा ने धन मिलने के लालच में वर के पिता की बात स्वीकार कर ली। लड़के को दूल्हे का वस्त्र पहनाकर राजकुमारी से विवाह कर दिया गया।
                    राजा ने बहुत सारा धन देकर राजकुमारी को विदा किया। शादी के बाद लड़का जब राजकुमारी से साथ लौट रहा था तो वह सच नहीं छिपा सका और उसने राजकुमारी के ओढ़नी पर लिख दिया: राजकुमारी, तुम्हारा विवाह मेरे साथ हुआ था, मैं तो वाराणसी पढ़ने के लिए जा रहा हूँ और अब तुम्हें जिस नवयुवक की पत्नी बनना पड़ेगा, वह काना है।
                    जब राजकुमारी ने अपनी ओढ़नी पर लिखा हुआ पढ़ा तो उसने काने लड़के के साथ जाने से इनकार कर दिया। राजा को जब ये सब बातें पता लगीं, तो उसने राजकुमारी को महल में ही रख लिया।
                    उधर लड़का अपने मामा के साथ वाराणसी पहुँच गया और गुरुकुल में पढ़ना शुरू कर दिया। जब उसकी आयु 16 वर्ष की हुई तो उसने यज्ञ किया। यज्ञ के समाप्ति पर ब्राह्मणों को भोजन कराया और खूब अन्न, वस्त्र दान किए। रात को वह अपने शयनकक्ष में सो गया। शिव के वरदान के अनुसार शयनावस्था में ही उसके प्राण-पखेड़ू उड़ गए। सूर्योदय पर मामा मृत भांजे को देखकर रोने-पीटने लगा। आसपास के लोग भी एकत्र होकर दुःख प्रकट करने लगे।
                    लड़के के मामा के रोने, विलाप करने के स्वर समीप से गुजरते हुए भगवान शिव और माता पार्वतीजी ने भी सुने
                    माता पार्वती ने भगवान से कहा: प्राणनाथ, मुझे इसके रोने के स्वर सहन नहीं हो रहे। आप इस व्यक्ति के कष्ट को अवश्य दूर करें।
                    भगवान शिव ने माता पार्वती के साथ अदृश्य रूप में समीप जाकर देखा तो भोलेनाथ, माता पार्वती से बोले: यह तो उसी व्यापारी का पुत्र है, जिसे मैंने 16 वर्ष की आयु का वरदान दिया था। इसकी आयु पूरी हो गई है।
                    माँ पार्वती ने फिर भगवान शिव से निवेदन कर उस बालक को जीवन देने का आग्रह किया। माता पार्वती के आग्रह पर भगवान शिव ने उस लड़के को जीवित होने का वरदान दिया और कुछ ही पल में वह जीवित होकर उठ बैठा।
                    शिक्षा समाप्त करके लड़का मामा के साथ अपने नगर की ओर चल दिया। दोनों चलते हुए उसी नगर में पहुँचे, जहाँ उसका विवाह हुआ था। उस नगर में भी यज्ञ का आयोजन किया। समीप से गुजरते हुए नगर के राजा ने यज्ञ का आयोजन देखा और उसने तुरंत ही लड़के और उसके मामा को पहचान लिया।
                    यज्ञ के समाप्त होने पर राजा मामा और लड़के को महल में ले गया और कुछ दिन उन्हें महल में रखकर बहुत-सा धन, वस्त्र आदि देकर राजकुमारी के साथ विदा कर दिया।
                    इधर भूखे-प्यासे रहकर व्यापारी और उसकी पत्नी बेटे की प्रतीक्षा कर रहे थे। उन्होंने प्रतिज्ञा कर रखी थी कि यदि उन्हें अपने बेटे की मृत्यु का समाचार मिला तो दोनों अपने प्राण त्याग देंगे परन्तु जैसे ही उसने बेटे के जीवित वापस लौटने का समाचार सुना तो वह बहुत प्रसन्न हुआ। वह अपनी पत्नी और मित्रो के साथ नगर के द्वार पर पहुँचा।
                    अपने बेटे के विवाह का समाचार सुनकर, पुत्रवधू राजकुमारी को देखकर उसकी खुशी का ठिकाना न रहा। उसी रात भगवान शिव ने व्यापारी के स्वप्न में आकर कहा: हे श्रेष्ठी, मैंने तेरे सोमवार के व्रत करने और व्रतकथा सुनने से प्रसन्न होकर तेरे पुत्र को लम्बी आयु प्रदान की है। पुत्र की लम्बी आयु जानकार व्यापारी बहुत प्रसन्न हुआ।
                    शिव भक्त होने तथा सोमवार का व्रत करने से व्यापारी की सभी मनोकामनाएँ पूर्ण हुईं, इसी प्रकार जो भक्त सोमवार का विधिवत व्रत करते हैं और व्रतकथा सुनते हैं उनकी सभी इच्छाएं पूरी होती हैं।`,
        end: `॥ बोलो भगवान शिव की जय। माँ पार्वती की जय`
    }
]

const mangalVrat = [
    {
        title: `मंगलवार व्रत कथा आरंभ`,
        description: `एक समय की बात है एक ब्राह्मण दंपत्ति की कोई संतान नहीं थी, जिस कारण वह बेहद दुःखी थे। एक समय ब्राह्मण वन में हनुमान जी की पूजा के लिए गया। वहाँ उसने पूजा के साथ महावीर जी से एक पुत्र की कामना की।
                    घर पर उसकी स्त्री भी पुत्र की प्राप्ति के लिए मंगलवार का व्रत करती थी। वह मंगलवार के दिन व्रत के अंत में हनुमान जी को भोग लगाकर ही भोजन करती थी।
                    एक बार व्रत के दिन ब्राह्मणी ना भोजन बना पाई और ना ही हनुमान जी को भोग लगा सकी। उसने प्रण किया कि वह अगले मंगलवार को हनुमान जी को भोग लगाकर ही भोजन करेगी।
                    वह भूखी प्यासी छह दिन तक पड़ी रही। मंगलवार के दिन वह बेहोश हो गई। हनुमान जी उसकी निष्ठा और लगन को देखकर प्रसन्न हुए। उन्होंने आशीर्वाद स्वरूप ब्राह्मणी को एक पुत्र दिया और कहा कि यह तुम्हारी बहुत सेवा करेगा।
                    बालक को पाकर ब्राह्मणी अति प्रसन्न हुई। उसने बालक का नाम मंगल रखा। कुछ समय उपरांत जब ब्राह्मण घर आया, तो बालक को देख पूछा कि वह कौन है?
                    पत्नी बोली कि मंगलवार व्रत से प्रसन्न होकर हनुमान जी ने उसे यह बालक दिया है। ब्राह्मण को अपनी पत्नी की बात पर विश्वास नहीं हुआ। एक दिन मौका देख ब्राह्मण ने बालक को कुएं में गिरा दिया।
                    घर पर लौटने पर ब्राह्मणी ने पूछा कि, मंगल कहां है? तभी पीछे से मंगल मुस्कुरा कर आ गया। उसे वापस देखकर ब्राह्मण आश्चर्यचकित रह गया। रात को हनुमानजी ने उसे सपने में दर्शन दिए और बताया कि यह पुत्र उसे उन्होंने ही दिया है।
                    ब्राह्मण सत्य जानकर बहुत खुश हुआ। इसके बाद ब्राह्मण दंपत्ति प्रत्येक मंगलवार को व्रत रखने लगे।
                    जो मनुष्य मंगलवार व्रत कथा को पढ़ता या सुनता है,और नियम से व्रत रखता है उसे हनुमान जी की कृपा से सब कष्ट दूर होकर सर्व सुख प्राप्त होता है, और हनुमान जी की दया के पात्र बनते हैं।
`,
        end: `॥ बोलो अंजनी पुत्र हनुमान की जय ॥`
    }
]

const santoshiMataVrat=[
    {
        title:`संतोषी माता व्रत कथा आरंभ`,
        description:`एक बुढ़िया थी, उसके सात बेटे थे। 6 कमाने वाले थे जबकि एक निक्कमा था। बुढ़िया छहों बेटों की रसोई बनाती, भोजन कराती और उनसे जो कुछ जूठन बचती वह सातवें को दे देती।
                    एक दिन वह पत्नी से बोला- देखो मेरी माँ को मुझ पर कितना प्रेम है।
                    वह बोली- क्यों नहीं, सबका झूठा जो तुमको खिलाती है।
                    वह बोला- ऐसा नहीं हो सकता है। मैं जब तक आँखों से न देख लूं मान नहीं सकता।
                    बहू हंस कर बोली- देख लोगे तब तो मानोगे।
                    कुछ दिन बाद त्यौहार आया। घर में सात प्रकार के भोजन और चूरमे के लड्डू बने। वह जांचने को सिर दुखने का बहाना कर पतला वस्त्र सिर पर ओढ़े रसोई घर में सो गया। वह कपड़े में से सब देखता रहा। छहों भाई भोजन करने आए। उसने देखा, माँ ने उनके लिए सुन्दर आसन बिछा नाना प्रकार की रसोई परोसी और आग्रह करके उन्हें जमाया। वह देखता रहा।
                    छहों भोजन करके उठे तब माँ ने उनकी झूठी थालियों में से लड्डुओं के टुकड़े उठाकर एक लड्डू बनाया।
                    जूठन साफ कर बुढ़िया माँ ने उसे पुकारा- बेटा, छहों भाई भोजन कर गए अब तू ही बाकी है, उठ तू कब खाएगा।
                    वह कहने लगा- माँ मुझे भोजन नहीं करना, मैं अब परदेश जा रहा हूँ।
                    माँ ने कहा- कल जाता हो तो आज चला जा।
                    वह बोला- हाँ आज ही जा रहा हूँ। यह कह कर वह घर से निकल गया।`,
        end:``
    },
    {
        title:`सातवें बेटे का परदेश जाना`,
        description:`चलते समय पत्नी की याद आ गई। वह गौशाला में कण्डे (उपले) थाप रही थी।
                    वहाँ जाकर बोला-
                    हम जावे परदेश आवेंगे कुछ काल,
                    तुम रहियो संतोष से धर्म आपनो पाल।
                    वह बोली-
                    जाओ पिया आनन्द से हमारो सोच हटाय,
                    राम भरोसे हम रहें ईश्वर तुम्हें सहाय।
                    दो निशानी आपन देख धरूं में धीर,
                    सुधि मति हमारी बिसारियो रखियो मन गम्भीर।
                    वह बोला- मेरे पास तो कुछ नहीं, यह अंगूठी है सो ले और अपनी कुछ निशानी मुझे दे।
                    वह बोली- मेरे पास क्या है, यह गोबर भरा हाथ है। यह कह कर उसकी पीठ पर गोबर के हाथ की थाप मार दी। वह चल दिया, चलते-चलते दूर देश पहुँचा।
                    वहाँ एक साहूकार की दुकान थी। वहाँ जाकर कहने लगा- भाई मुझे नौकरी पर रख लो।
                    साहूकार को जरूरत थी, बोला- रह जा।
                    लड़के ने पूछा- तनखा क्या दोगे।
                    साहूकार ने कहा- काम देख कर दाम मिलेंगे। साहूकार की नौकरी मिली, वह सुबह 7 बजे से 10 बजे तक नौकरी बजाने लगा। कुछ दिनों में दुकान का सारा लेन-देन, हिसाब-किताब, ग्राहकों को माल बेचना सारा काम करने लगा। साहूकार के सात-आठ नौकर थे, वे सब चक्कर खाने लगे, यह तो बहुत होशियार बन गया।
                    सेठ ने भी काम देखा और तीन महीने में ही उसे आधे मुनाफे का हिस्सेदार बना लिया। वह कुछ वर्ष में ही नामी सेठ बन गया और मालिक सारा कारोबार उसपर छोड़कर चला गया।
                    पति की अनुपस्थिति में सास का अत्याचार-
                    इधर उसकी पत्नी को सास ससुर दु:ख देने लगे, सारी गृहस्थी का काम कराके उसे लकड़ी लेने जंगल में भेजते। इस बीच घर के आटे से जो भूसी निकलती उसकी रोटी बनाकर रख दी जाती और फूटे नारियल की नारेली में पानी। एक दिन वह लकड़ी लेने जा रही थी, रास्ते में बहुत सी स्त्रियां संतोषी माता का व्रत करती दिखाई दी।
                    वह वहाँ खड़ी होकर कथा सुनने लगी और पूछा- बहिनों तुम किस देवता का व्रत करती हो और उसके करने से क्या फल मिलता है। यदि तुम इस व्रत का विधान मुझे समझा कर कहोगे तो मैं तुम्हारा बड़ा अहसान मानूंगी।
                    तब उनमें से एक स्त्री बोली- सुनो, यह संतोषी माता का व्रत है। इसके करने से निर्धनता, दरिद्रता का नाश होता है और जो कुछ मन में कामना हो, सब संतोषी माता की कृपा से पूरी होती है। तब उसने उससे व्रत की विधि पूछी।
`,
        end:``
    },
    {
        title:`संतोषी माता व्रत विधि`,
        description:`वह भक्तिनि स्त्री बोली- सवा आने का गुड़ चना लेना, इच्छा हो तो सवा पांच आने का लेना या सवा रुपए का भी सहूलियत के अनुसार लाना। बिना परेशानी और श्रद्धा व प्रेम से जितना भी बन पड़े सवाया लेना। प्रत्येक शुक्रवार को निराहार रह कर कथा सुनना, इसके बीच क्रम टूटे नहीं, लगातार नियम पालन करना, सुनने वाला कोई न मिले तो धी का दीपक जला उसके आगे या जल के पात्र को सामने रख कर कथा कहना। जब कार्य सिद्ध न हो नियम का पालन करना और कार्य सिद्ध हो जाने पर व्रत का उद्यापन करना।
                    तीन मास में माता फल पूरा करती है। यदि किसी के ग्रह खोटे भी हों, तो भी माता वर्ष भर में कार्य सिद्ध करती है, फल सिद्ध होने पर उद्यापन करना चाहिए बीच में नहीं। उद्यापन में अढ़ाई सेर आटे का खाजा तथा इसी परिमाण से खीर तथा चने का साग करना। आठ लड़कों को भोजन कराना, जहाँ तक मिलें देवर, जेठ, भाई-बंधु के हों, न मिले तो रिश्तेदारों और पास-पड़ोसियों को बुलाना। उन्हें भोजन करा यथा शक्ति दक्षिणा दे माता का नियम पूरा करना। उस दिन घर में खटाई न खाना। यह सुन बुढ़िया के लड़के की बहू चल दी।
                    रास्ते में लकड़ी के बोझ को बेच दिया और उन पैसों से गुड़-चना ले माता के व्रत की तैयारी कर आगे चली और सामने मंदिर देखकर पूछने लगी- यह मंदिर किसका है।
                    सब कहने लगे संतोषी माता का मंदिर है, यह सुनकर माता के मंदिर में जाकर चरणों में लोटने लगी।
                    दीन हो विनती करने लगी- माँ मैं निपट अज्ञानी हूँ, व्रत के कुछ भी नियम नहीं जानती, मैं दु:खी हूँ। हे माता ! जगत जननी मेरा दु:ख दूर कर मैं तेरी शरण में हूँ।
                    माता को दया आई- एक शुक्रवार बीता कि दूसरे को उसके पति का पत्र आया और तीसरे शुक्रवार को उसका भेजा हुआ पैसा आ पहुँचा। यह देख जेठ-जिठानी मुंह सिकोडऩे लगे।
                    लड़के ताने देने लगे- काकी के पास पत्र आने लगे, रुपया आने लगा, अब तो काकी की खातिर बढ़ेगी।
                    बेचारी सरलता से कहती- भैया कागज आवे रुपया आवे हम सब के लिए अच्छा है। ऐसा कह कर आँखों में आँसू भरकर संतोषी माता के मंदिर में आ मातेश्वरी के चरणों में गिरकर रोने लगी। माँ मैंने तुमसे पैसा कब माँगा है।
                    मुझे पैसे से क्या काम है। मुझे तो अपने सुहाग से काम है। मैं तो अपने स्वामी के दर्शन माँगती हूँ। तब माता ने प्रसन्न होकर कहा- जा बेटी, तेरा स्वामी आयेगा।
                    यह सुनकर खुशी से बावली होकर घर में जा काम करने लगी। अब संतोषी माँ विचार करने लगी, इस भोली पुत्री को मैंने कह तो दिया कि तेरा पति आयेगा लेकिन कैसे? वह तो इसे स्वप्न में भी याद नहीं करता।
                    उसे याद दिलाने को मुझे ही जाना पड़ेगा। इस तरह माता जी उस बुढ़िया के बेटे के पास जा स्वप्न में प्रकट हो कहने लगी- साहूकार के बेटे, सो रहा है या जागता है।
                    वह कहने लगा- माता सोता भी नहीं, जागता भी नहीं हूँ कहो क्या आज्ञा है?
                    माँ कहने लगी- तेरे घर-बार कुछ है कि नहीं।
                    वह बोला- मेरे पास सब कुछ है माँ-बाप है बहू है क्या कमी है।
                    माँ बोली- भोले पुत्र तेरी बहू घोर कष्ट उठा रही है, तेरे माँ-बाप उसे परेशानी दे रहे हैं। वह तेरे लिए तरस रही है, तू उसकी सुध ले।
                    वह बोला- हाँ माता जी यह तो मालूम है, परंतु जाऊं तो कैसे? परदेश की बात है, लेन-देन का कोई हिसाब नहीं, कोई जाने का रास्ता नहीं आता, कैसे चला जाऊं?
                    माँ कहने लगी- मेरी बात मान, सवेरे नहा धोकर संतोषी माता का नाम ले, घी का दीपक जला दण्डवत कर दुकान पर जा बैठ।
                    देखते-देखते सारा लेन-देन चुक जाएगा, जमा का माल बिक जाएगा, सांझ होते-होते धन का भारी ठेर लग जाएगा। अब बूढ़े की बात मानकर वह नहा धोकर संतोषी माता को दण्डवत धी का दीपक जला दुकान पर जा बैठा। थोड़ी देर में देने वाले रुपया लाने लगे, लेने वाले हिसाब लेने लगे। कोठे में भरे सामान के खरीददार नकद दाम दे सौदा करने लगे। शाम तक धन का भारी ठेर लग गया। मन में माता का नाम ले चमत्कार देख प्रसन्न हो घर ले जाने के वास्ते गहना, कपड़ा सामान खरीदने लगा। यहाँ काम से निपट तुरंत घर को रवाना हुआ।
                    उधर उसकी पत्नी जंगल में लकड़ी लेने जाती है, लौटते वक्त माताजी के मंदिर में विश्राम करती। वह तो उसके प्रतिदिन रुकने का जो स्थान ठहरा, धूल उड़ती देख वह माता से पूछती है- हे माता! यह धूल कैसे उड़ रही है?
                    माता कहती है- हे पुत्री तेरा पति आ रहा है। अब तू ऐसा कर लकड़ियों के तीन बोझ बना ले, एक नदी के किनारे रख और दूसरा मेरे मंदिर पर व तीसरा अपने सिर पर।
                    तेरे पति को लकड़ियों का गट्ठर देख मोह पैदा होगा, वह यहाँ रुकेगा, नाश्ता-पानी खाकर माँ से मिलने जाएगा, तब तू लकड़ियों का बोझ उठाकर जाना और चौक में गट्ठर डालकर जोर से आवाज लगाना- लो सासूजी, लकडिय़ों का गट्ठर लो, भूसी की रोटी दो, नारियल के खेपड़े में पानी दो, आज मेहमान कौन आया है? माताजी से बहुत अच्छा कहकर वह प्रसन्न मन से लकड़ियों के तीन गट्ठर बनाई। एक नदी के किनारे पर और एक माताजी के मंदिर पर रखा।
                    इतने में मुसाफिर आ पहुँचा। सूखी लकड़ी देख उसकी इच्छा उत्पन्न हुई कि हम यही पर विश्राम करें और भोजन बनाकर खा-पीकर गाँव जाएं। इसी तरह रुक कर भोजन बना, विश्राम करके गाँव को गया। सबसे प्रेम से मिला। उसी समय सिर पर लकड़ी का गट्ठर लिए वह उतावली सी आती है। लकड़ियों का भारी बोझ आंगन में डालकर जोर से तीन आवाज देती है- लो सासूजी, लकड़ियों का गट्ठर लो, भूसी की रोटी दो। आज मेहमान कौन आया है।
                    यह सुनकर उसकी सास बाहर आकर अपने दिए हुए कष्टों को भुलाने हेतु कहती है- बहु ऐसा क्यों कहती है? तेरा मालिक ही तो आया है। आ बैठ, मीठा भात खा, भोजन कर, कपड़े-गहने पहिन। उसकी आवाज सुन उसका पति बाहर आता है। अंगूठी देख व्याकुल हो जाता है।
                    माँ से पूछता है- माँ यह कौन है?
                    माँ बोली- बेटा यह तेरी बहु है। जब से तू गया है तब से सारे गाँव में भटकती फिरती है। घर का काम-काज कुछ करती नहीं, चार पहर आकर खा जाती है।
                    वह बोला- ठीक है माँ मैंने इसे भी देखा और तुम्हें भी, अब दूसरे घर की ताली दो, उसमें रहूँगा।
                    माँ बोली- ठीक है, जैसी तेरी मरजी। तब वह दूसरे मकान की तीसरी मंजिल का कमरा खोल सारा सामान जमाया। एक दिन में राजा के महल जैसा ठाट-बाट बन गया। अब क्या था? बहु सुख भोगने लगी। इतने में शुक्रवार आया।
`,
        end:``
    },
    {
        title:`व्रत उद्यापन में हुई भूल`,
        description:`उसने पति से कहा- मुझे संतोषी माता के व्रत का उद्यापन करना है।
                    पति बोला- खुशी से कर लो। वह उद्यापन की तैयारी करने लगी। जिठानी के लड़कों को भोजन के लिए कहने गई। उन्होंने मंजूर किया परन्तु पीछे से जिठानी ने अपने बच्चों को सिखाया, देखो, भोजन के समय खटाई माँगना, जिससे उसका उद्यापन पूरा न हो।
                    लड़के जीमने आए खीर खाना पेट भर खाया, परंतु बाद में खाते ही कहने लगे- हमें खटाई दो, खीर खाना हमको नहीं भाता, देखकर अरुचि होती है।
                    वह कहने लगी- भाई खटाई किसी को नहीं दी जाएगी। यह तो संतोषी माता का प्रसाद है।
                    लड़के उठ खड़े हुए, बोले- पैसा लाओ, भोली बहू कुछ जानती नहीं थी, उन्हें पैसे दे दिए।
                    लड़के उसी समय हठ करके इमली खटाई ले खाने लगे। यह देखकर बहु पर माताजी ने कोप किया। राजा के दूत उसके पति को पकड़ कर ले गए। जेठ जेठानी मन-माने वचन कहने लगे। लूट-लूट कर धन इकट्ठा कर लाया है, अब सब मालूम पड़ जाएगा जब जेल की मार खाएगा। बहू से यह सहन नहीं हुए।
                    रोती हुई माताजी के मंदिर गई, कहने लगी- हे माता! तुमने क्या किया, हंसा कर अब भक्तों को रुलाने लगी।
                    माता बोली- बेटी तूने उद्यापन करके मेरा व्रत भंग किया है।
                    वह कहने लगी- माता मैंने कुछ अपराध किया है, मैंने तो भूल से लड़कों को पैसे दे दिए थे, मुझे क्षमा करो। मैं फिर तुम्हारा उद्यापन करूँगी।
                    माँ बोली- अब भूल मत करना।
                    वह कहती है- अब भूल नहीं होगी, अब बतलाओ वे कैसे आवेंगे?
                    माँ बोली- जा पुत्री तेरा पति तुझे रास्ते में आता मिलेगा। वह निकली, राह में पति आता मिला।
                    वह पूछी- कहाँ गए थे?
                    वह कहने लगा- इतना धन जो कमाया है उसका टैक्स राजा ने माँगा था, वह भरने गया था।
                    वह प्रसन्न हो बोली- भला हुआ, अब घर को चलो। कुछ दिन बाद फिर शुक्रवार आया..
                    वह बोली- मुझे फिर माता का उद्यापन करना है।
                    पति ने कहा- करो, बहु फिर जेठ के लड़कों को भोजन को कहने गई। जेठानी ने एक दो बातें सुनाई और सब लड़कों को सिखाने लगी। तुम सब लोग पहले ही खटाई माँगना।
                    लड़के भोजन से पहले कहने लगे- हमें खीर नहीं खानी, हमारा जी बिगड़ता है, कुछ खटाई खाने को दो।
                    वह बोली- खटाई किसी को नहीं मिलेगी, आना हो तो आओ, वह ब्राह्मण के लड़के लाकर भोजन कराने लगी, यथा शक्ति दक्षिणा की जगह एक-एक फल उन्हें दिया। संतोषी माता प्रसन्न हुई।
`,
        end:``
    },
    {
        title:`संतोषी माता का फल`,
        description:`माता की कृपा होते ही नवमें मास में उसके चन्द्रमा के समान सुन्दर पुत्र प्राप्त हुआ। पुत्र को पाकर प्रतिदिन माता जी के मंदिर को जाने लगी।
                    माँ ने सोचा- यह रोज आती है, आज क्यों न इसके घर चलूं। यह विचार कर माता ने भयानक रूप बनाया, गुड़-चने से सना मुख, ऊपर सूंड के समान होठ, उस पर मक्खियां भिन-भिन कर रही थी।
                    देहली पर पैर रखते ही उसकी सास चिल्लाई- देखो रे, कोई चुड़ैल डाकिन चली आ रही है, लड़कों इसे भगाओ, नहीं तो किसी को खा जाएगी। लड़के भगाने लगे, चिल्लाकर खिड़की बंद करने लगे।
                    बहु रौशनदान में से देख रही थी, प्रसन्नता से पगली बन चिल्लाने लगी- आज मेरी माता जी मेरे घर आई है। वह बच्चे को दूध पीने से हटाती है। इतने में सास का क्रोध फट पड़ा।
                    वह बोली- क्या उतावली हुई है? बच्चे को पटक दिया। इतने में माँ के प्रताप से लड़के ही लड़के नजर आने लगे।
                    वह बोली- माँ मैं जिसका व्रत करती हूँ यह संतोषी माता है।
                    सबने माता जी के चरण पकड़ लिए और विनती कर कहने लगे- हे माता! हम मूर्ख हैं, अज्ञानी हैं, तुम्हारे व्रत की विधि हम नहीं जानते, व्रत भंग कर हमने बड़ा अपराध किया है, 
                    जग माता आप हमारा अपराध क्षमा करो। इस प्रकार माता प्रसन्न हुई। बहू को प्रसन्न हो जैसा फल दिया, वैसा माता सबको दे, जो पढ़े उसका मनोरथ पूर्ण हो।`,
        end:`॥ बोलो संतोषी माता की जय ॥ `
    }
]


const ekadashi=[
    {
        title:`निर्जला एकादशी व्रत कथा`,
        description:`जब सर्वज्ञ वेदव्यास ने पांडवों को चारों पुरुषार्थ: धर्म, अर्थ, काम और मोक्ष देने वाले एकादशी व्रत का संकल्प कराया था,
                    तब युधिष्ठिर ने कहा: जनार्दन! ज्येष्ठ मास के शुक्लपक्ष में जो एकादशी पड़ती हो, कृपया उसका वर्णन कीजिये।
                    भगवान श्रीकृष्ण ने कहा: हे राजन्! इसका वर्णन परम धर्मात्मा सत्यवती नन्दन व्यासजी करेंगे, क्योंकि ये सम्पूर्ण शास्त्रों के तत्त्वज्ञ और वेद वेदांगों के पारंगत विद्वान हैं|
                    तब वेदव्यासजी कहने लगे: कृष्ण पक्ष और शुक्ल पक्ष दोनों पक्षों की एकादशी में अन्न खाना वर्जित है। द्वादशी के दिन स्नान करके पवित्र हो और फूलों से भगवान केशव की पूजा करे। फिर नित्य कर्म समाप्त होने के पश्चात् पहले ब्राह्मणों को भोजन देकर अन्त में स्वयं भोजन करे।
                    यह सुनकर भीमसेन बोले: परम बुद्धिमान पितामह! मेरी उत्तम बात सुनिये। राजा युधिष्ठिर, माता कुन्ती, द्रौपदी, अर्जुन, नकुल और सहदेव, ये एकादशी को कभी भोजन नहीं करते तथा मुझसे भी हमेशा यही कहते हैं कि भीमसेन एकादशी को तुम भी न खाया करो परन्तु मैं उन लोगों से यही कहता हूँ कि मुझसे भूख नहीं सही जायेगी।
                    भीमसेन की बात सुनकर व्यासजी ने कहा: यदि तुम नरक को दूषित समझते हो और तुम्हें स्वर्गलोक की प्राप्ति अभीष्ट है और तो दोनों पक्षों की एकादशियों के दिन भोजन नहीं करना चाहिए।
                    भीमसेन बोले महाबुद्धिमान पितामह! मैं आपके सामने सच कहता हूँ। मुझसे एक बार भोजन करके भी व्रत नहीं किया जा सकता, तो फिर उपवास करके मैं कैसे रह सकता हूँ। मेरे उदर में वृक नामक अग्नि सदा प्रज्वलित रहती है, अत: जब मैं बहुत अधिक खाता हूँ, तभी यह शांत होती है।...
                    इसलिए महामुनि! मैं पूरे वर्षभर में केवल एक ही उपवास कर सकता हूँ। जिससे स्वर्ग की प्राप्ति सुलभ हो तथा जिसके करने से मैं कल्याण का भागी हो सकूँ, ऐसा कोई एक व्रत निश्चय करके बताइये। मैं उसका यथोचित रूप से पालन करुँगा।
                    व्यासजी ने कहा: भीम! ज्येष्ठ मास में सूर्य वृष राशि पर हो या मिथुन राशि पर, शुक्लपक्ष में जो एकादशी हो, उसका यत्नपूर्वक निर्जल व्रत करो। केवल कुल्ला या आचमन करने के लिए मुख में जल डाल सकते हो, उसको छोड़कर किसी प्रकार का जल विद्वान पुरुष मुख में न डाले, अन्यथा व्रत भंग हो जाता है।
                    एकादशी को सूर्योदय से लेकर दूसरे दिन के सूर्योदय तक मनुष्य जल का त्याग करे तो यह व्रत पूर्ण होता है। तदनन्तर द्वादशी को प्रभातकाल में स्नान करके ब्राह्मणों को विधिपूर्वक जल और सुवर्ण का दान करें। इस प्रकार सब कार्य पूरा करके जितेन्द्रिय पुरुष ब्राह्मणों के साथ भोजन करे।
                    वर्षभर में जितनी एकादशियाँ होती हैं, उन सबका फल निर्जला एकादशी के पालन मात्र से मनुष्य प्राप्त कर लेता है, इसमें तनिक भी कोई सन्देह नहीं है। शंख, चक्र और गदा धारण करनेवाले भगवान केशव ने मुझसे कहा था कि यदि मानव सबको छोड़कर एकमात्र मेरी शरण में आ जाय और एकादशी को निराहार रहे तो वह सब पापों से छूट जाता है।
                    एकादशी व्रत करने वाले पुरुष के पास विशालकाय, विकराल आकृति और काले रंगवाले दण्ड पाशधारी भयंकर यमदूत नहीं जाते। अंतकाल में पीताम्बरधारी, सौम्य स्वभाव वाले, हाथ में सुदर्शन धारण करने वाले और मन के समान वेगशाली विष्णुदूत आख़िर इस वैष्णव पुरुष को भगवान विष्णु के धाम में ले जाते हैं।
                    अत: निर्जला एकादशी को पूर्ण यत्न करके उपवास और श्रीहरि का पूजन करो। स्त्री हो या पुरुष, यदि उसने मेरु पर्वत के बराबर भी महान पाप किया हो तो वह सब इस एकादशी व्रत के प्रभाव से भस्म हो जाता है। जो मनुष्य उस दिन जल के नियम का पालन करता है, वह पुण्य का भागी होता है। उसे एक-एक प्रहर में कोटि-कोटि स्वर्णमुद्रा दान करने का फल प्राप्त होता सुना गया है।
                    मनुष्य निर्जला एकादशी के दिन स्नान, दान, जप, होम आदि जो कुछ भी करता है, वह सब अक्षय होता है, यह भगवान श्रीकृष्ण का कथन है। निर्जला एकादशी को विधिपूर्वक उत्तम रीति से उपवास करके मानव वैष्णवपद को प्राप्त कर लेता है। जो मनुष्य एकादशी के दिन अन्न खाता है, वह पाप का भोजन करता है। इस लोक में वह चाण्डाल के समान है और मरने पर दुर्गति को प्राप्त होता है।
                    जो ज्येष्ठ के शुक्लपक्ष में एकादशी को उपवास करके दान करेंगे, वे परम पद को प्राप्त होंगे। जिन्होंने एकादशी को उपवास किया है, वे ब्रह्महत्यारे, शराबी, चोर तथा गुरुद्रोही होने पर भी सब पातकों से मुक्त हो जाते हैं।
                    कुन्तीनन्दन! निर्जला एकादशी के दिन श्रद्धालु स्त्री पुरुषों के लिए जो विशेष दान और कर्त्तव्य विहित हैं, उन्हें सुनो: उस दिन जल में शयन करने वाले भगवान विष्णु का पूजन और जलमयी धेनु का दान करना चाहिए अथवा प्रत्यक्ष धेनु या घृतमयी धेनु का दान उचित है।...
                    पर्याप्त दक्षिणा और भाँति-भाँति के मिष्ठानों द्वारा यत्नपूर्वक ब्राह्मणों को सन्तुष्ट करना चाहिए। ऐसा करने से ब्राह्मण अवश्य संतुष्ट होते हैं और उनके संतुष्ट होने पर श्रीहरि मोक्ष प्रदान करते हैं। जिन्होंने शम, दम, और दान में प्रवृत हो श्रीहरि की पूजा और रात्रि में जागरण करते हुए इस निर्जला एकादशी का व्रत किया है, उन्होंने अपने साथ ही बीती हुई सौ पीढ़ियों को और आने वाली सौ पीढ़ियों को भगवान वासुदेव के परम धाम में पहुँचा दिया है।...
                    निर्जला एकादशी के दिन अन्न, वस्त्र, गौ, जल, शैय्या, सुन्दर आसन, कमण्डलु तथा छाता दान करने चाहिए। जो श्रेष्ठ तथा सुपात्र ब्राह्मण को जूता दान करता है, वह सोने के विमान पर बैठकर स्वर्गलोक में प्रतिष्ठित होता है। जो इस एकादशी की महिमा को भक्तिपूर्वक सुनता अथवा उसका वर्णन करता है, वह स्वर्गलोक में जाता है। चतुर्दशीयुक्त अमावस्या को सूर्यग्रहण के समय श्राद्ध करके मनुष्य जिस फल को प्राप्त करता है, वही फल इसके श्रवण से भी प्राप्त होता है।...
                    पहले दन्तधावन करके यह नियम लेना चाहिए कि मैं भगवान केशव की प्रसन्नता के लिए एकादशी को निराहार रहकर आचमन के सिवा दूसरे जल का भी त्याग करुँगा। द्वादशी को देवेश्वर भगवान विष्णु का पूजन करना चाहिए। गन्ध, धूप, पुष्प और सुन्दर वस्त्र से विधिपूर्वक पूजन करके जल के घड़े के दान का संकल्प करते हुए निम्नांकित मंत्र का उच्चारण करे।
                    संसारसागर से तारने वाले हे देव ह्रषीकेश! इस जल के घड़े का दान करने से आप मुझे परम गति की प्राप्ति कराइये।
                    भीमसेन! ज्येष्ठ मास में शुक्लपक्ष की जो शुभ एकादशी होती है, उसका निर्जल व्रत करना चाहिए। उस दिन श्रेष्ठ ब्राह्मणों को शक्कर के साथ जल के घड़े दान करने चाहिए। ऐसा करने से मनुष्य भगवान विष्णु के समीप पहुँचकर आनन्द का अनुभव करता है।...
                    तत्पश्चात् द्वादशी को ब्राह्मण भोजन कराने के बाद स्वयं भोजन करे। जो इस प्रकार पूर्ण रूप से पापनाशिनी एकादशी का व्रत करता है, वह सब पापों से मुक्त हो आनंदमय पद को प्राप्त होता है। यह सुनकर भीमसेन ने भी इस शुभ एकादशी का व्रत आरम्भ कर दिया। तबसे यह लोक में पाण्डव द्वादशी के नाम से विख्यात हुई।
                    ज्येष्ठ शुक्लपक्ष एकादशी के ही दिन भगवान श्री कृष्ण ने देवी रुक्मणी का हरण किया था अतः यह एकादशी रुक्मणी-हरण एकादशी के नाम से भी जानी जाती है।
`,
        end:`॥ जय श्री हरि ॥`
    },
    {
        title:`योगिनी एकादशी व्रत कथा`,
        description:`धर्मराज युधिष्ठिर ने भगवान श्री कृष्ण कहा: हे त्रिलोकीनाथ! मैंने ज्येष्ठ माह के शुक्ल पक्ष की निर्जला एकादशी की कथा सुनी। अब आप कृपा करके आषाढ़ माह के कृष्ण पक्ष की एकादशी की कथा सुनाइये। इस एकादशी का नाम तथा माहात्म्य क्या है? सो अब मुझे विस्तारपूर्वक बतायें।
                    श्रीकृष्ण ने कहा: हे पाण्डु पुत्र! आषाढ़ माह के कृष्ण पक्ष की एकादशी का नाम योगिनी एकादशी है। इसके व्रत से सभी पाप नष्ट हो जाते हैं। यह व्रत इस लोक में भोग तथा परलोक में मुक्ति देने वाला है।
                    हे धर्मराज! यह एकादशी तीनों लोकों में प्रसिद्ध है। इसके व्रत से सभी पाप नष्ट हो जाते हैं। तुम्हें मैं पुराण में कही हुई कथा सुनाता हूँ, ध्यानपूर्वक श्रवण करो- कुबेर नाम का एक राजा अलकापुरी नाम की नगरी में राज्य करता था। वह शिव-भक्त था। उनका हेममाली नामक एक यक्ष सेवक था, जो पूजा के लिए फूल लाया करता था। हेममाली की विशालाक्षी नाम की अति सुन्दर स्त्री थी।
                    एक दिन वह मानसरोवर से पुष्प लेकर आया, किन्तु कामासक्त होने के कारण पुष्पों को रखकर अपनी स्त्री के साथ रमण करने लगा। इस भोग-विलास में दोपहर हो गई।
                    हेममाली की राह देखते-देखते जब राजा कुबेर को दोपहर हो गई तो उसने क्रोधपूर्वक अपने सेवकों को आज्ञा दी कि तुम लोग जाकर पता लगाओ कि हेममाली अभी तक पुष्प लेकर क्यों नहीं आया। जब सेवकों ने उसका पता लगा लिया तो राजा के पास जाकर बताया- हे राजन! वह हेममाली अपनी स्त्री के साथ रमण कर रहा है।
                    इस बात को सुन राजा कुबेर ने हेममाली को बुलाने की आज्ञा दी। डर से काँपता हुआ हेममाली राजा के सामने उपस्थित हुआ। उसे देखकर कुबेर को अत्यन्त क्रोध आया और उसके होंठ फड़फड़ाने लगे।
                    राजा ने कहा: अरे अधम! तूने मेरे परम पूजनीय देवों के भी देव भगवान शिवजी का अपमान किया है। मैं तुझे श्राप देता हूँ कि तू स्त्री के वियोग में तड़पे और मृत्युलोक में जाकर कोढ़ी का जीवन व्यतीत करे।
                    कुबेर के श्राप से वह तत्क्षण स्वर्ग से पृथ्वी पर आ गिरा और कोढ़ी हो गया। उसकी स्त्री भी उससे बिछड़ गई। मृत्युलोक में आकर उसने अनेक भयंकर कष्ट भोगे, किन्तु शिव की कृपा से उसकी बुद्धि मलिन न हुई और उसे पूर्व जन्म की भी सुध रही। अनेक कष्टों को भोगता हुआ तथा अपने पूर्व जन्म के कुकर्मो को याद करता हुआ वह हिमालय पर्वत की तरफ चल पड़ा।
                    चलते-चलते वह मार्कण्डेय ऋषि के आश्रम में जा पहुँचा। वह ऋषि अत्यन्त वृद्ध तपस्वी थे। वह दूसरे ब्रह्मा के समान प्रतीत हो रहे थे और उनका वह आश्रम ब्रह्मा की सभा के समान शोभा दे रहा था। ऋषि को देखकर हेममाली वहाँ गया और उन्हें प्रणाम करके उनके चरणों में गिर पड़ा।
                    हेममाली को देखकर मार्कण्डेय ऋषि ने कहा: तूने कौन-से निकृष्ट कर्म किये हैं, जिससे तू कोढ़ी हुआ और भयानक कष्ट भोग रहा है।
                    महर्षि की बात सुनकर हेममाली बोला: हे मुनिश्रेष्ठ! मैं राजा कुबेर का अनुचर था। मेरा नाम हेममाली है। मैं प्रतिदिन मानसरोवर से फूल लाकर शिव पूजा के समय कुबेर को दिया करता था। एक दिन पत्नी सहवास के सुख में फँस जाने के कारण मुझे समय का ज्ञान ही नहीं रहा और दोपहर तक पुष्प न पहुँचा सका। तब उन्होंने मुझे शाप श्राप दिया कि तू अपनी स्त्री का वियोग और मृत्युलोक में जाकर कोढ़ी बनकर दुख भोग। इस कारण मैं कोढ़ी हो गया हूँ तथा पृथ्वी पर आकर भयंकर कष्ट भोग रहा हूँ, अतः कृपा करके आप कोई ऐसा उपाय बतलाये, जिससे मेरी मुक्ति हो।
                    मार्कण्डेय ऋषि ने कहा: हे हेममाली! तूने मेरे सम्मुख सत्य वचन कहे हैं, इसलिए मैं तेरे उद्धार के लिए एक व्रत बताता हूँ। यदि तू आषाढ़ माह के कृष्ण पक्ष की योगिनी नामक एकादशी का विधानपूर्वक व्रत करेगा तो तेरे सभी पाप नष्ट हो जाएँगे।
                    महर्षि के वचन सुन हेममाली अति प्रसन्न हुआ और उनके वचनों के अनुसार योगिनी एकादशी का विधानपूर्वक व्रत करने लगा। इस व्रत के प्रभाव से अपने पुराने स्वरूप में आ गया और अपनी स्त्री के साथ सुखपूर्वक रहने लगा।
                    भगवान श्री कृष्ण ने कहा: हे राजन! इस योगिनी एकादशी की कथा का फल 88000 ब्राह्मणों को भोजन कराने के बराबर है। इसके व्रत से सभी पाप नष्ट हो जाते हैं और अन्त में मोक्ष प्राप्त करके प्राणी स्वर्ग का अधिकारी बनता है।
`,
        end:`॥ जय श्री हरि ॥`
    },
    {
        title:`देवशयनी एकादशी व्रत कथा`,
        description:`धर्मराज युधिष्ठिर ने कहा: हे केशव! आषाढ़ शुक्ल एकादशी का क्या नाम है? इस व्रत के करने की विधि क्या है और किस देवता का पूजन किया जाता है? श्रीकृष्ण कहने लगे कि हे युधिष्ठिर! जिस कथा को ब्रह्माजी ने नारदजी से कहा था वही मैं तुमसे कहता हूँ।
                    एक बार देवऋषि नारदजी ने ब्रह्माजी से इस एकादशी के विषय में जानने की उत्सुकता प्रकट की, तब ब्रह्माजी ने उन्हें बताया: सतयुग में मांधाता नामक एक चक्रवर्ती सम्राट राज्य करते थे। उनके राज्य में प्रजा बहुत सुखी थी। किंतु भविष्य में क्या हो जाए, यह कोई नहीं जानता। अतः वे भी इस बात से अनभिज्ञ थे कि उनके राज्य में शीघ्र ही भयंकर अकाल पड़ने वाला है।
                    उनके राज्य में पूरे तीन वर्ष तक वर्षा न होने के कारण भयंकर अकाल पड़ा। इस अकाल से चारों ओर त्राहि-त्राहि मच गई। धर्म पक्ष के यज्ञ, हवन, पिंडदान, कथा-व्रत आदि में कमी हो गई। जब मुसीबत पड़ी हो तो धार्मिक कार्यों में प्राणी की रुचि कहाँ रह जाती है। प्रजा ने राजा के पास जाकर अपनी वेदना की दुहाई दी।
                    राजा तो इस स्थिति को लेकर पहले से ही दुःखी थे। वे सोचने लगे कि आखिर मैंने ऐसा कौन-सा पाप-कर्म किया है, जिसका दंड मुझे इस रूप में मिल रहा है? फिर इस कष्ट से मुक्ति पाने का कोई साधन करने के उद्देश्य से राजा सेना को लेकर जंगल की ओर चल दिए।
                    वहाँ विचरण करते-करते एक दिन वे ब्रह्माजी के पुत्र अंगिरा ऋषि के आश्रम में पहुँचे और उन्हें साष्टांग प्रणाम किया। ऋषिवर ने आशीर्वचनोपरांत कुशल क्षेम पूछा। फिर जंगल में विचरने व अपने आश्रम में आने का प्रयोजन जानना चाहा।
                    तब राजा ने हाथ जोड़कर कहा: महात्मन्‌! सभी प्रकार से धर्म का पालन करता हुआ भी मैं अपने राज्य में दुर्भिक्ष का दृश्य देख रहा हूँ। आखिर किस कारण से ऐसा हो रहा है, कृपया इसका समाधान करें।
                    यह सुनकर महर्षि अंगिरा ने कहा: हे राजन! सब युगों से उत्तम यह सतयुग है। इसमें छोटे से पाप का भी बड़ा भयंकर दंड मिलता है।
                    इसमें धर्म अपने चारों चरणों में व्याप्त रहता है। ब्राह्मण के अतिरिक्त किसी अन्य जाति को तप करने का अधिकार नहीं है जबकि आपके राज्य में एक शूद्र तपस्या कर रहा है। यही कारण है कि आपके राज्य में वर्षा नहीं हो रही है। जब तक वह काल को प्राप्त नहीं होगा, तब तक यह दुर्भिक्ष शांत नहीं होगा। दुर्भिक्ष की शांति उसे मारने से ही संभव है।
                    किंतु राजा का हृदय एक नरपराध शूद्र तपस्वी का शमन करने को तैयार नहीं हुआ।
                    उन्होंने कहा: हे देव मैं उस निरपराध को मार दूँ, यह बात मेरा मन स्वीकार नहीं कर रहा है। कृपा करके आप कोई और उपाय बताएँ।
                    महर्षि अंगिरा ने बताया: आषाढ़ माह के शुक्लपक्ष की एकादशी का व्रत करें। इस व्रत के प्रभाव से अवश्य ही वर्षा होगी।
                    राजा अपने राज्य की राजधानी लौट आए और चारों वर्णों सहित पद्मा एकादशी का विधिपूर्वक व्रत किया। व्रत के प्रभाव से उनके राज्य में मूसलधार वर्षा हुई और पूरा राज्य धन-धान्य से परिपूर्ण हो गया।
                    ब्रह्म वैवर्त पुराण में देवशयनी एकादशी के विशेष माहात्म्य का वर्णन किया गया है। इस व्रत से प्राणी की समस्त मनोकामनाएँ पूर्ण होती हैं।
`,
        end:`॥ जय श्री हरि ॥`
    },
    {
        title:`कामिका एकादशी व्रत कथा`,
        description:`अर्जुन ने कहा: हे प्रभु! मैंने आषाढ़ माह के शुक्ल पक्ष की देवशयनी एकादशी का सविस्तार वर्णन सुना। अब आप मुझे श्रावण माह के कृष्ण पक्ष की एकादशी की कथा सुनाने की कृपा करें। इस एकादशी का नाम क्या है? इसकी विधि क्या है? इसमें किस देवता का पूजन होता है? इसका उपवास करने से मनुष्य को किस फल की प्राप्ति होती है?
                    भगवान श्रीकृष्ण ने कहा: हे श्रेष्ठ धनुर्धर! मैं श्रावण माह की पवित्र एकादशी की कथा सुनाता हूँ, ध्यानपूर्वक श्रवण करो। एक बार इस एकादशी की पावन कथा को भीष्म पितामह ने लोकहित के लिये नारदजी से कहा था।
                    एक समय नारदजी ने कहा: हे पितामह! आज मेरी श्रावण के कृष्ण पक्ष की एकादशी की कथा सुनने की इच्छा है, अतः आप इस एकादशी की व्रत कथा विधान सहित सुनाइये।
                    नारदजी की इच्छा को सुन पितामह भीष्म ने कहा: हे नारदजी! आपने बहुत ही सुन्दर प्रस्ताव किया है। अब आप बहुत ध्यानपूर्वक इसे श्रवण कीजिए- श्रावण माह के कृष्ण पक्ष की एकादशी का नाम कामिका एकादशी है। इस एकादशी की कथा सुनने मात्र से ही वाजपेय यज्ञ के फल की प्राप्ति होती है। कामिका एकादशी के उपवास में शंख, चक्र, गदाधारी भगवान विष्णु का पूजन होता है। जो मनुष्य इस एकादशी को धूप, दीप, नैवेद्य आदि से भगवान विष्णु की पूजा करते हैं, उन्हें गंगा स्नान के फल से भी उत्तम फल की प्राप्ति होती है।
                    सूर्य ग्रहण और चन्द्र ग्रहण में केदार और कुरुक्षेत्र में स्नान करने से जिस पुण्य की प्राप्ति होती है, वह पुण्य कामिका एकादशी के दिन भगवान विष्णु की भक्तिपूर्वक पूजा करने से प्राप्त हो जाता है।
                    भगवान विष्णु की श्रावण माह में भक्तिपूर्वक पूजा करने का फल समुद्र और वन सहित पृथ्वी दान करने के फल से भी ज्यादा होता है।
                    व्यतिपात में गंडकी नदी में स्नान करने से जो फल प्राप्त होता है, वह फल भगवान की पूजा करने से मिल जाता है।
                    संसार में भगवान की पूजा का फल सबसे ज्यादा है, अतः भक्तिपूर्वक भगवान की पूजा न बन सके तो श्रावण माह के कृष्ण पक्ष की कामिका एकादशी का उपवास करना चाहिये।
                    आभूषणों से युक्त बछड़ा सहित गौदान करने से जो फल प्राप्त होता है, वह फल कामिका एकादशी के उपवास से मिल जाता है।
                    जो उत्तम द्विज श्रावण माह के कृष्ण पक्ष की कामिका एकादशी का उपवास करते हैं तथा भगवान विष्णु का पूजन करते हैं, उनसे सभी देव, नाग, किन्नर, पितृ आदि की पूजा हो जाती है, इसलिये पाप से डरने वाले व्यक्तियों को विधि-विधान सहित इस उपवास को करना चाहिये।
                    संसार सागर तथा पापों में फँसे हुए मनुष्यों को इनसे मुक्ति के लिये कामिका एकादशी का व्रत करना चाहिये।
                    कामिका एकादशी के उपवास से भी पाप नष्ट हो जाते हैं, संसार में इससे अधिक पापों को नष्ट करने वाला कोई और उपाय नहीं है।
                    हे नारदजी! स्वयं भगवान ने अपने मुख से कहा है कि मनुष्यों को अध्यात्म विद्या से जो फल प्राप्त होता है, उससे अधिक फल कामिका एकादशी का व्रत करने से मिल जाता है। इस उपवास के करने से मनुष्य को न यमराज के दर्शन होते हैं और न ही नरक के कष्ट भोगने पड़ते हैं। वह स्वर्ग का अधिकारी बन जाता है।
                    जो मनुष्य इस दिन तुलसीदल से भक्तिपूर्वक भगवान विष्णु का पूजन करते हैं, वे इस संसार सागर में रहते हुए भी इस प्रकार अलग रहते हैं, जिस प्रकार कमल पुष्प जल में रहता हुआ भी जल से अलग रहता है।
                    तुलसीदल से भगवान श्रीहरि का पूजन करने का फल एक बार स्वर्ण और चार बार चाँदी के दान के फल के बराबर है। भगवान विष्णु रत्न, मोती, मणि आदि आभूषणों की अपेक्षा तुलसीदल से अधिक प्रसन्न होते हैं।
                    जो मनुष्य प्रभु का तुलसीदल से पूजन करते हैं, उनके सभी पाप नष्ट हो जाते हैं। हे नारदजी! मैं भगवान की अति प्रिय श्री तुलसीजी को प्रणाम करता हूँ।
                    तुलसीजी के दर्शन मात्र से मनुष्य के सभी पाप नष्ट हो जाते हैं और शरीर के स्पर्श मात्र से मनुष्य पवित्र हो जाता है। तुलसीजी को जल से स्नान कराने से मनुष्य की सभी यम यातनाएं नष्ट हो जाती हैं। जो मनुष्य तुलसीजी को भक्तिपूर्वक भगवान के श्रीचरण कमलों में अर्पित करता है, उसे मुक्ति मिलती है।
                    इस कामिका एकादशी की रात्रि को जो मनुष्य जागरण करते हैं और दीप-दान करते हैं, उनके पुण्यों को लिखने में चित्रगुप्त भी असमर्थ हैं। एकादशी के दिन जो मनुष्य भगवान के सामने दीपक जलाते हैं, उनके पितर स्वर्गलोक में अमृत का पान करते हैं।
                    भगवान के सामने जो मनुष्य घी या तिल के तेल का दीपक जलाते हैं, उनको सूर्य लोक में भी सहस्रों दीपकों का प्रकाश मिलता है।
                    कामिका एकादशी व्रत कथा!
                    एक गाँव में एक वीर क्षत्रिय रहता था। एक दिन किसी कारण वश उसकी ब्राह्मण से हाथापाई हो गई और ब्राह्मण की मृत्य हो गई। अपने हाथों मरे गये ब्राह्मण की क्रिया उस क्षत्रिय ने करनी चाही। परन्तु पंडितों ने उसे क्रिया में शामिल होने से मना कर दिया। ब्राह्मणों ने बताया कि तुम पर ब्रह्म-हत्या का दोष है। पहले प्रायश्चित कर इस पाप से मुक्त हो तब हम तुम्हारे घर भोजन करेंगे।
                    इस पर क्षत्रिय ने पूछा कि इस पाप से मुक्त होने के क्या उपाय है। तब ब्राह्मणों ने बताया कि श्रावण माह के कृष्ण पक्ष की एकादशी को भक्तिभाव से भगवान श्रीधर का व्रत एवं पूजन कर 
                    ब्राह्मणों को भोजन कराके सदश्रिणा के साथ आशीर्वाद प्राप्त करने से इस पाप से मुक्ति मिलेगी। पंडितों के बताये हुए तरीके पर व्रत कराने वाली रात में भगवान श्रीधर ने क्षत्रिय को दर्शन देकर 
                    कहा कि तुम्हें ब्रह्म-हत्या के पाप से मुक्ति मिल गई है। इस व्रत के करने से ब्रह्म-हत्या आदि के सभी पाप नष्ट हो जाते हैं और इहलोक में सुख भोगकर प्राणी अन्त में विष्णुलोक को जाते हैं। 
                    इस कामिका एकादशी के माहात्म्य के श्रवण व पठन से मनुष्य स्वर्गलोक को प्राप्त करते हैं। `,
        end:`॥ जय श्री हरि ॥`
    },
    {
        title:`पुत्रदा  एकादशी व्रत कथा`,
        description:`श्री युधिष्ठिर कहने लगे कि हे भगवान! मैंने श्रावण माह के कृष्ण पक्ष की कामिका एकादशी का सविस्तार वर्णन सुना। अब आप मुझे श्रावण शुक्ल एकादशी का क्या नाम है? व्रत करने की विधि तथा इसका माहात्म्य कृपा करके कहिए। मधुसूदन कहने लगे कि इस एकादशी का नाम पुत्रदा एकादशी है। इस एकादशी को पवित्रा एकादशी भी कहा जाता है।
                    पुत्रदा एकादशी व्रत करने वाले व्यक्ति को पूजा के बाद श्रावण पुत्रदा एकादशी व्रत की कथा जरूर सुननी चाहिए, ऐसा करने से व्रत पूर्ण होता है और मनोकामनाएं पूरी होती हैं। अब आप शांतिपूर्वक इसकी कथा सुनिए। इसके सुनने मात्र से ही वायपेयी यज्ञ का फल मिलता है।
                    पुत्रदा एकादशी व्रत कथा!
                    द्वापर युग के आरंभ में महिष्मति नाम की एक नगरी थी, जिसमें महीजित नाम का राजा राज्य करता था, लेकिन पुत्रहीन होने के कारण राजा को राज्य सुखदायक नहीं लगता था। उसका मानना था, कि जिसके संतान न हो, उसके लिए यह लोक और परलोक दोनों ही दु:खदायक होते हैं।
                    पुत्र सुख की प्राप्ति के लिए राजा ने अनेक उपाय किए परंतु राजा को पुत्र की प्राप्ति नहीं हुई।
                    वृद्धावस्था आती देखकर राजा ने प्रजा के प्रतिनिधियों को बुलाया और कहा: हे प्रजाजनों! मेरे खजाने में अन्याय से उपार्जन किया हुआ धन नहीं है। न मैंने कभी देवताओं तथा ब्राह्मणों का धन छीना है। किसी दूसरे की धरोहर भी मैंने नहीं ‍ली, प्रजा को पुत्र के समान पालता रहा। मैं अपराधियों को पुत्र तथा बाँधवों की तरह दंड देता रहा। कभी किसी से घृणा नहीं की। सबको समान माना है। सज्जनों की सदा पूजा करता हूँ। इस प्रकार धर्मयुक्त राज्य करते हुए भी मेरे पु‍त्र नहीं है। सो मैं अत्यंत दु:ख पा रहा हूँ, इसका क्या कारण है?
                    राजा महीजित की इस बात को विचारने के लिए मं‍त्री तथा प्रजा के प्रतिनिधि वन को गए। वहाँ बड़े-बड़े ऋषि-मुनियों के दर्शन किए। राजा की उत्तम कामना की पूर्ति के लिए किसी श्रेष्ठ तपस्वी मुनि को देखते-फिरते रहे।
                    एक आश्रम में उन्होंने एक अत्यंत वयोवृद्ध धर्म के ज्ञाता, बड़े तपस्वी, परमात्मा में मन लगाए हुए निराहार, जितेंद्रीय, जितात्मा, जितक्रोध, सनातन धर्म के गूढ़ तत्वों को जानने वाले, समस्त शास्त्रों के ज्ञाता महात्मा लोमश मुनि को देखा, जिनका कल्प के व्यतीत होने पर एक रोम गिरता था।
                    सबने जाकर ऋषि को प्रणाम किया। उन लोगों को देखकर मुनि ने पूछा कि आप लोग किस कारण से आए हैं? नि:संदेह मैं आप लोगों का हित करूँगा। मेरा जन्म केवल दूसरों के उपकार के लिए हुआ है, इसमें संदेह मत करो।
                    लोमश ऋषि के ऐसे वचन सुनकर सब लोग बोले: हे महर्षे! आप हमारी बात जानने में ब्रह्मा से भी अधिक समर्थ हैं। अत: आप हमारे इस संदेह को दूर कीजिए। महिष्मति पुरी का धर्मात्मा राजा महीजित प्रजा का पुत्र के समान पालन करता है। फिर भी वह पुत्रहीन होने के कारण दु:खी है।
                    उन लोगों ने आगे कहा कि हम लोग उसकी प्रजा हैं। अत: उसके दु:ख से हम भी दु:खी हैं। आपके दर्शन से हमें पूर्ण विश्वास है कि हमारा यह संकट अवश्य दूर हो जाएगा क्योंकि महान पुरुषों के दर्शन मात्र से अनेक कष्ट दूर हो जाते हैं। अब आप कृपा करके राजा के पुत्र होने का उपाय बतलाएँ।
                    यह वार्ता सुनकर ऋषि ने थोड़ी देर के लिए नेत्र बंद किए और राजा के पूर्व जन्म का वृत्तांत जानकर कहने लगे कि यह राजा पूर्व जन्म में एक निर्धन वैश्य था। निर्धन होने के कारण इसने कई बुरे कर्म किए। यह एक गाँव से दूसरे गाँव व्यापार करने जाया करता था।
                    एक समय ज्येष्ठ मास के शुक्ल पक्ष की द्वादशी के दिन मध्याह्न के समय वह जबकि वह दो दिन से भूखा-प्यासा था, एक जलाशय पर जल पीने गया। उसी स्थान पर एक तत्काल की ब्याही हुई प्यासी गौ जल पी रही थी।
                    राजा ने उस प्यासी गाय को जल पीते हुए हटा दिया और स्वयं जल पीने लगा, इसीलिए राजा को यह दु:ख सहना पड़ा। एकादशी के दिन भूखा रहने से वह राजा हुआ और प्यासी गौ को जल पीते हुए हटाने के कारण पुत्र वियोग का दु:ख सहना पड़ रहा है। ऐसा सुनकर सब लोग कहने लगे कि हे ऋषि! शास्त्रों में पापों का प्रायश्चित भी लिखा है। अत: जिस प्रकार राजा का यह पाप नष्ट हो जाए, आप ऐसा उपाय बताइए।
                    लोमश मुनि कहने लगे कि श्रावण शुक्ल पक्ष की एकादशी को जिसे पुत्रदा एकादशी भी कहते हैं, तुम सब लोग व्रत करो और रात्रि को जागरण करो तो इससे राजा का यह पूर्व जन्म का पाप अवश्य नष्ट हो जाएगा, साथ ही राजा को पुत्र की अवश्य प्राप्ति होगी।
                    लोमश ऋषि के ऐसे वचन सुनकर मंत्रियों सहित सारी प्रजा नगर को वापस लौट आई और जब श्रावण शुक्ल एकादशी आई तो ऋषि की आज्ञानुसार सबने पुत्रदा एकादशी का व्रत और जागरण किया।
                    इसके पश्चात द्वादशी के दिन इसके पुण्य का फल राजा को दिया गया। उस पुण्य के प्रभाव से रानी ने गर्भ धारण किया और प्रसवकाल समाप्त होने पर उसके एक बड़ा तेजस्वी पुत्र उत्पन्न हुआ।
                    इसलिए हे राजन! इस श्रावण शुक्ल एकादशी का नाम पुत्रदा पड़ा। अत: संतान सुख की इच्छा हासिल करने वाले इस व्रत को अवश्य करें। इसके माहात्म्य को सुनने से मनुष्य सब पापों से मुक्त हो जाता है और इस लोक में संतान सुख भोगकर परलोक में स्वर्ग को प्राप्त होता है।
                    `,
        end:`॥ जय श्री हरि ॥`
    },
    {
        title:`अजा एकादशी व्रत कथा`,
        description:`अर्जुन ने कहा: हे पुण्डरिकाक्ष! मैंने श्रावण शुक्ल एकादशी अर्थात पुत्रदा एकादशी का सविस्तार वर्णन सुना। अब आप कृपा करके मुझे भाद्रपद माह के कृष्ण पक्ष की एकादशी के विषय में भी बतलाइये। इस एकादशी का क्या नाम है तथा इसके व्रत का क्या विधान है? इसका व्रत करने से किस फल की प्राप्ति होती है?
                    श्रीकृष्ण ने कहा: हे कुन्ती पुत्र! भाद्रपद माह के कृष्ण पक्ष की एकादशी को अजा एकादशी कहते हैं। इसका व्रत करने से सभी पाप नष्ट हो जाते हैं। इसलोक और परलोक में मदद करने वाली इस एकादशी व्रत के समान संसार में दूसरा कोई व्रत नहीं है।
                    अजा एकादशी व्रत कथा!
                    अब ध्यानपूर्वक इस एकादशी का माहात्म्य श्रवण करो: पौराणिक काल में भगवान श्री राम के वंश में अयोध्या नगरी में एक चक्रवर्ती राजा हरिश्चन्द्र नाम के एक राजा हुए थे। राजा अपनी सत्यनिष्ठा और ईमानदारी के लिए प्रसिद्घ थे।
                    एक बार देवताओं ने इनकी परीक्षा लेने की योजना बनाई। राजा ने स्वप्न में देखा कि ऋषि विश्ववामित्र को उन्होंने अपना राजपाट दान कर दिया है। सुबह विश्वामित्र वास्तव में उनके द्वार पर आकर कहने लगे तुमने स्वप्न में मुझे अपना राज्य दान कर दिया।
                    राजा ने सत्यनिष्ठ व्रत का पालन करते हुए संपूर्ण राज्य विश्वामित्र को सौंप दिया। दान के लिए दक्षिणा चुकाने हेतु राजा हरिश्चन्द्र को पूर्व जन्म के कर्म फल के कारण पत्नी, बेटा एवं खुद को बेचना पड़ा। हरिश्चन्द्र को एक डोम ने खरीद लिया जो श्मशान भूमि में लोगों के दाह संस्कारा का काम करवाता था।
                    स्वयं वह एक चाण्डाल का दास बन गया। उसने उस चाण्डाल के यहाँ कफन लेने का काम किया, किन्तु उसने इस आपत्ति के काम में भी सत्य का साथ नहीं छोड़ा।
                    जब इसी प्रकार उसे कई वर्ष बीत गये तो उसे अपने इस नीच कर्म पर बड़ा दुख हुआ और वह इससे मुक्त होने का उपाय खोजने लगा। वह सदैव इसी चिन्ता में रहने लगा कि मैं क्या करूँ? किस प्रकार इस नीच कर्म से मुक्ति पाऊँ? एक बार की बात है, वह इसी चिन्ता में बैठा था कि गौतम् ऋषि उसके पास पहुँचे। हरिश्चन्द्र ने उन्हें प्रणाम किया और अपनी दुःख-भरी कथा सुनाने लगे।
                    राजा हरिश्चन्द्र की दुख-भरी कहानी सुनकर महर्षि गौतम भी अत्यन्त दुःखी हुए और उन्होंने राजा से कहा: हे राजन! भादों माह के कृष्ण पक्ष की एकादशी का नाम अजा है। तुम उस एकादशी का विधानपूर्वक व्रत करो तथा रात्रि को जागरण करो। इससे तुम्हारे सभी पाप नष्ट हो जाएंगे।
                    महर्षि गौतम इतना कहकर आलोप हो गये। अजा नाम की एकादशी आने पर राजा हरिश्चन्द्र ने महर्षि के कहे अनुसार विधानपूर्वक उपवास तथा रात्रि जागरण किया। इस व्रत के प्रभाव से राजा के सभी पाप नष्ट हो गये। उस समय स्वर्ग में नगाड़े बजने लगे तथा पुष्पों की वर्षा होने लगी। उन्होने अपने सामने ब्रह्मा, विष्णु, महेश तथा देवेन्द्र आदि देवताओं को खड़ा पाया। उन्होने अपने मृतक पुत्र को जीवित तथा अपनी पत्नी को राजसी वस्त्र तथा आभूषणों से परिपूर्ण देखा।
                    व्रत के प्रभाव से राजा को पुनः अपने राज्य की प्राप्ति हुई। वास्तव में एक ऋषि ने राजा की परीक्षा लेने के लिए यह सब कौतुक किया था, परन्तु अजा एकादशी के व्रत के प्रभाव से ऋषि द्वारा रची गई सारी माया समाप्त हो गई और अन्त समय में हरिश्चन्द्र अपने परिवार सहित स्वर्ग लोक को गया।
                    हे राजन! यह सब अजा एकादशी के व्रत का प्रभाव था।
                    जो मनुष्य इस उपवास को विधानपूर्वक करते हैं तथा रात्रि-जागरण करते हैं, उनके सभी पाप नष्ट हो जाते हैं और अन्त में वे स्वर्ग को प्राप्त करते हैं। इस एकादशी व्रत की कथा के श्रवण मात्र से ही अश्वमेध यज्ञ के फल की प्राप्ति हो जाती है। 
                    `,
        end:`॥ जय श्री हरि ॥`
    },
    {
        title:`परिवर्तिनी एकादशी व्रत कथा`,
        description:`युधिष्ठिर ने कहा हे भगवान! आपने भाद्रपद कृष्ण एकादशी अर्थात अजा एकादशी का सविस्तार वर्णन सुनाया। अब आप कृपा करके मुझे भाद्रपद शुक्ल एकादशी का क्या नाम, इसकी विधि तथा इसका माहात्म्य कहिए। तब भगवान श्रीकृष्ण कहने लगे कि इस पुण्य, स्वर्ग और मोक्ष को देने वाली तथा सब पापों का नाश करने वाली, उत्तम वामन एकादशी का माहात्म्य मैं तुमसे कहता हूँ, आप इसे ध्यानपूर्वक सुनें।
                    यह भाद्रपद शुक्ल एकादशी को पद्मा एकादशी, परिवर्तिनी एकादशी, जयंती एकादशी, जल झुलनी एकादशी, देवझूलनी एकादशी एवं वामन एकादशी भी कहते हैं। इस दिन यज्ञ करने से वाजपेय यज्ञ का फल मिलता है। पापियों के पाप नाश करने के लिए इससे बढ़कर कोई उपाय नहीं। जो मनुष्य परिवर्तिनी एकादशी के दिन मेरे वामन रूप की पूजा करता है, उससे तीनों लोक पूज्य होते हैं। अत: मोक्ष की इच्छा करने वाले मनुष्य को इस व्रत को अवश्य करना चाहिए।
                    जो कमलनयन भगवान का कमल से पूजन करते हैं, वे अवश्य भगवान के समीप जाते हैं। जिसने भाद्रपद शुक्ल एकादशी को व्रत और पूजन किया, उसने ब्रह्मा, विष्णु सहित तीनों लोकों का पूजन किया। अत: हरिवासर अर्थात एकादशी का व्रत अवश्य करना चाहिए। इस दिन भगवान करवट लेते हैं, इसलिए इसको परिवर्तिनी एकादशी भी कहा जाता है।
                    भगवान के वचन सुनकर युधिष्ठिर बोले कि भगवान! मुझे अतिसंदेह हो रहा है कि आप किस प्रकार सोते और करवट लेते हैं तथा किस तरह राजा बलि को बांधा और वामन रूप रखकर क्या-क्या लीलाएं कीं? चातुर्मास के व्रत की क्या विधि है तथा आपके शयन करने पर मनुष्य का क्या कर्तव्य है। सो आप मुझसे विस्तार से बताइए।
                    पार्श्व एकादशी व्रत कथा!
                    श्रीकृष्ण कहने लगे कि हे राजन! अब आप सब पापों को नष्ट करने वाली कथा का श्रवण करें। त्रेतायुग में बलि नामक एक दैत्य था। वह मेरा परम भक्त था। विविध प्रकार के वेद सूक्तों से मेरा पूजन किया करता था और नित्य ही ब्राह्मणों का पूजन तथा यज्ञ के आयोजन करता था, लेकिन इंद्र से द्वेष के कारण उसने इंद्रलोक तथा सभी देवताओं को जीत लिया।
                    इस कारण सभी देवता एकत्र होकर सोच-विचार कर भगवान के पास गए। बृहस्पति सहित इंद्रादिक देवता प्रभु के निकट जाकर और नतमस्तक होकर वेद मंत्रों द्वारा भगवान का पूजन और स्तुति करने लगे। अत: मैंने वामन रूप धारण करके पांचवां अवतार लिया और फिर अत्यंत तेजस्वी रूप से राजा बलि को जीत लिया।
                    इतनी वार्ता सुनकर राजा युधिष्ठिर बोले कि हे जनार्दन! आपने वामन रूप धारण करके उस महाबली दैत्य को किस प्रकार जीता?
                    श्रीकृष्ण कहने लगे: मैंने वामन रूपधारी ब्रह्मचारी, बलि से तीन पग भूमि की याचना करते हुए कहा: ये मुझको तीन लोक के समान है और हे राजन यह तुमको अवश्य ही देनी होगी।
                    राजा बलि ने इसे तुच्छ याचना समझकर तीन पग भूमि का संकल्प मुझको दे दिया और मैंने अपने त्रिविक्रम रूप को बढ़ाकर यहां तक कि भूलोक में पद, भुवर्लोक में जंघा, स्वर्गलोक में कमर, मह:लोक में पेट, जनलोक में हृदय, यमलोक में कंठ की स्थापना कर सत्यलोक में मुख, उसके ऊपर मस्तक स्थापित किया।
                    सूर्य, चंद्रमा आदि सब ग्रह गण, योग, नक्षत्र, इंद्रादिक देवता और शेष आदि सब नागगणों ने विविध प्रकार से वेद सूक्तों से प्रार्थना की। तब मैंने राजा बलि का हाथ पकड़कर कहा कि हे राजन! एक पद से पृथ्वी, दूसरे से स्वर्गलोक पूर्ण हो गए। अब तीसरा पग कहां रखूं?
                    तब बलि ने अपना सिर झुका लिया और मैंने अपना पैर उसके मस्तक पर रख दिया जिससे मेरा वह भक्त पाताल को चला गया। फिर उसकी विनती और नम्रता को देखकर मैंने कहा कि हे बलि! मैं सदैव तुम्हारे निकट ही रहूंगा। विरोचन पुत्र बलि से कहने पर भाद्रपद शुक्ल एकादशी के दिन बलि के आश्रम पर मेरी मूर्ति स्थापित हुई।
                    इसी प्रकार दूसरी क्षीरसागर में शेषनाग के पष्ठ पर हुई! हे राजन! इस एकादशी को भगवान शयन करते हुए करवट लेते हैं, इसलिए तीनों लोकों के स्वामी भगवान विष्णु का उस दिन पूजन करना चाहिए। इस दिन तांबा, चांदी, चावल और दही का दान करना उचित है। रात्रि को जागरण अवश्य करना चाहिए।
                    जो विधिपूर्वक इस एकादशी का व्रत करते हैं, वे सब पापों से मुक्त होकर स्वर्ग में जाकर चंद्रमा के समान प्रकाशित होते हैं और यश पाते हैं। जो पापनाशक इस कथा को पढ़ते या सुनते हैं, उनको हजार अश्वमेध यज्ञ का फल प्राप्त होता है।`,
        end:`॥ जय श्री हरि ॥`
    },
    {
        title:`इंदिरा एकादशी व्रत कथा`,
        description:`धर्मराज युधिष्ठिर कहने लगे कि हे भगवान! मैंने भाद्रपद शुक्ल एकादशी अर्थात पार्श्व एकादशी का सविस्तार वर्णन सुना। अब आप कृपा करके मुझे आश्विन/क्वार माह के कृष्ण पक्ष की एकादशी के विषय में भी बतलाइये। इस एकादशी का क्या नाम है तथा इसके व्रत का क्या विधान है? इसका व्रत करने से किस फल की प्राप्ति होती है?
                    भगवान श्रीकृष्ण कहने लगे कि आश्विन कृष्ण एकादशी का नाम इंदिरा एकादशी है। यह एकादशी पापों को नष्ट करने वाली तथा पितरों को अ‍धोगति से मुक्ति देने वाली होती है। हे राजन! ध्यानपूर्वक इसकी कथा सुनो। इसके सुनने मात्र से ही वायपेय यज्ञ का फल मिलता है।
                    इंदिरा एकादशी व्रत कथा!
                    प्राचीनकाल में सतयुग के समय में महिष्मति नाम की एक नगरी में इंद्रसेन नाम का एक प्रतापी राजा धर्मपूर्वक अपनी प्रजा का पालन करते हुए शासन करते थे। वह राजा पुत्र, पौत्र और धन आदि से संपन्न और भगवान विष्णु के परम भक्त थे। एक दिन जब राजा सुखपूर्वक अपनी सभा में बैठे थे, तो आकाश मार्ग से महर्षि नारद उतरकर उसकी सभा में आए। राजा उन्हें देखते ही हाथ जोड़कर खड़े हो गये और विधिपूर्वक आसन व अर्घ्य दिया।
                    सुख से बैठकर मुनि ने राजा से पूछा कि हे राजन! आपके सातों अंग कुशलपूर्वक तो हैं? तुम्हारी बुद्धि धर्म में और तुम्हारा मन विष्णु भक्ति में तो रहता है? देवर्षि नारद की ऐसी बातें सुनकर राजा ने कहा: हे महर्षि! आपकी कृपा से मेरे राज्य में सब कुशल है तथा मेरे यहाँ यज्ञ कर्मादि सुकृत हो रहे हैं। आप कृपा करके अपने आगमन का कारण कहिए।
                    तब ऋषि कहने लगे कि हे राजन! आप आश्चर्य देने वाले मेरे वचनों को सुनो।..
                    ..मैं एक समय ब्रह्मलोक से यमलोक को गया, वहाँ श्रद्धापूर्वक यमराज से पूजित होकर मैंने धर्मशील और सत्यवान धर्मराज की प्रशंसा की। उसी यमराज की सभा में महान ज्ञानी और धर्मात्मा तुम्हारे पिता को एकादशी का व्रत भंग होने के कारण देखा। उन्होंने संदेशा दिया सो मैं तुम्हें कहता हूँ। उन्होंने कहा कि पूर्व जन्म में ‍कोई विघ्न हो जाने के कारण मैं यमराज के निकट रह रहा हूँ, सो हे पुत्र यदि तुम आश्विन कृष्णा इंदिरा एकादशी का व्रत मेरे निमित्त करो तो मुझे स्वर्ग की प्राप्ति हो सकती है।
                    इतना सुनकर राजा कहने लगा कि, हे महर्षि आप इस व्रत की विधि मुझसे कहिए।
                    नारदजी कहने लगे: आश्विन माह की कृष्ण पक्ष की दशमी के दिन प्रात:काल श्रद्धापूर्वक स्नानादि से निवृत्त होकर पुन: दोपहर को नदी आदि में जाकर स्नान करें। फिर श्रद्धापूर्व पितरों का श्राद्ध करें और एक बार भोजन करें। प्रात:काल होने पर एकादशी के दिन दातून आदि करके स्नान करें, फिर व्रत के नियमों को भक्तिपूर्वक ग्रहण करता हुआ प्रतिज्ञा करें कि मैं आज संपूर्ण भोगों को त्याग कर निराहार एकादशी का व्रत करूँगा।
                    हे अच्युत! हे पुंडरीकाक्ष! मैं आपकी शरण हूँ, आप मेरी रक्षा कीजिए, इस प्रकार नियमपूर्वक शालिग्राम की मूर्ति के आगे विधिपूर्वक श्राद्ध करके योग्य ब्राह्मणों को फलाहार का भोजन कराएँ और दक्षिणा दें। पितरों के श्राद्ध से जो बच जाए उसको सूँघकर गौ को दें तथा ध़ूप, दीप, गंध, ‍पुष्प, नैवेद्य आदि सब सामग्री से ऋषिकेश भगवान का पूजन करें।
                    रात में भगवान के निकट जागरण करें। इसके पश्चात द्वादशी के दिन प्रात:काल होने पर भगवान का पूजन करके ब्राह्मणों को भोजन कराएँ। भाई-बंधुओं, स्त्री और पुत्र सहित आप भी मौन होकर भोजन करें।
                    नारदजी कहने लगे कि हे राजन! इस विधि से यदि तुम आलस्य रहित होकर इस एकादशी का व्रत करोगे तो तुम्हारे पिता अवश्य ही स्वर्गलोक को जाएँगे। इतना कहकर नारदजी अंतर्ध्यान हो गए।
                    नारदजी के कथनानुसार राजा द्वारा अपने बाँधवों तथा दासों सहित व्रत करने से आकाश से पुष्पवर्षा हुई और उस राजा का पिता गरुड़ पर चढ़कर विष्णुलोक को गया। राजा इंद्रसेन भी एकादशी के व्रत के प्रभाव से निष्कंटक राज्य करके अंत में अपने पुत्र को सिंहासन पर बैठाकर स्वर्गलोक को गया।
                    हे युधिष्ठिर! यह इंदिरा एकादशी के व्रत का माहात्म्य मैंने तुमसे कहा। इसके पढ़ने और सुनने से मनुष्य सब पापों से छूट जाते हैं और सब प्रकार के भोगों को भोगकर बैकुंठ को प्राप्त होते हैं।
                    `,
        end:`॥ जय श्री हरि ॥`
    },
    {
        title:`पापांकुशा एकादशी व्रत कथा`,
        description:`अर्जुन कहने लगे कि हे जगदीश्वर! मैंने आश्विन कृष्ण एकादशी अर्थात इंदिरा एकादशी का सविस्तार वर्णन सुना। अब आप कृपा करके मुझे आश्विन/क्वार माह के शुक्ल पक्ष की एकादशी के विषय में भी बतलाइये। इस एकादशी का क्या नाम है तथा इसके व्रत का क्या विधान है? इसका व्रत करने से किस फल की प्राप्ति होती है? कृपया यह सब विधानपूर्वक कहिए।
                    भगवान श्रीकृष्ण ने कहा: हे कुंतीनंदन! आश्विन माह के शुक्ल पक्ष की एकादशी का नाम पापांकुशा एकादशी है। इसका व्रत करने से सभी पाप नष्ट हो जाते हैं तथा व्रत करने वाला अक्षय पुण्य का भागी होता है।
                    आश्विन शुक्ल एकादशी के दिन इच्छित फल की प्राप्ति के लिए भगवान विष्णु का पूजन करना चाहिए। इस पूजन के द्वारा मनुष्य को स्वर्ग लोक की प्राप्ति होती है। हे अर्जुन! जो मनुष्य कठिन तपस्याओं के द्वारा फल की प्राप्ति करते हैं, वह फल इस एकादशी के दिन क्षीर-सागर में शेषनाग पर शयन करने वाले भगवान विष्णु को नमस्कार कर देने से मिल जाता है और मनुष्य को यम के दुख नहीं भोगने पड़ते।
                    मनुष्य को पापों से बचने का दृढ़-संकल्प करना चाहिए। भगवान विष्णु का ध्यान-स्मरण किसी भी रूप में सुखदायक और पापनाशक है, परंतु पापांकुशा एकादशी के दिन प्रभु का स्मरण-कीर्तन सभी क्लेशों व पापों का शमन कर देता है।
                    पापांकुशा एकादशी व्रत कथा!
                    प्राचीन समय में विंध्य पर्वत पर क्रोधन नामक एक बहेलिया रहता था, वह बड़ा क्रूर था। उसका सारा जीवन हिंसा, लूटपाट, मद्यपान और गलत संगति पाप कर्मों में बीता।
                    जब उसका अंतिम समय आया तब यमराज के दूत बहेलिये को लेने आए और यमदूत ने बहेलिये से कहा कि कल तुम्हारे जीवन का अंतिम दिन है हम तुम्हें कल लेने आएंगे। यह बात सुनकर बहेलिया बहुत भयभीत हो गया और महर्षि अंगिरा के आश्रम में पहुंचा और महर्षि अंगिरा के चरणों पर गिरकर प्रार्थना करने लगा, हे ऋषिवर! मैंने जीवन भर पाप कर्म ही किए हैं।
                    कृपा कर मुझे कोई ऐसा उपाय बताएं, जिससे मेरे सारे पाप मिट जाएं और मोक्ष की प्राप्ति हो जाए। उसके निवेदन पर महर्षि अंगिरा ने उसे आश्विन शुक्ल की पापांकुशा एकादशी का विधि पूर्वक व्रत करके को कहा।
                    महर्षि अंगिरा के कहे अनुसार उस बहेलिए ने यह व्रत किया और किए गए सारे पापों से छुटकारा पा लिया और इस व्रत पूजन के बल से भगवान की कृपा से वह विष्णु लोक को गया। जब यमराज के यमदूत ने इस चमत्कार को देखा तो वह बहेलिया को बिना लिए ही यमलोक वापस लौट गए।
                    `,
        end:`॥ जय श्री हरि ॥`
    },
    {
        title:`रमा एकादशी व्रत कथा`,
        description:`धर्मराज युधिष्ठिर कहने लगे कि हे भगवान! मैंने आश्विन शुक्ल एकादशी अर्थात पापांकुशा एकादशी का सविस्तार वर्णन सुना। अब आप कृपा करके मुझे कार्तिक माह के कृष्ण पक्ष की एकादशी के विषय में भी बतलाइये। इस एकादशी का क्या नाम है तथा इसके व्रत का क्या विधान है? इसकी विधि क्या है? इसका व्रत करने से किस फल की प्राप्ति होती है? कृपया यह सब विधानपूर्वक कहिए।
                    भगवान श्रीकृष्ण बोले: कार्तिक माह के कृष्ण पक्ष मे दीपावली के चार दिन पहले आने वाली इस एकादशी को लक्ष्मी जी के नाम पर रमा एकादशी कहा जाता है। इसे पुण्यदायिनी एकादशी के नाम से भी जाना जाता है। इसके प्रभाव से ब्रह्महत्या जैसे महापाप भी दूर हो जाते हैं। यह बड़े-बड़े पापों का नाश करने वाली है। इसका माहात्म्य मैं तुमसे कहता हूँ, ध्यानपूर्वक सुनो।
                    रमा एकादशी व्रत कथा!
                    हे राजन! प्राचीनकाल में मुचुकुंद नाम के एक राजा थे। उसकी इंद्र के साथ मित्रता थी और साथ ही यम, कुबेर, वरुण और विभीषण भी उसके मित्र थे। यह राजा बड़ा धर्मात्मा, विष्णुभक्त और न्याय के साथ राज करता था। उस राजा की एक कन्या थी, जिसका नाम चंद्रभागा था। उस कन्या का विवाह चंद्रसेन के पुत्र शोभन के साथ हुआ था। एक समय वह शोभन ससुराल आया। उन्हीं दिनों जल्दी ही रमा एकादशी भी आने वाली थी।
                    जब व्रत का दिन समीप आ गया तो चंद्रभागा के मन में अत्यंत सोच उत्पन्न हुआ कि मेरे पति अत्यंत दुर्बल हैं और मेरे पिता की आज्ञा अति कठोर है। दशमी को राजा ने ढोल बजवाकर सारे राज्य में यह घोषणा करवा दी कि एकादशी को भोजन नहीं करना चाहिए। ढोल की घोषणा सुनते ही शोभन को अत्यंत चिंता हुई और अपनी पत्नी से कहा कि हे प्रिये! अब क्या करना चाहिए, मैं किसी प्रकार भी भूख सहन नहीं कर सकूँगा। ऐसा उपाय बतलाओ कि जिससे मेरे प्राण बच सकें, अन्यथा मेरे प्राण अवश्य चले जाएंगे।
                    चंद्रभागा कहने लगी कि हे स्वामी! मेरे पिता के राज में एकादशी के दिन कोई भी भोजन नहीं करता। हाथी, घोड़ा, ऊंट, बिल्ली, गौ आदि भी तृण, अन्न, जल आदि ग्रहण नहीं कर सकते, फिर मनुष्य का तो कहना ही क्या है। यदि आप भोजन करना चाहते हैं तो किसी दूसरे स्थान पर चले जाइए, क्योंकि यदि आप यहीं रहना चाहते हैं तो आपको अवश्य व्रत करना पड़ेगा। ऐसा सुनकर शोभन कहने लगा कि हे प्रिये! मैं अवश्य व्रत करूँगा, जो भाग्य में होगा, वह देखा जाएगा।
                    इस प्रकार से विचार कर शोभन ने व्रत रख लिया और वह भूख व प्यास से अत्यंत पीड़ित होने लगा। जब सूर्य नारायण अस्त हो गए और रात्रि को जागरण का समय आया जो वैष्णवों को अत्यंत हर्ष देने वाला था, परंतु शोभन के लिए अत्यंत दु:खदायी हुआ। प्रात:काल होते शोभन के प्राण निकल गए। तब राजा ने सुगंधित काष्ठ से उसका दाह संस्कार करवाया। परंतु चंद्रभागा ने अपने पिता की आज्ञा से अपने शरीर को दग्ध नहीं किया और शोभन की अंत्येष्टि क्रिया के बाद अपने पिता के घर में ही रहने लगी।
                    रमा एकादशी के प्रभाव से शोभन को मंदराचल पर्वत पर धन-धान्य से युक्त तथा शत्रुओं से रहित एक सुंदर देवपुर प्राप्त हुआ। वह अत्यंत सुंदर रत्न और वैदुर्यमणि जटित स्वर्ण के खंभों पर निर्मित अनेक प्रकार की स्फटिक मणियों से सुशोभित भवन में बहुमूल्य वस्त्राभूषणों तथा छत्र व चंवर से विभूषित, गंधर्व और अप्सराओं से युक्त सिंहासन पर आरूढ़ ऐसा शोभायमान होता था मानो दूसरा इंद्र विराजमान हो।
                    एक समय मुचुकुंद नगर में रहने वाले एक सोम शर्मा नामक ब्राह्मण तीर्थयात्रा करता हुआ घूमता-घूमता उधर जा निकला और उसने शोभन को पहचाना, कि यह तो राजा का जमाई शोभन है, उसके निकट गया। शोभन भी उसे पहचान कर अपने आसन से उठकर उसके पास आया और प्रणामादि करके कुशल प्रश्न किया। ब्राह्मण ने कहा कि राजा मुचुकुंद और आपकी पत्नी कुशल से हैं। नगर में भी सब प्रकार से कुशल हैं, परंतु हे राजन! हमें आश्चर्य हो रहा है। आप अपना वृत्तांत कहिए कि ऐसा सुंदर नगर जो न कभी देखा, न सुना, आपको कैसे प्राप्त हुआ।
                    तब शोभन बोला कि कार्तिक कृष्ण की रमा एकादशी का व्रत करने से मुझे यह नगर प्राप्त हुआ, परंतु यह अस्थिर है। यह स्थिर हो जाए ऐसा उपाय कीजिए। ब्राह्मण कहने लगा कि हे राजन! यह स्थिर क्यों नहीं है और कैसे स्थिर हो सकता है? आप बताइए, फिर मैं अवश्यमेव वह उपाय करूँगा। मेरी इस बात को आप मिथ्या न समझिए। शोभन ने कहा कि मैंने इस व्रत को श्रद्धारहित होकर किया है। अत: यह सब कुछ अस्थिर है। यदि आप मुचुकुंद की कन्या चंद्रभागा को यह सब वृत्तांत कहें तो यह स्थिर हो सकता है।
                    ऐसा सुनकर उस श्रेष्ठ ब्राह्मण ने अपने नगर लौटकर चंद्रभागा से सब वृत्तांत कह सुनाया। ब्राह्मण के वचन सुनकर चंद्रभागा बड़ी प्रसन्नता से ब्राह्मण से कहने लगी कि हे ब्राह्मण! ये सब बातें आपने प्रत्यक्ष देखी हैं या स्वप्न की बातें कर रहे हैं। ब्राह्मण कहने लगा कि हे पुत्री! मैंने महावन में तुम्हारे पति को प्रत्यक्ष देखा है। साथ ही किसी से विजय न हो ऐसा देवताओं के नगर के समान उनका नगर भी देखा है। उन्होंने यह भी कहा कि यह स्थिर नहीं है। किस प्रकार वह स्थिर रह सके सो उपाय करना चाहिए।
                    चंद्रभागा कहने लगी हे विप्र! तुम मुझे वहाँ ले चलो, मुझे पतिदेव के दर्शन की तीव्र लालसा है। मैं अपने किए हुए पुण्य से उस नगर को स्थिर बना दूंगी। आप ऐसा कार्य कीजिए जिससे उनका हमारा संयोग हो क्योंकि वियोगी को मिला देना महान पुण्य है। सोम शर्मा यह बात सुनकर चंद्रभागा को लेकर मंदराचल पर्वत के समीप वामदेव ऋषि के आश्रम पर गया। वामदेवजी ने सारी बात सुनकर वेद मंत्रों के उच्चारण से चंद्रभागा का अभिषेक कर दिया। तब ऋषि के मंत्र के प्रभाव और एकादशी के व्रत से चंद्रभागा का शरीर दिव्य हो गया और वह दिव्य गति को प्राप्त हुई।
                    इसके बाद बड़ी प्रसन्नता के साथ अपने पति के निकट गई। अपनी प्रिय पत्नी को आते देखकर शोभन अति प्रसन्न हुआ। और उसे बुलाकर अपनी बाईं तरफ बिठा लिया। चंद्रभागा कहने लगी कि हे प्राणनाथ! आप मेरे पुण्य को ग्रहण कीजिए। अपने पिता के घर जब मैं आठ वर्ष की थी तब से विधिपूर्वक एकादशी के व्रत को श्रद्धापूर्वक करती आ रही हूँ। इस पुण्य के प्रताप से आपका यह नगर स्थिर हो जाएगा तथा समस्त कर्मों से युक्त होकर प्रलय के अंत तक रहेगा। इस प्रकार चंद्रभागा ने दिव्य आभू‍षणों और वस्त्रों से सुसज्जित होकर अपने पति के साथ आनंदपूर्वक रहने लगी।
                    हे राजन! यह मैंने रमा एकादशी का माहात्म्य कहा है, जो मनुष्य इस व्रत को करते हैं, उनके ब्रह्म हत्यादि समस्त पाप नष्ट हो जाते हैं। कृष्ण पक्ष और शुक्ल पक्ष दोनों ही एका‍दशियां समान हैं, इनमें कोई भेदभाव नहीं है। दोनों समान फल देती हैं। जो मनुष्य इस माहात्म्य को पढ़ते अथवा सुनते हैं, वे समस्त पापों से छूटकर विष्णुलोक को प्राप्त होता हैं।
                    `,
        end:`॥ जय श्री हरि ॥`
    },
    {
        title:`देवोत्थान  एकादशी व्रत कथा`,
        description:`धर्मराज युधिष्ठिर कहने लगे कि हे भगवान! मैंने कार्तिक कृष्ण एकादशी अर्थात रमा एकादशी का सविस्तार वर्णन सुना। अब आप कृपा करके मुझे कार्तिक माह के शुक्ल पक्ष की एकादशी के विषय में भी बतलाइये। इस एकादशी का क्या नाम है तथा इसके व्रत का क्या विधान है? इसकी विधि क्या है? इसका व्रत करने से किस फल की प्राप्ति होती है? कृपया यह सब विधानपूर्वक कहिए।
                    भगवान श्रीकृष्ण बोले: कार्तिक माह के शुक्ल पक्ष मे तुलसी विवाह के दिन आने वाली इस एकादशी को विष्णु प्रबोधिनी एकादशी, देव-प्रबोधिनी एकादशी, देवोत्थान, देव उथव एकादशी, देवउठनी एकादशी, कार्तिक शुक्ल एकादशी तथा प्रबोधिनी एकादशी भी कहा जाता है। यह बड़े-बड़े पापों का नाश करने वाली है, इसका माहात्म्य मैं तुमसे कहता हूँ, ध्यानपूर्वक सुनो।
                    देवोत्थान एकादशी व्रत कथा!
                    एक राजा के राज्य में सभी लोग एकादशी का व्रत रखते थे। प्रजा तथा नौकर-चाकरों से लेकर पशुओं तक को एकादशी के दिन अन्न नहीं दिया जाता था। एक दिन किसी दूसरे राज्य का एक व्यक्ति राजा के पास आकर बोला: महाराज! कृपा करके मुझे नौकरी पर रख लें। तब राजा ने उसके सामने एक शर्त रखी कि ठीक है, रख लेते हैं। किन्तु रोज तो तुम्हें खाने को सब कुछ मिलेगा, पर एकादशी को अन्न नहीं मिलेगा।
                    उस व्यक्ति ने उस समय हाँ कर ली, पर एकादशी के दिन जब उसे फलाहार का सामान दिया गया तो वह राजा के सामने जाकर गिड़गिड़ाने लगा: महाराज! इससे मेरा पेट नहीं भरेगा। मैं भूखा ही मर जाऊंगा, मुझे अन्न दे दो।
                    राजा ने उसे शर्त की बात याद दिलाई, पर वह अन्न छोड़ने को तैयार नहीं हुआ, तब राजा ने उसे आटा-दाल-चावल आदि दिए। वह नित्य की तरह नदी पर पहुंचा और स्नान कर भोजन पकाने लगा। जब भोजन बन गया तो वह भगवान को बुलाने लगा: आओ भगवान! भोजन तैयार है।
                    उसके बुलाने पर पीताम्बर धारण किए भगवान चतुर्भुज रूप में आ पहुँचे तथा प्रेम से उसके साथ भोजन करने लगे। भोजनादि करके भगवान अंतर्धान हो गए तथा वह अपने काम पर चला गया।
                    पंद्रह दिन बाद अगली एकादशी को वह राजा से कहने लगा कि महाराज, मुझे दुगुना सामान दीजिए। उस दिन तो मैं भूखा ही रह गया। राजा ने कारण पूछा तो उसने बताया कि हमारे साथ भगवान भी खाते हैं। इसीलिए हम दोनों के लिए ये सामान पूरा नहीं होता।
                    यह सुनकर राजा को बड़ा आश्चर्य हुआ। वह बोला: मैं नहीं मान सकता कि भगवान तुम्हारे साथ खाते हैं। मैं तो इतना व्रत रखता हूँ, पूजा करता हूँ, पर भगवान ने मुझे कभी दर्शन नहीं दिए।
                    राजा की बात सुनकर वह बोला: महाराज! यदि विश्वास न हो तो साथ चलकर देख लें। राजा एक पेड़ के पीछे छिपकर बैठ गया। उस व्यक्ति ने भोजन बनाया तथा भगवान को शाम तक पुकारता रहा, परंतु भगवान न आए। अंत में उसने कहा: हे भगवान! यदि आप नहीं आए तो मैं नदी में कूदकर प्राण त्याग दूंगा।
                    लेकिन भगवान नहीं आए, तब वह प्राण त्यागने के उद्देश्य से नदी की तरफ बढ़ा। प्राण त्यागने का उसका दृढ़ इरादा जान शीघ्र ही भगवान ने प्रकट होकर उसे रोक लिया और साथ बैठकर भोजन करने लगे। खा-पीकर वे उसे अपने विमान में बिठाकर अपने धाम ले गए। यह देख राजा ने सोचा कि व्रत-उपवास से तब तक कोई फायदा नहीं होता, जब तक मन शुद्ध न हो। इससे राजा को ज्ञान मिला। वह भी मन से व्रत-उपवास करने लगा और अंत में स्वर्ग को प्राप्त हुआ।
                    `,
        end:`॥ जय श्री हरि ॥`
    },
    {
        title:`उत्पन्ना एकादशी व्रत कथा`,
        description:`धर्मराज युधिष्ठिर कहने लगे कि हे भगवान! मैंने कार्तिक शुक्ल एकादशी अर्थात प्रबोधिनी एकादशी का सविस्तार वर्णन सुना। अब आप कृपा करके मुझे मार्गशीर्ष माह के कृष्ण पक्ष की एकादशी के विषय में भी बतलाइये। इस एकादशी का क्या नाम है तथा इसके व्रत का क्या विधान है? इसकी विधि क्या है? इसका व्रत करने से किस फल की प्राप्ति होती है? कृपया यह सब विधानपूर्वक कहिए।
                    भगवान श्रीकृष्ण बोले: मार्गशीर्ष माह के कृष्ण पक्ष मे आने वाली इस एकादशी को उत्पन्ना एकादशी कहा जाता है। यह व्रत शंखोद्धार तीर्थ में स्नान करके भगवान के दर्शन करने से जो फल प्राप्त होता है, उसके बराबर पुण्य मिलता है। व्रत करने वाले भक्त को चोर, पाखंडी, परस्त्रीगामी, निंदक, मिथ्याभाषी तथा किसी भी प्रकार के पापी से बात नहीं करनी चाहिए। इसका माहात्म्य मैं तुमसे कहता हूं, ध्यानपूर्वक सुनो।
                    उत्पन्ना एकादशी व्रत कथा!
                    युधिष्ठिर कहने लगे कि हे भगवन! आपने हजारों यज्ञ और लाख गौदान को भी एकादशी व्रत के बराबर नहीं बताया। सो यह तिथि सब तिथियों से उत्तम कैसे हुई, बताइए।
                    भगवन कहने लगे- हे युधिष्ठिर! सतयुग में मुर नाम का दैत्य उत्पन्न हुआ। वह बड़ा बलवान और भयानक था। उस प्रचंड दैत्य ने इंद्र, आदित्य, वसु, वायु, अग्नि आदि सभी देवताओं को पराजित करके भगा दिया। तब इंद्र सहित सभी देवताओं ने भयभीत होकर भगवान शिव से सारा वृत्तांत कहा और बोले हे कैलाशपति! मुर दैत्य से भयभीत होकर सब देवता मृत्यु लोक में फिर रहे हैं। तब भगवान शिव ने कहा- हे देवताओं! तीनों लोकों के स्वामी, भक्तों के दु:खों का नाश करने वाले भगवान विष्णु की शरण में जाओ।
                    वे ही तुम्हारे दु:खों को दूर कर सकते हैं। शिवजी के ऐसे वचन सुनकर सभी देवता क्षीरसागर में पहुँचे। वहाँ भगवान को शयन करते देख हाथ जोड़कर उनकी स्तुति करने लगे‍, कि हे देवताओं द्वारा स्तुति करने योग्य प्रभो! आपको बारम्बार नमस्कार है, देवताओं की रक्षा करने वाले मधुसूदन! आपको नमस्कार है। आप हमारी रक्षा करें। दैत्यों से भयभीत होकर हम सब आपकी शरण में आए हैं।
                    आप इस संसार के कर्ता, माता-पिता, उत्पत्ति और पालनकर्ता और संहार करने वाले हैं। सबको शांति प्रदान करने वाले हैं। आकाश और पाताल भी आप ही हैं। सबके पितामह ब्रह्मा, सूर्य, चंद्र, अग्नि, सामग्री, होम, आहुति, मंत्र, तंत्र, जप, यजमान, यज्ञ, कर्म, कर्ता, भोक्ता भी आप ही हैं। आप सर्वव्यापक हैं। आपके सिवा तीनों लोकों में चर तथा अचर कुछ भी नहीं है।
                    हे भगवन्! दैत्यों ने हमको जीतकर स्वर्ग से भ्रष्ट कर दिया है और हम सब देवता इधर-उधर भागे-भागे फिर रहे हैं, आप उन दैत्यों से हम सबकी रक्षा करें।
                    इंद्र के ऐसे वचन सुनकर भगवान विष्णु कहने लगे कि हे इंद्र! ऐसा मायावी दैत्य कौन है जिसने सब देवताअओं को जीत लिया है, उसका नाम क्या है, उसमें कितना बल है और किसके आश्रय में है तथा उसका स्थान कहाँ है? यह सब मुझसे कहो।
                    भगवान के ऐसे वचन सुनकर इंद्र बोले- भगवन! प्राचीन समय में एक नाड़ीजंघ नामक राक्षस था उसके महापराक्रमी और लोकविख्यात मुर नाम का एक पुत्र हुआ। उसकी चंद्रावती नाम की नगरी है। उसी ने सब देवताअओं को स्वर्ग से निकालकर वहाँ अपना अधिकार जमा लिया है। उसने इंद्र, अग्नि, वरुण, यम, वायु, ईश, चंद्रमा, नैऋत आदि सबके स्थान पर अधिकार कर लिया है।
                    सूर्य बनकर स्वयं ही प्रकाश करता है। स्वयं ही मेघ बन बैठा है और सबसे अजेय है। हे असुर निकंदन! उस दुष्ट को मारकर देवताओं को अजेय बनाइए।
                    यह वचन सुनकर भगवान ने कहा- हे देवताओं, मैं शीघ्र ही उसका संहार करूंगा। तुम चंद्रावती नगरी जाओ। इस प्रकार कहकर भगवान सहित सभी देवताओं ने चंद्रावती नगरी की ओर प्रस्थान किया। उस समय जब दैत्य मुर सेना सहित युद्ध भूमि में गरज रहा था। उसकी भयानक गर्जना सुनकर सभी देवता भय के मारे चारों दिशाओं में भागने लगे। जब स्वयं भगवान रणभूमि में आए तो दैत्य उन पर भी अस्त्र, शस्त्र, आयुध लेकर दौड़े।
                    भगवान ने उन्हें सर्प के समान अपने बाणों से बींध डाला। बहुत-से दैत्य मारे गए, केवल मुर बचा रहा। वह अविचल भाव से भगवान के साथ युद्ध करता रहा। भगवान जो-जो भी तीक्ष्ण बाण चलाते वह उसके लिए पुष्प सिद्ध होता। उसका शरीर छिन्न‍-भिन्न हो गया किंतु वह लगातार युद्ध करता रहा। दोनों के बीच मल्लयुद्ध भी हुआ।
                    10 हजार वर्ष तक उनका युद्ध चलता रहा किंतु मुर नहीं हारा। थककर भगवान बद्रिकाश्रम चले गए। वहां हेमवती नामक सुंदर गुफा थी, उसमें विश्राम करने के लिए भगवान उसके अंदर प्रवेश कर गए। यह गुफा 12 योजन लंबी थी और उसका एक ही द्वार था। विष्णु भगवान वहां योगनिद्रा की गोद में सो गए। मुर भी पीछे-पीछे आ गया और भगवान को सोया देखकर मारने को उद्यत हुआ तभी भगवान के शरीर से उज्ज्वल, कांतिमय रूप वाली देवी प्रकट हुई। देवी ने राक्षस मुर को ललकारा, युद्ध किया और उसे तत्काल मौत के घाट उतार दिया।
                    श्री हरि जब योगनिद्रा की गोद से उठे, तो सब बातों को जानकर उस देवी से कहा कि आपका जन्म एकादशी के दिन हुआ है, अत: आप उत्पन्ना एकादशी के नाम से पूजित होंगी। आपके भक्त वही होंगे, जो मेरे भक्त हैं।`,
        end:`॥ जय श्री हरि ॥`
    },
    {
        title:`मोक्षदा एकादशी व्रत कथा`,
        description:`धर्मराज युधिष्ठिर कहने लगे कि हे भगवान! मैंने मार्गशीर्ष कृष्ण एकादशी अर्थात उत्पन्ना एकादशी का सविस्तार वर्णन सुना। अब आप कृपा करके मुझे मार्गशीर्ष माह के शुक्ल पक्ष की एकादशी के विषय में भी बतलाइये। इस एकादशी का क्या नाम है तथा इसके व्रत का क्या विधान है? इसकी विधि क्या है? इसका व्रत करने से किस फल की प्राप्ति होती है? कृपया यह सब विधानपूर्वक कहिए।
                    भगवान श्रीकृष्ण बोले: मार्गशीर्ष माह के शुक्ल पक्ष मे आने वाली इस एकादशी को मोक्षदा एकादशी कहा जाता है। यह व्रत मोक्ष देने वाला तथा चिंतामणि के समान सब कामनाएँ पूर्ण करने वाला है। जिससे आप अपने पूर्वजो के दुखों को खत्म कर सकते हैं। इसका माहात्म्य मैं तुमसे कहता हूँ, ध्यानपूर्वक सुनो।
                    मोक्षदा एकादशी व्रत कथा!
                    गोकुल नाम के नगर में वैखानस नामक राजा राज्य करता था। उसके राज्य में चारों वेदों के ज्ञाता ब्राह्मण रहते थे। वह राजा अपनी प्रजा का पुत्रवत पालन करता था। एक बार रात्रि में राजा ने एक स्वप्न देखा कि उसके पिता नरक में हैं। उसे बड़ा आश्चर्य हुआ।
                    प्रात: वह विद्वान ब्राह्मणों के पास गया और अपना स्वप्न सुनाया। कहा- मैंने अपने पिता को नरक में कष्ट उठाते देखा है। उन्होंने मुझसे कहा कि हे पुत्र मैं नरक में पड़ा हूँ। यहाँ से तुम मुझे मुक्त कराओ। जबसे मैंने ये वचन सुने हैं तबसे मैं बहुत बेचैन हूँ। चित्त में बड़ी अशांति हो रही है। मुझे इस राज्य, धन, पुत्र, स्त्री, हाथी, घोड़े आदि में कुछ भी सुख प्रतीत नहीं होता। क्या करूँ?
                    राजा ने कहा- हे ब्राह्मण देवताओं! इस दु:ख के कारण मेरा सारा शरीर जल रहा है। अब आप कृपा करके कोई तप, दान, व्रत आदि ऐसा उपाय बताइए जिससे मेरे पिता को मुक्ति मिल जाए। उस पुत्र का जीवन व्यर्थ है जो अपने माता-पिता का उद्धार न कर सके। एक उत्तम पुत्र जो अपने माता-पिता तथा पूर्वजों का उद्धार करता है, वह हजार मूर्ख पुत्रों से अच्छा है। जैसे एक चंद्रमा सारे जगत में प्रकाश कर देता है, परंतु हजारों तारे नहीं कर सकते। ब्राह्मणों ने कहा- हे राजन! यहाँ पास ही भूत, भविष्य, वर्तमान के ज्ञाता पर्वत ऋषि का आश्रम है। आपकी समस्या का हल वे जरूर करेंगे।
                    ऐसा सुनकर राजा मुनि के आश्रम पर गया। उस आश्रम में अनेक शांत चित्त योगी और मुनि तपस्या कर रहे थे। उसी जगह पर्वत मुनि बैठे थे। राजा ने मुनि को साष्टांग दंडवत किया। मुनि ने राजा से सांगोपांग कुशल पूछी। राजा ने कहा कि महाराज आपकी कृपा से मेरे राज्य में सब कुशल हैं, लेकिन अकस्मात मेरे चित्त में अत्यंत अशांति होने लगी है। ऐसा सुनकर पर्वत मुनि ने आँखें बंद की और भूत विचारने लगे। फिर बोले हे राजन! मैंने योग के बल से तुम्हारे पिता के कुकर्मों को जान लिया है। उन्होंने पूर्व जन्म में कामातुर होकर एक पत्नी को रति दी किंतु सौत के कहने पर दूसरे पत्नी को ऋतुदान माँगने पर भी नहीं दिया। उसी पापकर्म के कारण तुम्हारे पिता को नर्क में जाना पड़ा।
                    तब राजा ने कहा ‍इसका कोई उपाय बताइए। मुनि बोले: हे राजन! आप मार्गशीर्ष एकादशी का उपवास करें और उस उपवास के पुण्य को अपने पिता को संकल्प कर दें। इसके प्रभाव से आपके पिता की अवश्य नर्क से मुक्ति होगी। मुनि के ये वचन सुनकर राजा महल में आया और मुनि के कहने अनुसार कुटुम्ब सहित मोक्षदा एकादशी का व्रत किया। इसके उपवास का पुण्य उसने पिता को अर्पण कर दिया। इसके प्रभाव से उसके पिता को मुक्ति मिल गई और स्वर्ग में जाते हुए वे पुत्र से कहने लगे- हे पुत्र तेरा कल्याण हो। यह कहकर स्वर्ग चले गए।
                    मार्गशीर्ष मास की शुक्ल पक्ष की मोक्षदा एकादशी का जो व्रत करते हैं, उनके समस्त पाप नष्ट हो जाते हैं। इस व्रत से बढ़कर मोक्ष देने वाला और कोई व्रत नहीं है। इस दिन गीता जयंती मनाई जाती हैं साथ ही यह धनुर्मास की एकादशी कहलाती हैं, अतः इस एकादशी का महत्व कई गुना और भी बढ़ जाता हैं। इस दिन से गीता-पाठ का अनुष्ठान प्रारंभ करें तथा प्रतिदिन थोडी देर गीता अवश्य पढें।
                    `,
        end:`॥ जय श्री हरि ॥`
    },
    {
        title:`सफला एकादशी व्रत कथा`,
        description:`धर्मराज युधिष्ठिर कहने लगे कि हे जनार्दन! मैंने मार्गशीर्ष शुक्ल एकादशी अर्थात मोक्षदा एकादशी का सविस्तार वर्णन सुना। अब आप कृपा करके मुझे पौष माह के कृष्ण पक्ष की एकादशी के विषय में भी बतलाइये। इस एकादशी का क्या नाम है तथा इसके व्रत का क्या विधान है? इसकी विधि क्या है? इसका व्रत करने से किस फल की प्राप्ति होती है? कृपया यह सब विधानपूर्वक कहिए।
                    भगवान श्रीकृष्ण बोले: पौष माह के कृष्ण पक्ष मे आने वाली इस एकादशी को सफला एकादशी कहा जाता है। इस एकादशी के देवता श्रीनारायण हैं। विधिपूर्वक इस व्रत को करना चाहिए। जिस प्रकार नागों में शेषनाग, पक्षियों में गरुड़, सब ग्रहों में चंद्रमा, यज्ञों में अश्वमेध और देवताओं में भगवान विष्णु श्रेष्ठ हैं, उसी तरह सब व्रतों में एकादशी का व्रत श्रेष्ठ है। जो मनुष्य सदैव एकादशी का व्रत करते हैं, वे मुझे परम प्रिय हैं। इसका माहात्म्य मैं तुमसे कहता हूँ, ध्यानपूर्वक सुनो।
                    भक्तवत्सल भगवान श्रीकृष्ण कहने लगे कि धर्मराज, मैं तुम्हारे स्नेह के कारण तुमसे कहता हूँ कि एकादशी व्रत के अतिरिक्त मैं अधिक से अधिक दक्षिणा पाने वाले यज्ञ से भी प्रसन्न नहीं होता हूँ। अत: इसे अत्यंत भक्ति और श्रद्धा से युक्त होकर करें। हे राजन! द्वादशीयुक्त पौष कृष्ण एकादशी का माहात्म्य तुम एकाग्रचित्त होकर सुनो।
                    सफला एकादशी व्रत कथा!
                    चम्पावती नगरी में एक महिष्मान नाम का राजा राज्य करता था। उसके चार पुत्र थे। उन सबमें लुम्पक नाम वाला बड़ा राजपुत्र महापापी था। वह पापी सदा परस्त्री और वेश्यागमन तथा दूसरे बुरे कामों में अपने पिता का धन नष्ट किया करता था। सदैव ही देवता, बाह्मण, वैष्णवों की निंदा किया करता था। जब राजा को अपने बड़े पुत्र के ऐसे कुकर्मों का पता चला तो उन्होंने उसे अपने राज्य से निकाल दिया। तब वह विचारने लगा कि कहाँ जाऊँ? क्या करूँ?
                    अंत में उसने चोरी करने का निश्चय किया। दिन में वह वन में रहता और रात्रि को अपने पिता की नगरी में चोरी करता तथा प्रजा को तंग करने और उन्हें मारने का कुकर्म करता। कुछ समय पश्चात सारी नगरी भयभीत हो गई। वह वन में रहकर पशु आदि को मारकर खाने लगा। नागरिक और राज्य के कर्मचारी उसे पकड़ लेते किंतु राजा के भय से छोड़ देते।
                    वन में एक अतिप्राचीन विशाल पीपल का वृक्ष था। लोग उसकी भगवान के समान पूजा करते थे। उसी वृक्ष के नीचे वह महापापी लुम्पक रहा करता था। इस वन को लोग देवताओं की क्रीड़ास्थली मानते थे। कुछ समय पश्चात पौष कृष्ण पक्ष की दशमी के दिन वह वस्त्रहीन होने के कारण शीत के चलते सारी रात्रि सो नहीं सका। उसके हाथ-पैर अकड़ गए।
                    सूर्योदय होते-होते वह मूर्छित हो गया। दूसरे दिन एकादशी को मध्याह्न के समय सूर्य की गर्मी पाकर उसकी मूर्छा दूर हुई। गिरता-पड़ता वह भोजन की तलाश में निकला। पशुओं को मारने में वह समर्थ नहीं था अत: पेड़ों के नीचे गिर हुए फल उठाकर वापस उसी पीपल वृक्ष के नीचे आ गया। उस समय तक भगवान सूर्य अस्त हो चुके थे।
                    वृक्ष के नीचे फल रखकर कहने लगा- हे भगवन! अब आपके ही अर्पण है ये फल। आप ही तृप्त हो जाइए। उस रात्रि को दु:ख के कारण रात्रि को भी नींद नहीं आई।
                    उसके इस उपवास और जागरण से भगवान अत्यंत प्रसन्न हो गए और उसके सारे पाप नष्ट हो गए। दूसरे दिन प्रात: एक ‍अतिसुंदर घोड़ा अनेक सुंदर वस्तुअओं से सजा हुआ उसके सामने आकर खड़ा हो गया।
                    उसी समय आकाशवाणी हुई कि हे राजपुत्र! श्रीनारायण की कृपा से तेरे पाप नष्ट हो गए हैं। अब तू अपने पिता के पास जाकर राज्य प्राप्त कर। ऐसी वाणी सुनकर वह अत्यंत प्रसन्न हुआ और दिव्य वस्त्र धारण करके भगवान आपकी जय हो! कहकर अपने पिता के पास गया। उसके पिता ने प्रसन्न होकर उसे समस्त राज्य का भार सौंप दिया और स्वयं वन के रास्ते चल दिए।
                    अब लुम्पक शास्त्रानुसार राज्य करने लगा। उसके स्त्री, पुत्र आदि सारा कुटुम्ब भगवान नारायण का परम भक्त हो गया। वृद्ध होने पर वह भी अपने पुत्र को राज्य का भार सौंपकर वन में तपस्या करने चला गया और अंत समय में वैकुंठ को प्राप्त हुआ।
                    अत: जो मनुष्य इस परम पवित्र सफला एकादशी का व्रत करता है उसे अंत में मुक्ति मिलती है। जो नहीं करते वे पूँछ और सींगों से रहित पशुओं के समान हैं। इस सफला एकादशी के माहात्म्य को पढ़ने से अथवा श्रवण करने से मनुष्य को अश्वमेध यज्ञ का फल मिलता है।
                    `,
        end:`॥ जय श्री हरि ॥`
    },
    {
        title:`पौष पुत्रदा एकादशी व्रत कथा`,
        description:`धर्मराज युधिष्ठिर कहने लगे कि हे भगवान! आपने पौष कृष्ण एकादशी अर्थात सफला एकादशी का माहात्म्य बताकर बड़ी कृपा की। अब आप कृपा करके मुझे पौष माह के शुक्ल पक्ष की एकादशी के विषय में भी बतलाइये। इस एकादशी का क्या नाम है तथा इसके व्रत का क्या विधान है? इसकी विधि क्या है? इसका व्रत करने से किस फल की प्राप्ति होती है? और उसमें कौन-से देवता का पूजन किया जाता है? कृपया यह सब विधानपूर्वक कहिए।
                    भगवान श्रीकृष्ण बोले: पौष माह के शुक्ल पक्ष मे आने वाली इस एकादशी को पौष पुत्रदा एकादशी कहा जाता है। इसमें भी नारायण भगवान की पूजा की जाती है। विधिपूर्वक इस व्रत को करना चाहिए। इस चर और अचर संसार में पुत्रदा एकादशी के व्रत के समान दूसरा कोई व्रत नहीं है। इसके पुण्य से मनुष्य तपस्वी, विद्वान और लक्ष्मीवान होता है। इसकी मैं एक कथा कहता हूँ सो तुम ध्यानपूर्वक सुनो।
                    पौष पुत्रदा एकादशी व्रत कथा!
                    भद्रावती नामक नगरी में सुकेतुमान नाम का एक राजा राज्य करता था। उसके कोई पुत्र नहीं था। उसकी स्त्री का नाम शैव्या था। वह निपुत्री होने के कारण सदैव चिंतित रहा करती थी। राजा के पितर भी रो-रोकर पिंड लिया करते थे और सोचा करते थे कि इसके बाद हमको कौन पिंड देगा। राजा को भाई, बाँधव, धन, हाथी, घोड़े, राज्य और मंत्री इन सबमें से किसी से भी संतोष नहीं होता था।
                    वह सदैव यही विचार करता था कि मेरे मरने के बाद मुझको कौन पिंडदान करेगा। बिना पुत्र के पितरों और देवताओं का ऋण मैं कैसे चुका सकूँगा। जिस घर में पुत्र न हो उस घर में सदैव अँधेरा ही रहता है। इसलिए पुत्र उत्पत्ति के लिए प्रयत्न करना चाहिए।
                    जिस मनुष्य ने पुत्र का मुख देखा है, वह धन्य है। उसको इस लोक में यश और परलोक में शांति मिलती है अर्थात उनके दोनों लोक सुधर जाते हैं। पूर्व जन्म के कर्म से ही इस जन्म में पुत्र, धन आदि प्राप्त होते हैं। राजा इसी प्रकार रात-दिन चिंता में लगा रहता था।
                    एक समय तो राजा ने अपने शरीर को त्याग देने का निश्चय किया परंतु आत्मघात को महान पाप समझकर उसने ऐसा नहीं किया। एक दिन राजा ऐसा ही विचार करता हुआ अपने घोड़े पर चढ़कर वन को चल दिया तथा पक्षियों और वृक्षों को देखने लगा। उसने देखा कि वन में मृग, व्याघ्र, सूअर, सिंह, बंदर, सर्प आदि सब भ्रमण कर रहे हैं। हाथी अपने बच्चों और हथिनियों के बीच घूम रहा है।
                    इस वन में कहीं तो गीदड़ अपने कर्कश स्वर में बोल रहे हैं, कहीं उल्लू ध्वनि कर रहे हैं। वन के दृश्यों को देखकर राजा सोच-विचार में लग गया। इसी प्रकार आधा दिन बीत गया। वह सोचने लगा कि मैंने कई यज्ञ किए, ब्राह्मणों को स्वादिष्ट भोजन से तृप्त किया फिर भी मुझको दु:ख प्राप्त हुआ, क्यों?
                    राजा प्यास के मारे अत्यंत दु:खी हो गया और पानी की तलाश में इधर-उधर फिरने लगा। थोड़ी दूरी पर राजा ने एक सरोवर देखा। उस सरोवर में कमल खिले थे तथा सारस, हंस, मगरमच्छ आदि विहार कर रहे थे। उस सरोवर के चारों तरफ मुनियों के आश्रम बने हुए थे। उसी समय राजा के दाहिने अंग फड़कने लगे। राजा शुभ शकुन समझकर घोड़े से उतरकर मुनियों को दंडवत प्रणाम करके बैठ गया।
                    राजा को देखकर मुनियों ने कहा - हे राजन! हम तुमसे अत्यंत प्रसन्न हैं। तुम्हारी क्या इच्छा है, सो कहो।
                    राजा ने पूछा - महाराज आप कौन हैं, और किसलिए यहाँ आए हैं। कृपा करके बताइए।
                    मुनि कहने लगे कि हे राजन! आज संतान देने वाली पुत्रदा एकादशी है, हम लोग विश्वदेव हैं और इस सरोवर में स्नान करने के लिए आए हैं।
                    यह सुनकर राजा कहने लगा कि महाराज मेरे भी कोई संतान नहीं है, यदि आप मुझ पर प्रसन्न हैं तो एक पुत्र का वरदान दीजिए।
                    मुनि बोले - हे राजन! आज पुत्रदा एकादशी है। आप अवश्य ही इसका व्रत करें, भगवान की कृपा से अवश्य ही आपके घर में पुत्र होगा।
                    मुनि के वचनों को सुनकर राजा ने उसी दिन एकादशी का ‍व्रत किया और द्वादशी को उसका पारण किया। इसके पश्चात मुनियों को प्रणाम करके महल में वापस आ गया। कुछ समय बीतने के बाद रानी ने गर्भ धारण किया और नौ महीने के पश्चात उनके एक पुत्र हुआ। वह राजकुमार अत्यंत शूरवीर, यशस्वी और प्रजापालक हुआ।
                    श्रीकृष्ण बोले: हे राजन! पुत्र की प्राप्ति के लिए पुत्रदा एकादशी का व्रत करना चाहिए। जो मनुष्य इस माहात्म्य को पढ़ता या सुनता है उसे अंत में स्वर्ग की प्राप्ति होती है।
                    `,
        end:`॥ जय श्री हरि ॥`
    },
    {
        title:`षटतिला एकादशी व्रत कथा`,
        description:`एक समय दालभ्य ऋषि ने पुलस्त्य ऋषि से पूछा कि हे महाराज, पृथ्वी लोक में मनुष्य ब्रह्महत्यादि महान पाप करते हैं, पराए धन की चोरी तथा दूसरे की उन्नति देखकर ईर्ष्या करते हैं। साथ ही अनेक प्रकार के व्यसनों में फँसे रहते हैं, फिर भी उनको नर्क प्राप्त नहीं होता, इसका क्या कारण है?
                    वे न जाने कौन-सा दान-पुण्य करते हैं जिससे उनके पाप नष्ट हो जाते हैं। यह सब कृपापूर्वक आप कहिए। पुलस्त्य मुनि कहने लगे कि हे महाभाग! आपने मुझसे अत्यंत गंभीर प्रश्न पूछा है। इससे संसार के जीवों का अत्यंत भला होगा। इस भेद को इंद्र आदि भी नहीं जानते परंतु मैं आपको यह गुप्त तत्व अवश्य बताऊँगा।
                    उन्होंने कहा कि माघ मास लगते ही मनुष्य को स्नान आदि करके शुद्ध रहना चाहिए। इंद्रियों को वश में कर काम, क्रोध, लोभ, मोह, अहंकार, ईर्ष्या तथा द्वेष आदि का त्याग कर भगवान का स्मरण करना चाहिए। पुष्य नक्षत्र में गोबर, कपास, तिल मिलाकर उनके कंडे बनाना चाहिए। उन कंडों से 108 बार हवन करना चाहिए।
                    उस दिन मूल नक्षत्र हो और एकादशी तिथि हो तो अच्छे पुण्य देने वाले नियमों को ग्रहण करें। स्नानादि नित्य क्रिया से निवृत्त होकर सब देवताओं के देव श्री भगवान का पूजन करें और एकादशी व्रत धारण करें। रात्रि को जागरण करना चाहिए।
                    उसके दूसरे दिन धूप-दीप, नैवेद्य आदि से भगवान का पूजन करके खिचड़ी का भोग लगाएँ। तत्पश्चात पेठा, नारियल, सीताफल या सुपारी का अर्घ्य देकर स्तुति करनी चाहिए:
                    हे भगवान! आप दीनों को शरण देने वाले हैं, इस संसार सागर में फँसे हुओं का उद्धार करने वाले हैं। हे पुंडरीकाक्ष! हे विश्वभावन! हे सुब्रह्मण्य! हे पूर्वज! हे जगत्पते! आप लक्ष्मीजी सहित इस तुच्छ अर्घ्य को ग्रहण करें।
                    इसके पश्चात जल से भरा कुंभ (घड़ा) ब्राह्मण को दान करें तथा ब्राह्मण को श्यामा गौ और तिल पात्र देना भी उत्तम है। तिल स्नान और भोजन दोनों ही श्रेष्ठ हैं। इस प्रकार जो मनुष्य जितने तिलों का दान करता है, उतने ही हजार वर्ष स्वर्ग में वास करता है। इतना कहकर पुलस्त्य ऋषि कहने लगे कि अब मैं तुमसे इस एकादशी की कथा कहता हूँ।
                    षटतिला एकादशी व्रत कथा!
                    एक समय नारदजी ने भगवान श्रीविष्णु से यही प्रश्न किया था और भगवान ने जो षटतिला एकादशी का माहात्म्य नारदजी से कहा: सो मैं तुमसे कहता हूँ। भगवान ने नारदजी से कहा कि हे नारद! मैं तुमसे सत्य घटना कहता हूँ। ध्यानपूर्वक सुनो।
                    प्राचीनकाल में मृत्युलोक में एक ब्राह्मणी रहती थी। वह सदैव व्रत किया करती थी। एक समय वह एक मास तक व्रत करती रही। इससे उसका शरीर अत्यंत दुर्बल हो गया। यद्यपि वह अत्यंत बुद्धिमान थी तथापि उसने कभी देवताअओं या ब्राह्मणों के निमित्त अन्न या धन का दान नहीं किया था। इससे मैंने सोचा कि ब्राह्मणी ने व्रत आदि से अपना शरीर शुद्ध कर लिया है, अब इसे विष्णुलोक तो मिल ही जाएगा परंतु इसने कभी अन्न का दान नहीं किया, इससे इसकी तृप्ति होना कठिन है।
                    भगवान ने आगे कहा: ऐसा सोचकर मैं भिखारी के वेश में मृत्युलोक में उस ब्राह्मणी के पास गया और उससे भिक्षा माँगी।
                    वह ब्राह्मणी बोली: महाराज किसलिए आए हो?
                    मैंने कहा: मुझे भिक्षा चाहिए।
                    इस पर उसने एक मिट्टी का ढेला मेरे भिक्षापात्र में डाल दिया। मैं उसे लेकर स्वर्ग में लौट आया। कुछ समय बाद ब्राह्मणी भी शरीर त्याग कर स्वर्ग में आ गई। उस ब्राह्मणी को मिट्टी का दान करने से स्वर्ग में सुंदर महल मिला, परंतु उसने अपने घर को अन्नादि सब सामग्रियों से शून्य पाया।
                    घबराकर वह मेरे पास आई और कहने लगी कि भगवन् मैंने अनेक व्रत आदि से आपकी पूजा की परंतु फिर भी मेरा घर अन्नादि सब वस्तुओं से शून्य है। इसका क्या कारण है?
                    इस पर मैंने कहा: पहले तुम अपने घर जाओ। देवस्त्रियाँ आएँगी तुम्हें देखने के लिए। पहले उनसे षटतिला एकादशी का पुण्य और विधि सुन लो, तब द्वार खोलना। मेरे ऐसे वचन सुनकर वह अपने घर गई। जब देवस्त्रियाँ आईं और द्वार खोलने को कहा तो ब्राह्मणी बोली: आप मुझे देखने आई हैं तो षटतिला एकादशी का माहात्म्य मुझसे कहो।
                    उनमें से एक देवस्त्री कहने लगी कि मैं कहती हूँ। जब ब्राह्मणी ने षटतिला एकादशी का माहात्म्य सुना तब द्वार खोल दिया। देवांगनाओं ने उसको देखा कि न तो वह गांधर्वी है और न आसुरी है वरन पहले जैसी मानुषी है। उस ब्राह्मणी ने उनके कथनानुसार षटतिला एकादशी का व्रत किया। इसके प्रभाव से वह सुंदर और रूपवती हो गई तथा उसका घर अन्नादि समस्त सामग्रियों से युक्त हो गया।
                    अत: मनुष्यों को मूर्खता त्यागकर षटतिला एकादशी का व्रत और लोभ न करके तिलादि का दान करना चाहिए। इससे दुर्भाग्य, दरिद्रता तथा अनेक प्रकार के कष्ट दूर होकर मोक्ष की प्राप्ति होती है।
                    इस प्रकार षटतिला एकादशी व्रत कथा समाप्त हुई।`,
        end:`॥ जय श्री हरि ॥`
    },
    {
        title:`जया एकादशी व्रत कथा`,
        description:`धर्मराज युधिष्ठिर बोले: हे भगवन्! आपने माघ के कृष्ण पक्ष की षटतिला एकादशी का अत्यन्त सुंदर वर्णन करते हुए सुनाया। आप स्वदेज, अंडज, उद्भिज और जरायुज चारों प्रकार के जीवों के उत्पन्न, पालन तथा नाश करने वाले हैं। अब आप कृपा करके माघ शुक्ल एकादशी के विषय में भी बतलाइये। इस एकादशी का क्या नाम है तथा इसके व्रत का क्या विधान है? इसकी विधि क्या है? इसका व्रत करने से किस फल की प्राप्ति होती है? और इसमें कौन से देव का पूजन किया जाता है? कृपया यह सब विधानपूर्वक कहिए।
                    श्रीकृष्ण कहने लगे कि हे राजन्! माघ शुक्ल एकादशी के दिन आने वाली एकादशी को जया एकादशी के नाम से जाना जाता है। इसका व्रत करने से मनुष्य ब्रह्म हत्यादि पापों से छूट कर मोक्ष को प्राप्त होता है तथा इसके प्रभाव से भूत, पिशाच आदि योनियों से मुक्त हो जाता है। इस व्रत को विधिपूर्वक करना चाहिए। अब मैं तुमसे पद्मपुराण में वर्णित इसकी महिमा की एक कथा सुनाता हूँ।
                    जया एकादशी व्रत कथा!
                    देवराज इंद्र स्वर्ग में राज करते थे और अन्य सब देवगण सुखपूर्वक स्वर्ग में रहते थे। एक समय इंद्र अपनी इच्छानुसार नंदन वन में अप्सराओं के साथ विहार कर रहे थे और गंधर्व गान कर रहे थे। उन गंधर्वों में प्रसिद्ध पुष्पदंत तथा उसकी कन्या पुष्पवती और चित्रसेन तथा उसकी स्त्री मालिनी भी उपस्थित थे। साथ ही मालिनी का पुत्र पुष्पवान और उसका पुत्र माल्यवान भी उपस्थित थे।
                    पुष्पवती गंधर्व कन्या माल्यवान को देखकर उस पर मोहित हो गई और माल्यवान पर काम-बाण चलाने लगी। उसने अपने रूप लावण्य और हावभाव से माल्यवान को वश में कर लिया। हे राजन्! वह पुष्पवती अत्यन्त सुंदर थी। अब वे इंद्र को प्रसन्न करने के लिए गान करने लगे परंतु परस्पर मोहित हो जाने के कारण उनका चित्त भ्रमित हो गया था।
                    इनके ठीक प्रकार न गाने तथा स्वर ताल ठीक नहीं होने से इंद्र इनके प्रेम को समझ गया और उन्होंने इसमें अपना अपमान समझ कर उनको शाप दे दिया। इंद्र ने कहा हे मूर्खों! तुमने मेरी आज्ञा का उल्लंघन किया है, इसलिए तुम्हारा धिक्कार है। अब तुम दोनों स्त्री-पुरुष के रूप में मृत्यु लोक में जाकर पिशाच रूप धारण करो और अपने कर्म का फल भोगो।
                    इंद्र का ऐसा शाप सुनकर वे अत्यन्त दु:खी हुए और हिमालय पर्वत पर दु:खपूर्वक अपना जीवन व्यतीत करने लगे। उन्हें गंध, रस तथा स्पर्श आदि का कुछ भी ज्ञान नहीं था। वहाँ उनको महान दु:ख मिल रहे थे। उन्हें एक क्षण के लिए भी निद्रा नहीं आती थी।
                    उस जगह अत्यन्त शीत था, इससे उनके रोंगटे खड़े रहते और मारे शीत के दाँत बजते रहते। एक दिन पिशाच ने अपनी स्त्री से कहा कि पिछले जन्म में हमने ऐसे कौन-से पाप किए थे, जिससे हमको यह दु:खदायी पिशाच योनि प्राप्त हुई। इस पिशाच योनि से तो नर्क के दु:ख सहना ही उत्तम है। अत: हमें अब किसी प्रकार का पाप नहीं करना चाहिए। इस प्रकार विचार करते हुए वे अपने दिन व्यतीत कर रहे थे।
                    दैव्ययोग से तभी माघ मास में शुक्ल पक्ष की जया नामक एकादशी आई। उस दिन उन्होंने कुछ भी भोजन नहीं किया, एवं अच्छे-अच्छे कार्य किए। केवल फल-फूल खाकर ही दिन व्यतीत किया और सायंकाल के समय दुखी अवस्था मे पीपल के वृक्ष के नीचे बैठ गए। उस समय सूर्य भगवान अस्त हो रहे थे। उस रात को अत्यन्त ठंड थी, इस कारण वे दोनों शीत के मारे अति दुखित होकर मृतक के समान आपस में चिपटे हुए पड़े रहे। उस रात्रि को उनको निद्रा भी नहीं आई।
                    जया एकादशी के उपवास और रात्रि के जागरण से दूसरे दिन प्रभात होते ही उनकी पिशाच योनि से उन्हें छुटकारा मिला। अत्यन्त सुंदर गंधर्व और अप्सरा की देह धारण कर सुंदर वस्त्राभूषणों से अलंकृत होकर उन्होंने स्वर्गलोक को प्रस्थान किया। उस समय आकाश में देवता उनकी स्तुति करते हुए पुष्पवर्षा करने लगे। स्वर्गलोक में जाकर दोनों ने देवराज इंद्र को प्रणाम किया। देवराज इंद्र इनको पहले रूप में देखकर अत्यन्त आश्चर्यचकित हुए एवं पूछने लगे कि तुमने अपनी यह पिशाच योनि से किस प्रकार छुटकारा पाया, अतः हमें यह सब बतलाइए।
                    माल्यवान बोले हे देवेन्द्र! भगवान विष्णु की कृपा और जया एकादशी के व्रत के प्रभाव से ही हमारी पिशाच देह छूटी है। तब इंद्र बोले कि हे माल्यवान! भगवान की कृपा और एकादशी का व्रत करने से न केवल तुम्हारी पिशाच योनि छूट गई, वरन् हम लोगों के भी वंदनीय हो गए क्योंकि विष्णु और शिव के भक्त हम लोगों के वंदनीय हैं, अत: आप धन्य है। अब आप पुष्पवती के साथ जाकर विहार करो।
                    श्रीकृष्ण कहने लगे कि हे राजन् युधिष्ठिर! इस जया एकादशी के व्रत से, बुरी से बुरी योनि से भी मुक्ति मिल जाती है। जिस मनुष्य ने जया एकादशी का व्रत किया है, उसने मानो सब यज्ञ, जप, दान आदि कर लिए। जो मनुष्य जया एकादशी का व्रत करते हैं वे अवश्य ही हजार वर्ष तक स्वर्ग में वास करते हैं।
                    `,
        end:`॥ जय श्री हरि ॥`
    },
    {
        title:`विजया एकादशी व्रत कथा`,
        description:`धर्मराज युधिष्‍ठिर बोले: हे जनार्दन! आपने माघ के शुक्ल पक्ष की जया एकादशी का अत्यन्त सुंदर वर्णन करते हुए सुनाया। अब आप फाल्गुन मास के कृष्ण पक्ष की एकादशी का क्या नाम है? तथा उसकी विधि क्या है? कृपा करके आप मुझे बताइए।
                    श्री भगवान बोले: हे राजन्, फाल्गुन मास के कृष्ण पक्ष की एकादशी का नाम विजया एकादशी है। इसके व्रत के प्रभाव से मनुष्‍य को विजय प्राप्त‍ होती है। यह सब व्रतों से उत्तम व्रत है। इस विजया एकादशी के महात्म्य के श्रवण व पठन से समस्त पाप नाश को प्राप्त हो जाते हैं। भयंकर शत्रुओं से जब आप घिरे हों और पराजय सामने खड़ी हो उस विकट स्थिति में विजया नामक एकादशी आपको विजय दिलाने की क्षमता रखती है।
                    विजया एकादशी व्रत कथा!
                    एक समय देवर्षि नारदजी ने जगत् पिता ब्रह्माजी से कहा महाराज! आप मुझसे फाल्गुन मास के कृष्ण पक्ष की एकादशी विधान का कहिए। ब्रह्माजी कहने लगे कि हे नारद! विजया एकादशी का व्रत पुराने तथा नए पापों को नाश करने वाला है। इस विजया एकादशी की विधि मैंने आज तक किसी से भी नहीं कही। यह समस्त मनुष्यों को विजय प्रदान करती है।
                    त्रेता युग में मर्यादा पुरुषोत्तम श्री रामचंद्रजी जो विष्णु के अंशावतार थे, जब उनको चौदह वर्ष का वनवास हो गया, तब वे श्री लक्ष्मण तथा सीताजी ‍सहित पंचवटी में निवास करने लगे। वहाँ पर दुष्ट रावण ने जब सीताजी का हरण ‍किया तब इस समाचार से श्री रामचंद्रजी तथा लक्ष्मण अत्यंत व्याकुल हुए और सीताजी की खोज में चल दिए।
                    घूमते-घूमते जब वे मरणासन्न जटायु के पास पहुँचे तो जटायु उन्हें सीताजी का वृत्तांत सुनाकर स्वर्गलोक चला गया। कुछ आगे जाकर उनकी सुग्रीव से मित्रता हुई और बाली का वध किया। हनुमानजी ने लंका में जाकर सीताजी का पता लगाया और उनसे श्री रामचंद्रजी और सुग्रीव की‍ मित्रता का वर्णन किया। वहाँ से लौटकर हनुमानजी ने भगवान राम के पास आकर सब समाचार कहे।
                    श्री रामचंद्रजी ने वानर सेना सहित सुग्रीव की सम्पत्ति से लंका को प्रस्थान किया। जब श्री रामचंद्रजी समुद्र के किनारे पहुँचे तब उन्होंने मगरमच्छ आदि से युक्त उस अगाध समुद्र को देखकर लक्ष्मणजी से कहा कि इस समुद्र को हम किस प्रकार से पार करेंगे।
                    श्री लक्ष्मण ने कहा हे पुराण पुरुषोत्तम, आप आदिपुरुष हैं, सब कुछ जानते हैं। यहाँ से आधा योजन दूर पर कुमारी द्वीप में वकदाल्भ्य नाम के मुनि रहते हैं। उन्होंने अनेक ब्रह्मा देखे हैं, आप उनके पास जाकर इसका उपाय पूछिए। लक्ष्मणजी के इस प्रकार के वचन सुनकर श्री रामचंद्रजी वकदाल्भ्य ऋषि के पास गए और उनको प्रमाण करके बैठ गए।
                    मुनि ने भी उनको मनुष्य रूप धारण किए हुए पुराण पुरुषोत्तम समझकर उनसे पूछा कि हे राम! आपका आना कैसे हुआ? रामचंद्रजी कहने लगे कि हे ऋषे! मैं अपनी सेना ‍सहित यहाँ आया हूँ और राक्षसों को जीतने के लिए लंका जा रहा हूँ। आप कृपा करके समुद्र पार करने का कोई उपाय बतलाइए। मैं इसी कारण आपके पास आया हूँ।
                    वकदाल्भ्य ऋषि बोले कि हे राम! फाल्गुन मास के कृष्ण पक्ष की एकादशी का उत्तम व्रत करने से निश्चय ही आपकी विजय होगी, साथ ही आप समुद्र भी अवश्य पार कर लेंगे। इस व्रत की विधि यह है कि दशमी के दिन स्वर्ण, चाँदी, ताँबा या मिट्‍टी का एक घड़ा बनाएँ। उस घड़े को जल से भरकर तथा पाँच पल्लव रख वेदिका पर स्थापित करें। उस घड़े के नीचे सतनजा और ऊपर जौ रखें। उस पर श्रीनारायण भगवान की स्वर्ण की मूर्ति स्थापित करें। एका‍दशी के दिन स्नानादि से निवृत्त होकर धूप, दीप, नैवेद्य, नारियल आदि से भगवान की पूजा करें।
                    तत्पश्चात घड़े के सामने बैठकर दिन व्यतीत करें ‍और रात्रि को भी उसी प्रकार बैठे रहकर जागरण करें। द्वादशी के दिन नित्य नियम से निवृत्त होकर उस घड़े को ब्राह्मण को दे दें। हे राम! यदि तुम भी इस व्रत को सेनापतियों सहित करोगे तो तुम्हारी विजय अवश्य होगी। श्री रामचंद्रजी ने ऋषि के कथनानुसार इस व्रत को किया और इसके प्रभाव से दैत्यों पर विजय पाई।
                    अत: हे राजन्! जो कोई मनुष्य विधिपूर्वक इस व्रत को करेगा, दोनों लोकों में उसकी अवश्य विजय होगी। श्री ब्रह्माजी ने नारदजी से कहा था कि हे पुत्र! जो कोई इस व्रत के महात्म्य को पढ़ता या सुनता है, उसको वाजपेय यज्ञ का फल प्राप्त होता है।
                    `,
        end:`॥ जय श्री हरि ॥`
    },
    {
        title:`आमलकी एकादशी व्रत कथा`,
        description:`धर्मराज युधिष्‍ठिर बोले: हे जनार्दन! आपने फाल्गुन के कृष्ण पक्ष की विजया एकादशी का सुंदर वर्णन करते हुए सुनाया। अब आप फाल्गुन मास के शुक्ल पक्ष की एकादशी का क्या नाम है? तथा उसकी विधि क्या है? कृपा करके आप मुझे बताइए।
                    श्री भगवान बोले: हे राजन्, फाल्गुन मास के शुक्ल पक्ष की एकादशी का नाम आमलकी एकादशी है। इस व्रत के करने से समस्त पाप नष्ट हो जाते हैं, तथा इसके प्रभाव से एक हजार गौ दान का फल प्राप्त‍ होता है। इस व्रत के करने से समस्त पाप नष्ट हो जाते हैं। हे राजन्, अब मैं आपको महर्षि वशिष्ठ जी द्वारा राजा मांधाता को सुनाई पौराणिक कथा के बारे मैं बताता हूँ, आप इसे ध्यानपूर्वक सुनें।
                    आमलकी एकादशी व्रत कथा!
                    राजा मांधाता बोले कि हे वशिष्ठजी! यदि आप मुझ पर कृपा करें तो किसी ऐसे व्रत की कथा कहिए, जिससे मेरा कल्याण हो। महर्षि वशिष्ठ बोले कि हे राजन्, सब व्रतों से उत्तम और अंत में मोक्ष देने वाले आमलकी एकादशी के व्रत का मैं वर्णन करता हूं। यह एकादशी फाल्गुन माह के शुक्ल पक्ष में होती है।
                    एक वैदिश नाम का नगर था जिसमें ब्राह्मण, क्षत्रिय, वैश्य और शूद्र चारों वर्ण आनंद सहित रहते थे। उस नगर में सदैव वेद ध्वनि गूंजा करती थी तथा पापी, दुराचारी तथा नास्तिक उस नगर में कोई नहीं था। उस नगर में चैतरथ नाम का चन्द्रवंशी राजा राज्य करता था। वह अत्यंत विद्वान तथा धर्मी था। उस नगर में कोई भी व्यक्ति दरिद्र व कंजूस नहीं था। सभी नगरवासी विष्णु भक्त थे और आबाल-वृद्ध स्त्री-पुरुष एकादशी का व्रत किया करते थे।
                    एक समय फाल्गुन मास के शुक्ल पक्ष की आमलकी एकादशी आई। उस दिन राजा, प्रजा तथा बाल-वृद्ध सबने हर्षपूर्वक व्रत किया। राजा अपनी प्रजा के साथ मंदिर में जाकर पूर्ण कुंभ स्थापित करके धूप, दीप, नैवेद्य, पंचरत्न आदि से धात्री / आंवले का पूजन करने लगे और इस प्रकार स्तुति करने लगे..
                    हे धात्री! तुम ब्रह्मस्वरूप हो, तुम ब्रह्माजी द्वारा उत्पन्न हुए हो और समस्त पापों का नाश करने वाले हो, तुमको नमस्कार है। अब तुम मेरा अर्घ्य स्वीकार करो। तुम श्रीराम चन्द्रजी द्वारा सम्मानित हो, मैं आपकी प्रार्थना करता हूं, अत: आप मेरे समस्त पापों का नाश करो। उस मंदिर में सब ने रात्रि को जागरण किया।
                    रात के समय वहां एक बहेलिया आया, जो अत्यंत पापी और दुराचारी था। वह अपने कुटुम्ब का पालन जीव-हत्या करके किया करता था। भूख और प्यास से अत्यंत व्याकुल वह बहेलिया इस जागरण को देखने के लिए मंदिर के एक कोने में बैठ गया और विष्णु भगवान तथा एकादशी माहात्म्य की कथा सुनने लगा। इस प्रकार अन्य मनुष्यों की भांति उसने भी सारी रात जागकर बिता दी।
                    प्रात:काल होते ही सब लोग अपने घर चले गए तो बहेलिया भी अपने घर चला गया। घर जाकर उसने भोजन किया। कुछ समय बीतने के पश्चात उस बहेलिए की मृत्यु हो गई।
                    मगर उस आमलकी एकादशी के व्रत तथा जागरण से उसने राजा विदूरथ के घर जन्म लिया और उसका नाम वसुरथ रखा गया। युवा होने पर वह चतुरंगिनी सेना के सहित तथा धन-धान्य से युक्त होकर 10 हजार ग्रामों का पालन करने लगा।
                    वह तेज में सूर्य के समान, कांति में चन्द्रमा के समान, वीरता में भगवान विष्णु के समान और क्षमा में पृथ्वी के समान था। वह अत्यंत धार्मिक, सत्यवादी, कर्मवीर और विष्णु भक्त था। वह प्रजा का समान भाव से पालन करता था। दान देना उसका नित्य कर्तव्य था।
                    एक दिन राजा शिकार खेलने के लिए गया। दैवयोग से वह मार्ग भूल गया और दिशा ज्ञान न रहने के कारण उसी वन में एक वृक्ष के नीचे सो गया। थोड़ी देर बाद पहाड़ी म्लेच्छ वहां पर आ गए और राजा को अकेला देखकर मारो, मारो शब्द करते हुए राजा की ओर दौड़े। म्लेच्छ कहने लगे कि इसी दुष्ट राजा ने हमारे माता, पिता, पुत्र, पौत्र आदि अनेक संबंधियों को मारा है तथा देश से निकाल दिया है अत: इसको अवश्य मारना चाहिए।
                    ऐसा कहकर वे म्लेच्छ उस राजा को मारने दौड़े और अनेक प्रकार के अस्त्र-शस्त्र उसके ऊपर फेंके। वे सब अस्त्र-शस्त्र राजा के शरीर पर गिरते ही नष्ट हो जाते और उनका वार पुष्प के समान प्रतीत होता। अब उन म्लेच्छों के अस्त्र-शस्त्र उलटा उन्हीं पर प्रहार करने लगे जिससे वे मूर्छित होकर गिरने लगे। उसी समय राजा के शरीर से एक दिव्य स्त्री उत्पन्न हुई। वह स्त्री अत्यंत सुंदर होते हुए भी उसकी भृकुटी टेढ़ी थी, उसकी आंखों से लाल-लाल अग्नि निकल रही थी जिससे वह दूसरे काल के समान प्रतीत होती थी।
                    वह स्त्री म्लेच्छों को मारने दौड़ी और थोड़ी ही देर में उसने सब म्लेच्छों को काल के गाल में पहुंचा दिया। जब राजा सोकर उठा तो उसने म्लेच्छों को मरा हुआ देखकर कहा कि इन शत्रुओं को किसने मारा है? इस वन में मेरा कौन हितैषी रहता है? वह ऐसा विचार कर ही रहा था कि आकाशवाणी हुई: हे राजा! इस संसार में विष्णु भगवान के अतिरिक्त कौन तेरी सहायता कर सकता है। इस आकाशवाणी को सुनकर राजा अपने राज्य में आ गया और सुखपूर्वक राज्य करने लगा।
                    महर्षि वशिष्ठ बोले कि हे राजन्! यह आमलकी एकादशी के व्रत का प्रभाव था। जो मनुष्य इस आमलकी एकादशी का व्रत करते हैं, वे प्रत्येक कार्य में सफल होते हैं और अंत में विष्णुलोक को जाते हैं।
                    `,
        end:`॥ जय श्री हरि ॥`
    },
    {
        title:`पापमोचनी एकादशी व्रत कथा`,
        description:`अर्जुन ने भगवान श्री कृष्ण से कहा: हे मधुसूदन! आपने फाल्गुन मास के शुक्ल पक्ष की एकादशी अर्थात आमलकी एकादशी के बारे मे विस्तार पूर्वक बतलाया। चैत्र माह के कृष्ण पक्ष मे आने वाली एकादशी का क्या नाम है? तथा उसकी विधि क्या है? कृपा करके आप मेरी बढ़ती हुई जिज्ञासा को शांत करें।
                    भगवान श्रीकृष्ण ने कहा: हे अर्जुन! चैत्र मास के कृष्ण पक्ष की एकादशी को पापमोचनी एकादशी के नाम से जाना जाता है। एक बार की बात है, पृथ्वीपति राजा मान्धाता ने लोमश ऋषि से यही प्रश्न किया था, जो तुमने मुझसे किया है। अतः जो कुछ भी ऋषि लोमश ने राजा मान्धाता को बतलाया, वही मैं तुमसे कह रहा हूँ।
                    राजा मान्धाता ने धर्म के गुह्यतम रहस्यों के ज्ञाता महर्षि लोमश से पूछा: हे ऋषिश्रेष्ठ! मनुष्य के पापों का मोचन किस प्रकार सम्भव है? कृपा कर कोई ऐसा सरल उपाय बतायें, जिससे सभी को सहज ही पापों से छुटकारा मिल जाए।
                    पापमोचनी एकादशी व्रत कथा!
                    महर्षि लोमश ने कहा: हे नृपति! चैत्र मास के कृष्ण पक्ष की एकादशी का नाम पापमोचिनी एकादशी है। उसके व्रत के प्रभाव से मनुष्यों के अनेक पाप नष्ट हो जाते हैं। मैं तुम्हें इस व्रत की कथा सुनाता हूँ, ध्यानपूर्वक श्रवण करो।
                    प्राचीन समय में चैत्ररथ नामक एक वन था। उसमें अप्सराएँ किन्नरों के साथ विहार किया करती थीं। वहाँ सदैव वसन्त का मौसम रहता था, अर्थात उस जगह सदा नाना प्रकार के पुष्प खिले रहते थे। कभी गन्धर्व कन्‍याएँ विहार किया करती थीं, कभी देवेन्द्र अन्य देवताओं के साथ क्रीड़ा किया करते थे।
                    उसी वन में मेधावी नाम के एक ऋषि भी तपस्या में लीन रहते थे। वे शिवभक्त थे। एक दिन मञ्जुघोषा नामक एक अप्सरा ने उनको मोहित कर उनकी निकटता का लाभ उठाने की चेष्टा की, इसलिए वह कुछ दूरी पर बैठ वीणा बजाकर मधुर स्वर में गाने लगी।
                    उसी समय शिव भक्त महर्षि मेधावी को कामदेव भी जीतने का प्रयास करने लगे। कामदेव ने उस सुन्दर अप्सरा के भ्रू का धनुष बनाया। कटाक्ष को उसकी प्रत्यन्चा बनाई और उसके नेत्रों को मञ्जुघोषा अप्सरा का सेनापति बनाया। इस तरह कामदेव अपने शत्रुभक्त को जीतने को तैयार हुआ।
                    उस समय महर्षि मेधावी भी युवावस्था में थे और काफी हृष्ट-पुष्ट थे। उन्होंने यज्ञोपवीत तथा दण्ड धारण कर रखा था। वे दूसरे कामदेव के समान प्रतीत हो रहे थे। उस मुनि को देखकर कामदेव के वश में हुई मञ्जुघोषा ने धीरे-धीरे मधुर वाणी से वीणा पर गायन शुरू किया तो महर्षि मेधावी भी मञ्जुघोषा के मधुर गाने पर तथा उसके सौन्दर्य पर मोहित हो गए। वह अप्सरा मेधावी मुनि को कामदेव से पीड़ित जानकर उनसे आलिङ्गन करने लगी।
                    महर्षि मेधावी उसके सौन्दर्य पर मोहित होकर शिव रहस्य को भूल गए और काम के वशीभूत होकर उसके साथ रमण करने लगे।
                    काम के वशीभूत होने के कारण मुनि को उस समय दिन-रात का कुछ भी ज्ञान न रहा और काफी समय तक वे रमण करते रहे। तदुपरान्त मञ्जुघोषा उस मुनि से बोली: हे ऋषिवर! अब मुझे बहुत समय हो गया है, अतः स्वर्ग जाने की आज्ञा दीजिये।
                    अप्सरा की बात सुनकर ऋषि ने कहा: हे मोहिनी! सन्ध्या को तो आयी हो, प्रातःकाल होने पर चली जाना।
                    ऋषि के ऐसे वचनों को सुनकर अप्सरा उनके साथ रमण करने लगी। इसी प्रकार दोनों ने साथ-साथ बहुत समय बिताया।
                    मञ्जुघोषा ने एक दिन ऋषि से कहा: हे विप्र! अब आप मुझे स्वर्ग जाने की आज्ञा दीजिये।
                    मुनि ने इस बार भी वही कहा: हे रूपसी! अभी ज्यादा समय व्यतीत नहीं हुआ है, कुछ समय और ठहरो।
                    मुनि की बात सुन अप्सरा ने कहा: हे ऋषिवर! आपकी रात तो बहुत लम्बी है। आप स्वयं ही सोचिये कि मुझे आपके पास आये कितना समय हो गया। अब और ज्यादा समय तक ठहरना क्या उचित है?
                    अप्सरा की बात सुन मुनि को समय का बोध हुआ और वह गम्भीरतापूर्वक विचार करने लगे। जब उन्हें समय का ज्ञान हुआ कि उन्हें रमण करते सत्तावन (57) वर्ष व्यतीत हो चुके हैं तो उस अप्सरा को वह काल का रूप समझने लगे।
                    इतना ज्यादा समय भोग-विलास में व्यर्थ चला जाने पर उन्हें बड़ा क्रोध आया। अब वह भयंकर क्रोध में जलते हुए उस तप नाश करने वाली अप्सरा की तरफ भृकुटी तानकर देखने लगे। क्रोध से उनके अधर काँपने लगे और इन्द्रियाँ बेकाबू होने लगीं।
                    क्रोध से थरथराते स्वर में मुनि ने उस अप्सरा से कहा: मेरे तप को नष्ट करने वाली दुष्टा! तू महा पापिन और बहुत ही दुराचारिणी है, तुझ पर धिक्कार है। अब तू मेरे श्राप से पिशाचिनी बन जा।
                    मुनि के क्रोधयुक्त श्राप से वह अप्सरा पिशाचिनी बन गई। यह देख वह व्यथित होकर बोली: हे ऋषिवर! अब मुझ पर क्रोध त्यागकर प्रसन्न होइए और कृपा करके बताइये कि इस शाप का निवारण किस प्रकार होगा? विद्वानों ने कहा है, साधुओं की सङ्गत अच्छा फल देने वाली होती है और आपके साथ तो मेरे बहुत वर्ष व्यतीत हुए हैं, अतः अब आप मुझ पर प्रसन्न हो जाइए, अन्यथा लोग कहेंगे कि एक पुण्य आत्मा के साथ रहने पर मञ्जुघोषा को पिशाचिनी बनना पड़ा।
                    मञ्जुघोषा की बात सुनकर मुनि को अपने क्रोध पर अत्यन्त ग्लानि हुई साथ ही अपनी अपकीर्ति का भय भी हुआ, अतः पिशाचिनी बनी मञ्जुघोषा से मुनि ने कहा: तूने मेरा बड़ा बुरा किया है, किन्तु फिर भी मैं तुझे इस श्राप से मुक्ति का उपाय बतलाता हूँ। चैत्र मास के कृष्ण पक्ष की जो एकादशी है, उसका नाम पापमोचिनी है। उस एकादशी का उपवास करने से तेरी पिशाचिनी की देह से मुक्ति हो जाएगी।
                    ऐसा कहकर मुनि ने उसको व्रत का सब विधान समझा दिया। फिर अपने पापों का प्रायश्चित करने के लिए वे अपने पिता च्यवन ऋषि के पास गये।
                    च्यवन ऋषि ने अपने पुत्र मेधावी को देखकर कहा: हे पुत्र! ऐसा क्या किया है तूने कि तेरे सभी तप नष्ट हो गए हैं? जिससे तुम्हारा समस्त तेज मलिन हो गया है?
                    मेधावी मुनि ने लज्जा से अपना सिर झुकाकर कहा: पिताश्री! मैंने एक अप्सरा से रमण करके बहुत बड़ा पाप किया है। इसी पाप के कारण सम्भवतः मेरा सारा तेज और मेरे तप नष्ट हो गए हैं। कृपा करके आप इस पाप से छूटने का उपाय बतलाइये।
                    ऋषि ने कहा: हे पुत्र! तुम चैत्र माह के कृष्ण पक्ष की पापमोचिनी एकादशी का विधि तथा भक्तिपूर्वक उपवास करो, इससे तुम्हारे सभी पाप नष्ट हो जाएंगे।
                    अपने पिता च्यवन ऋषि के वचनों को सुनकर मेधावी मुनि ने पापमोचिनी एकादशी का विधिपूर्वक व्रत किया। उसके प्रभाव से उनके सभी पाप नष्ट हो गए।
                    मञ्जुघोषा अप्सरा भी पापमोचिनी एकादशी का उपवास करने से पिशाचिनी की देह से छूट गई और पुनः अपना सुन्दर रूप धारण कर स्वर्गलोक चली गई।
                    लोमश मुनि ने कहा: हे राजन! इस पापमोचिनी एकादशी के प्रभाव से समस्त पाप नष्ट हो जाते हैं। इस एकादशी की कथा के श्रवण व पठन से एक हजार गौदान करने का फल प्राप्त होता है। इस उपवास के करने से ब्रह्म हत्या करने वाले, स्वर्ण चुराने वाले, मद्यपान करने वाले, अगम्या गमन करने वाले आदि भयंकर पाप भी नष्ट हो जाते हैं और अन्त में स्वर्गलोक की प्राप्ति होती है।
                    `,
        end:`॥ जय श्री हरि ॥`
    },
    {
        title:`कामदा एकादशी व्रत कथा`,
        description:`धर्मराज युधिष्ठिर कहने लगे: हे भगवन्! मैं आपको कोटि-कोटि नमन करता हूँ। आपने चैत्र मास के कृष्ण पक्ष की एकादशी अर्थात पापमोचनी एकादशी के बारे मे विस्तार पूर्वक बतलाया। अब आप कृपा करके चैत्र शुक्ल एकादशी का क्या नाम है? तथा उसकी विधि एवं महात्म्य क्या है?
                    भगवान श्रीकृष्ण ने कहा: हे धर्मराज! चैत्र मास के शुक्ल पक्ष की एकादशी को कामदा एकादशी के नाम से जाना जाता है। एक समय की बात है, यही प्रश्न राजा दिलीप ने गुरु वशिष्ठजी से किया था और जो समाधान उन्होंने बतलाया, वही मैं तुमसे कह रहा हूँ।
                    कामदा एकादशी व्रत कथा!
                    प्राचीनकाल में भोगीपुर नामक एक नगर था। वहाँ पर अनेक ऐश्वर्यों से युक्त पुण्डरीक नाम का एक राजा राज्य करता था। भोगीपुर नगर में अनेक अप्सरा, किन्नर तथा गन्धर्व वास करते थे। उनमें से एक जगह ललिता और ललित नाम के दो स्त्री-पुरुष अत्यंत वैभवशाली घर में निवास करते थे। उन दोनों में अत्यंत स्नेह था, यहाँ तक कि अलग-अलग हो जाने पर दोनों व्याकुल हो जाते थे।
                    एक समय पुण्डरीक की सभा में अन्य गंधर्वों सहित ललित भी गान कर रहा था। गाते-गाते उसको अपनी प्रिय ललिता का ध्यान आ गया और उसका स्वर भंग होने के कारण गाने का स्वरूप बिगड़ गया। ललित के मन के भाव जानकर कार्कोट नामक नाग ने पद भंग होने का कारण राजा से कह दिया। तब पुण्डरीक ने क्रोधपूर्वक कहा कि तू मेरे सामने गाता हुआ अपनी स्त्री का स्मरण कर रहा है। अत: तू नरभक्षी दैत्य बनकर अपने कर्म का फल भोग।
                    पुण्डरीक के श्राप से ललित उसी क्षण महाकाय विशाल राक्षस हो गया। उसका मुख अत्यंत भयंकर, नेत्र सूर्य-चंद्रमा की तरह प्रदीप्त तथा मुख से अग्नि निकलने लगी। उसकी नाक पर्वत की कंदरा के समान विशाल हो गई और गर्दन पर्वत के समान लगने लगी। सिर के बाल पर्वतों पर खड़े वृक्षों के समान लगने लगे तथा भुजाएँ अत्यंत लंबी हो गईं। कुल मिलाकर उसका शरीर आठ योजन के विस्तार में हो गया। इस प्रकार राक्षस होकर वह अनेक प्रकार के दुःख भोगने लगा।
                    जब उसकी प्रियतमा ललिता को यह वृत्तान्त मालूम हुआ तो उसे अत्यंत खेद हुआ और वह अपने पति के उद्धार का यत्न सोचने लगी। वह राक्षस अनेक प्रकार के घोर दुःख सहता हुआ घने वनों में रहने लगा। उसकी स्त्री उसके पीछे-पीछे जाती और विलाप करती रहती।
                    एक बार ललिता अपने पति के पीछे घूमती-घूमती विन्ध्याचल पर्वत पर पहुँच गई, जहाँ पर श्रृंगी ऋषि का आश्रम था। ललिता शीघ्र ही श्रृंगी ऋषि के आश्रम में गई और वहाँ जाकर विनीत भाव से प्रार्थना करने लगी।
                    उसे देखकर श्रृंगी ऋषि बोले: हे सुभगे! तुम कौन हो और यहाँ किस लिए आई हो? ‍
                    ललिता बोली: हे मुने! मेरा नाम ललिता है। मेरा पति राजा पुण्डरीक के श्राप से विशालकाय राक्षस हो गया है। इसका मुझको महान दुःख है। उसके उद्धार का कोई उपाय बतलाइए।
                    श्रृंगी ऋषि बोले: हे गंधर्व कन्या! अब चैत्र शुक्ल एकादशी आने वाली है, जिसका नाम कामदा एकादशी है। इसका व्रत करने से मनुष्य के सब कार्य सिद्ध होते हैं। यदि तू कामदा एकादशी का व्रत कर उसके पुण्य का फल अपने पति को दे तो वह शीघ्र ही राक्षस योनि से मुक्त हो जाएगा और राजा का श्राप भी अवश्यमेव शांत हो जाएगा।
                    मुनि के ऐसे वचन सुनकर ललिता ने चैत्र शुक्ल एकादशी आने पर उसका व्रत किया और द्वादशी को ब्राह्मणों के सामने अपने व्रत का फल अपने पति को देती हुई भगवान से इस प्रकार प्रार्थना करने लगी: हे प्रभो! मैंने जो यह व्रत किया है इसका फल मेरे पतिदेव को प्राप्त हो जाए जिससे वह राक्षस योनि से मुक्त हो जाए।
                    एकादशी का फल देते ही उसका पति राक्षस योनि से मुक्त होकर अपने पुराने स्वरूप को प्राप्त हुआ। फिर अनेक सुंदर वस्त्राभूषणों से युक्त होकर ललिता के साथ विहार करने लगा। उसके पश्चात वे दोनों विमान में बैठकर स्वर्गलोक को चले गए।
                    वशिष्ठ मुनि कहने लगे: हे राजन्! इस व्रत को विधिपूर्वक करने से समस्त पाप नाश हो जाते हैं तथा राक्षस आदि की योनि भी छूट जाती है। संसार में इसके बराबर कोई और दूसरा व्रत नहीं है। इसकी कथा पढ़ने या सुनने से वाजपेय यज्ञ का फल प्राप्त होता है।
                    `,
        end:`॥ जय श्री हरि ॥`
    },
    {
        title:`वरुथिनी एकादशी व्रत कथा`,
        description:`धर्मराज युधिष्ठिर बोले: हे भगवन्! मैं आपको नमस्कार करता हूँ। आपने चैत्र मास के शुक्ल पक्ष की एकादशी अर्थात कामदा एकादशी के बारे मे विस्तार पूर्वक बतलाया। अब आप कृपा करके वैशाख कृष्ण एकादशी का क्या नाम है? तथा उसकी विधि एवं महात्म्य क्या है?
                    भगवान श्रीकृष्ण ने कहा: हे राजेश्वर! वैशाख मास के कृष्ण पक्ष की एकादशी को वरुथिनी एकादशी के नाम से जाना जाता है। यह सौभाग्य देने वाली, सब पापों को नष्ट करने वाली तथा अंत में मोक्ष देने वाली है। इसकी महात्म्य कथा आपसे कहता हूँ..
                    वरुथिनी एकादशी व्रत कथा!
                    प्राचीन काल में नर्मदा नदी के तट पर मान्धाता नामक राजा राज्य करते थे। वह अत्यंत दानशील तथा तपस्वी थे। एक दिन जब वह जंगल में तपस्या कर रहे थे, तभी न जाने कहाँ से एक जंगली भालू आया और राजा का पैर चबाने लगा। राजा पूर्ववत अपनी तपस्या में लीन रहे। कुछ देर बाद पैर चबाते-चबाते भालू राजा को घसीटकर पास के जंगल में ले गया।
                    राजा बहुत घबराया, मगर तापस धर्म अनुकूल उसने क्रोध और हिंसा न करके भगवान विष्णु से प्रार्थना की, करुण भाव से भगवान विष्णु को पुकारा। उसकी पुकार सुनकर भगवान श्रीहरि विष्णु प्रकट हुए और उन्होंने चक्र से भालू को मार डाला।
                    राजा का पैर भालू पहले ही खा चुका था। इससे राजा बहुत ही शोकाकुल हुए। उन्हें दुःखी देखकर भगवान विष्णु बोले: हे वत्स! शोक मत करो। तुम मथुरा जाओ और वरूथिनी एकादशी का व्रत रखकर मेरी वराह अवतार मूर्ति की पूजा करो। उसके प्रभाव से पुन: सुदृढ़ अंगों वाले हो जाओगे। इस भालू ने तुम्हें जो काटा है, यह तुम्हारे पूर्व जन्म का अपराध था।
                    भगवान की आज्ञा मानकर राजा मान्धाता ने मथुरा जाकर श्रद्धापूर्वक वरूथिनी एकादशी का व्रत किया। इसके प्रभाव से राजा शीघ्र ही पुन: सुंदर और संपूर्ण अंगों वाला हो गया। इसी एकादशी के प्रभाव से राजा मान्धाता स्वर्ग गये थे।
                    जो भी व्यक्ति भय से पीड़ित है उसे वरूथिनी एकादशी का व्रत रखकर भगवान विष्णु का स्मरण करना चाहिए। इस व्रत को करने से समस्त पापों का नाश होकर मोक्ष मिलता है।
                    `,
        end:`॥ जय श्री हरि ॥`
    },
    {
        title:`मोहिनी एकादशी व्रत कथा`,
        description:`धर्मराज युधिष्ठिर ने कहा: हे भगवन्! मैं आपको नमस्कार करता हूँ। आपने वैशाख मास के कृष्ण पक्ष की एकादशी अर्थात वरुथिनी एकादशी के बारे मे विस्तार पूर्वक बतलाया। अब आप कृपा करके वैशाख शुक्ल एकादशी का क्या नाम है? तथा उसकी कथा क्या है? इस व्रत की क्या विधि है, यह सब विस्तारपूर्वक बताइए।
                    भगवान श्रीकृष्ण कहने लगे: हे धर्मराज! वैशाख मास के शुक्ल पक्ष की एकादशी को मोहिनी एकादशी के नाम से जाना जाता है। मैं आपसे एक कथा कहता हूँ, जिसे महर्षि वशिष्ठ ने श्री रामचंद्रजी से कही थी। राजन आप कृपया ध्यानपूर्वक सुनें।
                    मोहिनी एकादशी व्रत कथा!
                    एक समय श्रीराम बोले कि हे गुरुदेव! कोई ऐसा व्रत बताइए, जिससे समस्त पाप और दु:ख का नाश हो जाए। मैंने सीताजी के वियोग में बहुत दु:ख भोगे हैं।
                    महर्षि वशिष्ठ बोले: हे राम! आपने बहुत सुंदर प्रश्न किया है। आपकी बुद्धि अत्यंत शुद्ध तथा पवित्र है। यद्यपि आपका नाम स्मरण करने से मनुष्य पवित्र और शुद्ध हो जाता है, तब भी लोकहित में यह प्रश्न अच्छा है। वैशाख मास में जो एकादशी आती है उसका नाम मोहिनी एकादशी है। इसका व्रत करने से मनुष्य सब पापों तथा दु:खों से छूटकर मोहजाल से मुक्त हो जाता है। मैं इसकी कथा कहता हूँ। ध्यानपूर्वक सुनो।
                    सरस्वती नदी के तट पर भद्रावती नाम की एक नगरी में द्युतिमान नामक चंद्रवंशी राजा राज करता था। वहाँ धन-धान्य से संपन्न व पुण्यवान धनपाल नामक वैश्य भी रहता है। वह अत्यंत धर्मालु और विष्णु भक्त था। उसने नगर में अनेक भोजनालय, प्याऊ, कुएँ, सरोवर, धर्मशाला आदि बनवाए थे। सड़कों पर आम, जामुन, नीम आदि के अनेक वृक्ष भी लगवाए थे।
                    उसके 5 पुत्र थे- सुमना, सद्‍बुद्धि, मेधावी, सुकृति और धृष्टबुद्धि। इनमें से पाँचवाँ पुत्र धृष्टबुद्धि महापापी था। वह पितर आदि को नहीं मानता था। वह वेश्या, दुराचारी मनुष्यों की संगति में रहकर जुआ खेलता और पर-स्त्री के साथ भोग-विलास करता तथा मद्य-मांस का सेवन करता था। इसी प्रकार अनेक कुकर्मों में वह पिता के धन को नष्ट करता रहता था।
                    इन्हीं कारणों से त्रस्त होकर पिता ने उसे घर से निकाल दिया था। घर से बाहर निकलने के बाद वह अपने गहने एवं कपड़े बेचकर अपना निर्वाह करने लगा। जब सब कुछ नष्ट हो गया तो वेश्या और दुराचारी साथियों ने उसका साथ छोड़ दिया। अब वह भूख-प्यास से अति दु:खी रहने लगा। कोई सहारा न देख चोरी करना सीख गया।
                    एक बार वह पकड़ा गया तो वैश्य का पुत्र जानकर चेतावनी देकर छोड़ दिया गया। मगर दूसरी बार फिर पकड़ में आ गया। राजाज्ञा से इस बार उसे कारागार में डाल दिया गया। कारागार में उसे अत्यंत दु:ख दिए गए। बाद में राजा ने उसे नगरी से निकल जाने का कहा।
                    वह नगरी से निकल वन में चला गया। वहाँ वन्य पशु-पक्षियों को मारकर खाने लगा। कुछ समय पश्चात वह बहेलिया बन गया और धनुष-बाण लेकर पशु-पक्षियों को मार-मारकर खाने लगा।
                    एक दिन भूख-प्यास से व्यथित होकर वह खाने की तलाश में घूमता हुआ कौण्डिन्य ऋषि के आश्रम में पहुँच गया। उस समय वैशाख मास था और ऋषि गंगा स्नान कर आ रहे थे। उनके भीगे वस्त्रों के छींटे उस पर पड़ने से उसे कुछ सद्‍बुद्धि प्राप्त हुई।
                    वह कौण्डिन्य मुनि से हाथ जोड़कर कहने लगा कि: हे मुने! मैंने जीवन में बहुत पाप किए हैं। आप इन पापों से छूटने का कोई साधारण बिना धन का उपाय बताइए। उसके दीन वचन सुनकर मुनि ने प्रसन्न होकर कहा कि तुम वैशाख शुक्ल की मोहिनी एकादशी का व्रत करो। इससे अनेक जन्मों के किये हुए मेरु पर्वत जैसे समस्त महापाप भी नष्ट हो जाते हैं |
                    मुनि के वचन सुनकर वह अत्यंत प्रसन्न हुआ और उनके द्वारा बताई गई विधि के अनुसार व्रत किया।
                    हे राम! इस व्रत के प्रभाव से उसके सब पाप नष्ट हो गए और अंत में वह गरुड़ पर बैठकर विष्णुलोक को गया। इस व्रत से मोह आदि सब नष्ट हो जाते हैं। संसार में इस व्रत से श्रेष्ठ कोई व्रत नहीं है। इसके माहात्म्य को पढ़ने से अथवा सुनने से एक हजार गौदान का फल प्राप्त होता है।
                    `,
        end:`॥ जय श्री हरि ॥`
    },
    {
        title:`अचला एकादशी व्रत कथा`,
        description:`धर्मराज युधिष्ठिर भगवान श्री कृष्ण से कहते हैं: हे भगवन्! आपने वैशाख मास के शुक्ल पक्ष की एकादशी अर्थात मोहिनी एकादशी के बारे मे विस्तार पूर्वक बतलाया। अब आप कृपा करके ज्येष्ठ कृष्ण एकादशी का क्या नाम है? तथा उसकी कथा क्या है? इस व्रत की क्या विधि है, कृपा कर यह सब विस्तारपूर्वक कहिए।
                    भगवान श्रीकृष्ण कहने लगे: हे राजन! ज्येष्ठ मास के कृष्ण पक्ष की एकादशी को अचला एकादशी तथा अपरा एकादशी दोनो ही नामों से जाना जाता है। क्योंकि यह अपार धन देने वाली है। जो मनुष्य इस व्रत को करते हैं, वे संसार में प्रसिद्ध हो जाते हैं।
                    इस दिन भगवान त्रिविक्रम की पूजा की जाती है। भगवान त्रिविक्रम में भगवान विष्णु, भगवान विट्ठल और बालाजी के दर्शन होते हैं।
                    अपरा एकादशी का महत्व!
                    अपरा एकादशी के व्रत के प्रभाव से ब्रह्म हत्या, भू‍त योनि, दूसरे की निंदा आदि के सब पाप दूर हो जाते हैं। इस व्रत के करने से परस्त्री गमन, झूठी गवाही देना, झूठ बोलना, झूठे शास्त्र पढ़ना या बनाना, झूठा ज्योतिषी बनना तथा झूठा वैद्य बनना आदि सब पाप नष्ट हो जाते हैं।
                    जो क्षत्रिय युद्ध से भाग जाए वे नरकगामी होते हैं, परंतु अपरा एकादशी का व्रत करने से वे भी स्वर्ग को प्राप्त होते हैं। जो शिष्य गुरु से शिक्षा ग्रहण करते हैं फिर उनकी निंदा करते हैं वे अवश्य नरक में पड़ते हैं। मगर अपरा एकादशी का व्रत करने से वे भी इस पाप से मुक्त हो जाते हैं।
                    जो फल तीनों पुष्कर में कार्तिक पूर्णिमा को स्नान करने से या गंगा तट पर पितरों को पिंडदान करने से प्राप्त होता है, वही अपरा एकादशी का व्रत करने से प्राप्त होता है। मकर के सूर्य में प्रयागराज के स्नान से, शिवरात्रि का व्रत करने से, सिंह राशि के बृहस्पति में गोमती नदी के स्नान से, कुंभ में केदारनाथ के दर्शन या बद्रीनाथ के दर्शन, सूर्यग्रहण में कुरुक्षेत्र के स्नान से, स्वर्णदान करने से अथवा अर्द्ध प्रसूता गौदान से जो फल मिलता है, वही फल अपरा एकादशी के व्रत से मिलता है।
                    यह व्रत पापरूपी वृक्ष को काटने के लिए कुल्हाड़ी है। पापरूपी ईंधन को जलाने के लिए ‍अग्नि, पापरूपी अंधकार को मिटाने के लिए सूर्य के समान, मृगों को मारने के लिए सिंह के समान है। अत: मनुष्य को पापों से डरते हुए इस व्रत को अवश्य करना चाहिए। अपरा एकादशी का व्रत तथा भगवान का पूजन करने से मनुष्य सब पापों से छूटकर विष्णु लोक को जाता है।
                    अपरा एकादशी व्रत कथा!
                    इसकी प्रचलित कथा के अनुसार प्राचीन काल में महीध्वज नामक एक धर्मात्मा राजा था। उसका छोटा भाई वज्रध्वज बड़ा ही क्रूर, अधर्मी तथा अन्यायी था। वह अपने बड़े भाई से द्वेष रखता था। उस पापी ने एक दिन रात्रि में अपने बड़े भाई की हत्या करके उसकी देह को एक जंगली पीपल के नीचे गाड़ दिया। इस अकाल मृत्यु से राजा प्रेतात्मा के रूप में उसी पीपल पर रहने लगा और अनेक उत्पात करने लगा।
                    एक दिन अचानक धौम्य नामक ॠषि उधर से गुजरे। उन्होंने प्रेत को ज्ञानचक्षु से देखा और तपोबल से उसके अतीत को जान लिया। अपने तपोबल से प्रेत उत्पात का कारण समझा। ॠषि ने प्रसन्न होकर उस प्रेत को पीपल के पेड़ से उतारा तथा परलोक विद्या का उपदेश दिया।
                    दयालु ॠषि ने राजा की प्रेत योनि से मुक्ति के लिए स्वयं ही अपरा एकादशी का व्रत किया और उसे अगति से छुड़ाने को उसका पुण्य प्रेत को अर्पित कर दिया। इस पुण्य के प्रभाव से राजा की प्रेत योनि से मुक्ति हो गई। वह ॠषि को सप्रेम धन्यवाद देता हुआ दिव्य देह धारण कर पुष्पक विमान में बैठकर स्वर्ग को चला गया।
                    हे राजन! यह अपरा एकादशी की कथा मैंने लोकहित के लिए कही है। इसे पढ़ने अथवा सुनने से मनुष्य सब पापों से छूट जाता है।
                    `,
        end:`॥ जय श्री हरि ॥`
    }
]

const bhaiDuj=[
    {
        title:`भैया दूज पौराणिक कथा`,
        description:`सूर्य की पत्नी संज्ञा से 2 संतानें थीं, पुत्र यमराज तथा पुत्री यमुना। संज्ञा सूर्य का तेज सहन न कर पाने के कारण अपनी छायामूर्ति का निर्माण कर उन्हें ही अपने पुत्र-पुत्री को सौंपकर वहाँ से चली गई। छाया को यम और यमुना से अत्यधिक लगाव तो नहीं था, किंतु यमुना अपने भाई यमराज से बड़ा स्नेह करती थीं।
                    यमुना अपने भाई यमराज के यहाँ प्राय: जाती और उनके सुख-दुःख की बातें पूछा करती। तथा यमुना, यमराज को अपने घर पर आने के लिए भी आमंत्रित करतीं, किंतु व्यस्तता तथा अत्यधिक दायित्व के कारण वे उसके घर न जा पाते थे।
                    एक बार कार्तिक शुक्ल द्वितीया को यमराज अपनी बहन यमुना के घर अचानक जा पहुँचे। बहन के घर जाते समय यमराज ने नरक में निवास करने वाले जीवों को मुक्त कर दिया। बहन यमुना ने अपने सहोदर भाई का बड़ा आदर-सत्कार किया। विविध व्यंजन बनाकर उन्हें भोजन कराया तथा भाल पर तिलक लगाया। जब वे वहाँ से चलने लगे, तब उन्होंने यमुना से कोई भी मनोवांछित वर मांगने का अनुरोध किया।
                    यमुना ने उनके आग्रह को देखकर कहा: भैया! यदि आप मुझे वर देना ही चाहते हैं तो यही वर दीजिए कि आज के दिन प्रतिवर्ष आप मेरे यहाँ आया करेंगे और मेरा आतिथ्य स्वीकार किया करेंगे। इसी प्रकार जो भाई अपनी बहन के घर जाकर उसका आतिथ्य स्वीकार करे तथा इस दिन जो बहन अपने भाई को टीका करके भोजन खिलाये, उसे आपका भय न रहे। इसी के साथ उन्होंने यह भी वरदान दिया कि यदि इस दिन भाई-बहन यमुना नदी में डुबकी लगाएंगे तो वे यमराज के प्रकोप से बचे रहेंगे।
                    यमुना की प्रार्थना को यमराज ने स्वीकार कर लिया। तभी से बहन-भाई का यह त्यौहार मनाया जाने लगा। भैया दूज त्यौहार का मुख्य उद्देश्य, भाई-बहन के मध्य सद्भावना, तथा एक-दूसरे के प्रति निष्कपट प्रेम को प्रोत्साहित करना है। भैया दूज के दिन ही पांच दीनो तक चलने वाले दीपावली उत्सव का समापन भी हो जाता है।
                    इस दिन अगर अपनी बहन न हो तो ममेरी, फुफेरी या मौसेरी बहनों को उपहार देकर ईश्वर का आर्शीवाद प्राप्त कर सकते हैं। जो पुरुष यम द्वितीया को बहन के हाथ का खाना खाता है, उसे धर्म, धन, अर्थ, आयुष्य और विविध प्रकार के सुख मिलते हैं। साथ ही यम द्वितीय के दिन शाम को घर में बत्ती जलाने से पहले घर के बाहर चार बत्तियों से युक्त दीपक जलाकर दीप-दान करना भी फलदायी होता है।
                    `,
        end:``
    },
    {
        title:`भैया दूज पौराणिक कथा`,
        description:`एक बुढ़िया माई थीं, उसके सात बेटे और एक बेटी थी। बेटी कि शादी हो चुकी थी। जब भी उसके बेटे कि शादी होती, फेरों के समय एक नाग आता और उसके बेटे को डस लेता था। बेटे कि वही म्रत्यु हो जाती और बहू विधवा, इस तरह उसके छह बेटे मर गये। सातवे कि शादी होनी बाकी थी। इस तरह अपने बेटों के मर जाने के दुःख से बूढ़ीमाई रो-रो के अंधी हो गयी थी।
                    भाई दूज आने को हुई तो भाई ने कहा कि मैं बहिन से तिलक कराने जाऊँगा। माँ ने कहा ठीक है। उधर जब बहिन को पता चला कि, उसका भाई आ रहा है तो वह खुशी से पागल होकर पड़ोसन के गयी और पूछने लगी कि जब बहुत प्यारा भाई घर आए तो क्या बनाना चलिए? पड़ोसन उसकी खुशी को देख कर जलभुन गयी और कह दिया कि,” दूध से रसोई लेप, घी में चावल पका। ” बहिन ने एसा ही किया।
                    उधर भाई जब बहिन के घर जा रहा था तो उसे रास्ते में साँप मिला। साँप उसे डसने को हुआ।
                    भाई बोला: तुम मुझे क्यू डस रहे हो?
                    साँप बोला: मैं तुम्हारा काल हूँ। और मुझे तुमको डसना है।
                    भाई बोला: मेरी बहिन मेरा इंतजार कर रही है। मैं जब तिलक करा के वापस लौटूँगा, तब तुम मुझे डस लेना।
                    साँप ने कहा: भला आज तक कोई अपनी मौत के लिए लौट के आया है, जो तुम आऔगे।
                    भाई ने कहा: अगर तुझे यकीन नही है तो तू मेरे झोले में बैठ जा| जब मैं अपनी बहिन के तिलक कर लू तब तू मुझे डस लेना। साँप ने एसा ही किया।
                    भाई बहिन के घर पहुँच गया। दोनो बड़े खुश हुए।
                    भाई बोला: बहिन, जल्दी से खाना दे, बड़ी भूख लगी है।
                    बहिन क्या करे। न तो दूध कि रसोई सूखे, न ही घी में चावल पके।
                    भाई ने पूछा: बहिन इतनी देर क्यूँ लग रही है? तू क्या पका रही है?
                    तब बहिन ने बताया कि ऐसे-ऐसे किया है।
                    भाई बोला: पगली! कहीं घी में भी चावल पके हैं, या दूध से कोई रसोई लीपे है। गोबर से रसोई लीप, दूध में चावल पका।
                    बहिन ने एसा ही किया। खाना खा के भाई को बहुत ज़ोर नींद आने लगी। इतने में बहिन के बच्चे आ गये। बोले-मामा मामा हमारे लिए क्या लाए हो?
                    भाई बोला: में तो कुछ नही लाया।
                    बच्चो ने वह झोला ले लिया जिसमें साँप था। जेसे ही उसे खोला, उसमे से हीरे का हार निकला।
                    बहिन ने कहा: भैया तूने बताया नही कि तू मेरे लिए इतना सुंदर हार लाए हो।
                    भाई बोला: बहना तुझे पसंद है तो तू लेले, मुझे हार का क्या करना।
                    अगले दिन भाई बोला: अब मुझे जाना है, मेरे लिए खाना रख दे| बहिन ने उसके लिए लड्डू बना के एक डब्बे मे रख के दे दिए।
                    भाई कुछ दूर जाकर, थक कर एक पेड़ के नीचे सो गया। उधर बहिन के जब बच्चों को जब भूख लगी तो माँ से कहा कि खाना दे दो।
                    माँ ने कहा: खाना अभी बनने में देर है। तो बच्चे बोले कि मामा को जो रखा है वही दे दो। तो वह बोली कि लड्डू बनाने के लिए बाजरा पीसा था, वही बचा पड़ा है चक्की में, जाकर खा लो। बच्चों ने देखा कि चक्की में तो साँप कि हड्डियाँ पड़ी है।
                    यही बात माँ को आकर बताई तो वह बावड़ी सी हो कर भाई के पीछे भागी। रास्ते भर लोगों से पूछती कि किसी ने मेरा गैल बाटोई देखा, किसी ने मेरा बावड़ा सा भाई देखा। तब एक ने बताया कि कोई लेटा तो है पेड़ के नीचे, देख ले वही तो नहीं। भागी भागी पेड़ के नीचे पहुची। अपने भाई को नींद से उठाया। भैया भैया कहीं तूने मेरे लड्डू तो नही खाए!!
                    भाई बोला: ये ले तेरे लड्डू, नहीं खाए मैने। ले दे के लड्डू ही तो दिए थे, उसके भी पीछे पीछे आ गयी।
                    बहिन बोली: नहीं भाई, तू झूठ बोल रहा है, जरूर तूने खाया है| अब तो मैं तेरे साथ चलूंगी।
                    भाई बोला: तू न मान रही है तो चल फिर।
                    चलते चलते बहिन को प्यास लगती है, वह भाई को कहती है कि मुझे पानी पीना है।
                    भाई बोला: अब मैं यहाँ तेरे लिए पानी कहाँ से लाउ, देख ! दूर कहीं चील उड़ रहीं हैं,चली जा वहाँ शायद तुझे पानी मिल जाए।
                    तब बहिन वहाँ गयी, और पानी पी कर जब लौट रही थी तो रास्ते में देखती है कि एक जगह ज़मीन में 6 शिलाए गढ़ी हैं, और एक बिना गढ़े रखी हुई थी। उसने एक बुढ़िया से पूछा कि ये शिलाएँ कैसी हैं।
                    उस बुढ़िया ने बताया कि: एक बुढ़िया है। उसके सात बेटे थे। 6 बेटे तो शादी के मंडप में ही मर चुके हैं, तो उनके नाम कि ये शिलाएँ ज़मीन में गढ़ी हैं, अभी सातवे कि शादी होनी बाकी है। जब उसकी शादी होगी तो वह भी मंडप में ही मर जाएगा, तब यह सातवी सिला भी ज़मीन में गड़ जाएगी।
                    यह सुनकर बहिन समझ गयी ये सिलाएँ किसी और कि नही बल्कि उसके भाइयों के नाम कि हैं। उसने उस बुढ़िया से अपने सातवे भाई को बचाने का उपाय पूछा। बुढ़िया ने उसे बतला दिया कि वह अपने सातवे भाई को केसे बचा सकती है। सब जान कर वह वहाँ से अपने बॉल खुले कर के पागलों कि तरह अपने भाई को गालियाँ देती हुई चली।
                    भाई के पास आकर बोलने लगी: तू तो जलेगा, कुटेगा, मरेगा।
                    भाई उसके एसे व्यवहार को देखकर चोंक गया पर उसे कुछ समझ नही आया। इसी तरह दोनो भाई बहिन माँ के घर पहुँच गये। थोड़े समय के बाद भाई के लिए सगाई आने लगी। उसकी शादी तय हो गयी।
                    जब भाई को सहरा पहनाने लगे तो वह बोली: इसको क्यू सहरा बँधेगा, सहारा तो मैं पहनूँगी। ये तो जलेगा, मरेगा।
                    सब लोगों ने परेशान होकर सहरा बहिन को दे दिया। बहिन ने देखा उसमें कलंगी कि जगह साँप का बच्चा था।बहिन ने उसे निकाल के फैंक दिया।
                    अब जब भाई घोड़ी चढ़ने लगा तो बहिन फिर बोली: ये घोड़ी पर क्यू चढ़ेगा, घोड़ी पर तो मैं बैठूँगी, ये तो जलेगा, मरेगा, इसकी लाश को चील कौवे खाएँगे। सब लोग बहुत परेशान। सब ने उसे घोड़ी पर भी चढ़ने दिया।
                    अब जब बारात चलने को हुई तब बहिन बोली: ये क्यू दरवाजे से निकलेगा, ये तो पीछे के रास्ते से जाएगा, दरवाजे से तो मैं निकलूंगी। जब वह दरवाजे के नीचे से जा रही थी तो दरवाजा अचानक गिरने लगा। बहिन ने एक ईंट उठा कर अपनी चुनरी में रख ली, दरवाजा वही कि वही रुक गया। सब लोगों को बड़ा अचंभा हुआ।
                    रास्ते में एक जगह बारात रुकी तो भाई को पीपल के पेड़ के नीचे खड़ा कर दिया।
                    बहिन कहने लगी: ये क्यू छाव में खड़ा होगा, ये तो धूप में खड़ा होगा| छाँव में तो मैं खड़ी होगी।
                    जैसे ही वह पेड़ के नीचे खड़ी हुई, पेड़ गिरने लगा। बहिन ने एक पत्ता तोड़ कर अपनी चुनरी में रख लिया, पेड़ वही कि वही रुक गया।अब तो सबको विश्वास हो गया कि ये बावली कोई जादू टोना सिख कर आई है, जो बार बार अपने भाई कि रक्षा कर रही है। ऐसे करते करते फेरों का समय आ गया।
                    जब दुल्हन आई तो उसने दुल्हन के कान में कहा: अब तक तो मैने तेरे पति को बचा लिया, अब तू ही अपने पति को और साथ ही अपने मरे हुए जेठों को बचा सकती है।
                    फेरों के समय एक नाग आया, वो जैसे ही दूल्हे को डसने को हुआ , दुल्हन ने उसे एक लोटे में भर के उपर से प्लेट से बंद कर दिया। थोड़ी देर बाद नागिन लहर लहर करती आई।
                    दुल्हन से बोली: तू मेरा पति छोड़।
                    दुल्हन बोली: पहले तू मेरा पति छोड़।
                    नागिन ने कहा: ठीक है मैने तेरा पति छोड़ा।
                    दुल्हन: एसे नहीं, पहले तीन बार बोल।
                    नागिन ने 3 बार बोला, फिर बोली कि अब मेरे पति को छोड़।
                    दुल्हन बोली: एक मेरे पति से क्या होगा, हसने बोलने क लिए जेठ भी तो होना चाहिए, एक जेठ भी छोड़।
                    नागिन ने जेठ के भी प्राण दे ।
                    फिर दुल्हन ने कहा: एक जेठ से लड़ाई हो गयी तो एक और जेठ। वो विदेश चला गया तो तीसरा जेठ भी छोड़।
                    इस तरह एक एक करके दुल्हन ने अपने 6 जेठ जीवित करा लिए।
                    उधर रो रो के बुढ़िया का बुरा हाल था। कि अब तो मेरा सातवा बेटा भी बाकी बेटों कि तरह मर जाएगा। गाँव वालों ने उसे बताया कि उसके सात बेटा और बहुए आ रही है।
                    तो बुढ़िया बोली: गर यह बात सच हो तो मेरी आँखो कि रोशनी वापस आ जाए और मेरे सीने से दूध कि धार बहने लगे। ऐसा ही हुआ। अपने सारे बहू बेटों को देख कर वह बहुत खुश हुई।
                    बोली: यह सब तो मेरी बावली का किया है। कहाँ है मेरी बेटी?
                    सब बहिन को ढूँढने लगे। देखा तो वह भूसे कि कोठरी में सो रही थी। जब उसे पता चला कि उसका भाई सही सलामत है तो वह अपने घर को चली। उसके पीछे पीछे सारी लक्ष्मी भी जाने लगी।
                    बुढ़िया ने कहा: बेटी, पीछे मूड के देख! तू सारी लक्ष्मी ले जाएगी तो तेरे भाई भाभी क्या खाएँगे।
                    तब बहिन ने पीछे मुड़ के देखा और कहा: जो माँ ने अपने हाथों से दिया वह मेरे साथ चल, बाद बाकी का भाई भाभी के पास रहे। इस तरह एक बहिन ने अपने भाई की रक्षा की।
                    `,
        end:``
    }
]

const jitiya=[
    {
        title:`पहली कथा`,
        description:`एक बार एक गरुड़ और एक मादा लोमड़ी नर्मदा नदी के पास एक जंगल में रहते थे। दोनों ने कुछ महिलाओं को पूजा करते और उपवास करते देखा और खुद भी इसे देखने की कामना की। उनके उपवास के दौरान, लोमड़ी भूख के कारण बेहोश हो गई और चुपके से भोजन कर लिया। दूसरी ओर, चील ने पूरे समर्पण के साथ व्रत का पालन किया और उसे पूरा किया। परिणामस्वरूप लोमड़ी से पैदा हुए सभी बच्चे जन्म के कुछ दिन बाद ही खत्म हो गए और चील की संतान लंबी आयु के लिए धन्य हो गई।
                    इस कथा के अनुसार जीमूतवाहन गंधर्व के बुद्धिमान और राजा थे। जीमूतवाहन शासक बनने से संतुष्ट नहीं थे और परिणामस्वरूप उन्होंने अपने भाइयों को अपने राज्य की सभी जिम्मेदारियां दीं और अपने पिता की सेवा के लिए जंगल में चले गए। एक दिन जंगल में भटकते हुए उन्‍हें एक बुढ़िया विलाप करती हुई मिलती है। उन्‍होंने बुढ़िया से रोने का कारण पूछा। इसपर उसने उसे बताया कि वह सांप (नागवंशी) के परिवार से है और उसका एक ही बेटा है। एक शपथ के रूप में हर दिन एक सांप पक्षीराज गरुड़ को चढ़ाया जाता है और उस दिन उसके बेटे का नंबर था।
                    उसकी समस्या सुनने के बाद ज‍िमूतवाहन ने उन्‍हें आश्‍वासन द‍िया क‍ि वह उनके बेटे को जीव‍ित वापस लेकर आएंगे। तब वह खुद गरुड़ का चारा बनने का व‍िचार कर चट्टान पर लेट जाते हैं। तब गरुड़ आता है और अपनी अंगुलियों से लाल कपड़े से ढंके हुए जिमूतवाहन को पकड़कर चट्टान पर चढ़ जाता है। उसे हैरानी होती है क‍ि ज‍िसे उसने पकड़ा है वह कोई प्रति‍क्रिया क्‍यों नहीं दे रहा है। तब वह ज‍िमूतवाहन से उनके बारे में पूछता है। तब गरुड़ ज‍िमूतवाहन की वीरता और परोपकार से प्रसन्न होकर सांपों से कोई और बलिदान नहीं लेने का वादा करता है। मान्‍यता है क‍ि तभी से ही संतान की लंबी उम्र और कल्‍याण के ल‍िए ज‍ित‍िया व्रत मनाया जाता है।
                    `,
        end:``
    },
    {
        title:`दूसरी कथा`,
        description:`महाभारत युद्ध में अपने पिता गुरु द्रोणाचार्य की मृत्यु का बदला लेने की भावना से अश्वत्थामा पांडवों के शिविर में घुस गया। शिविर के अंदर पांच लोग को सोया पाए, अश्वत्थामा ने उन्हें पांडव समझकर मार दिया, परंतु वे द्रोपदी की पांच संतानें मारी गईं। उसके उपरांत अुर्जन ने अश्वत्थामा को बंदी बनाकर उसकी दिव्य मणि उसके माथे से निकाल ली।
                    अश्वत्थामा ने फिर से बदला लेने के लिए अभिमन्यु की पत्नी उत्तरा के गर्भ में पल रहे बच्चें को मारने का प्रयास किया और उसने ब्रह्मास्त्र से उत्तरा के गर्भ को नष्ट कर दिया। तब भगवान श्रीकृष्ण ने उत्तरा की अजन्मी संतान को फिर से जीवित कर दिया। गर्भ में मरने के बाद जीवित होने के कारण उस बच्चे को जीवित्पुत्रिका के नाम से भी जाना जाता है तब। उस समय से ही संतान की लंबी उम्र के लिए जितिया का व्रत रखा जाने लगा।
                    `,
        end:``
    }
]

export {
    satnarayanaKatha,
    vatSavitriKatha,
    vrihaspatiKatha,
    somvarKatha,
    mangalVrat,
    santoshiMataVrat,
    ekadashi,
    bhaiDuj,
    jitiya
}