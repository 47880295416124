import React from "react";
import { Text, Box } from '@chakra-ui/react';
import { bali, chaturLingatoHom, chetrapaalHom, kushkundika, navAhuti, navgrahDevHom, saptGhriMatrikaHom, sarvatoBhadraHome, sodasmatrikaHom, vastuDevHom, yoginiHom } from "../utils/havan";



export const HavanVidhi = () => {

    return <div style={{ minHeight: "95vh", paddingTop: '80px' }}>
        <Text fontSize='2xl' as={'b'} >|| हवन विधि ||</Text>
        <br />
        <br />
        <Text fontSize='xl' as={'b'} >-----पूर्वाङ्ग -----</Text>
        <br />
        <br />
        <Text fontSize='xl' >~ पञ्चभूसंस्कार ~</Text><br />

        <Box textAlign={"start"} w={['90%', '80%', '60%']} ml={['5%', '10%', '20%']}>
            <Text fontSize='md' >
                1. परिसमूह्य : कुशाओं से बुहार कर (गन्दगी- कचरा साफ करें)।तीन कुशाओं के परिसमूहन (सफाई) करके उसे  ईशानकोण में त्यागा  जाता है।
            </Text>
            <Text fontSize='md' >
                2. उपलिप्य : पूर्वसमय में इन्द्र ने वज्र से वृत्रासुर का वध किया था जिसके रक्त से पृथ्वी व्याप्त हो गई इसलिए गोमय से तीन बार उपलेपन करना चाहिए।
            </Text>

            <Text fontSize='md' >
                3. उल्लिख्य -  स्रुवा के मूल से उत्तरोत्तर तीन रेखा खींच कर (चिह्नित करें)
            </Text>
            <Text fontSize='md' >
                4.उद्धृत्य - अनामिका एवं अँगूठे से सभी रेखाओं से मिट्टी उठाकर ईशानकोण में ही त्याग करे
            </Text>
            <Text fontSize='md' >
                5.अभ्युक्ष्य - तीन बार जल पवित्र करें
            </Text>
        </Box>

        <br />
        <br />
        <Text fontSize='xl' >~ अग्नानयन व क्रव्यदांश त्याग ~</Text><br />
        <Box textAlign={"start"} w={['90%', '80%', '60%']} ml={['5%', '10%', '20%']}>
            <Text fontSize='md' >
                कांस्यपात्र या हस्तनिर्मित मृण्मयपात्र में अन्य पात्र से ढंकी हुई अग्नि मंगाकर अग्निकोण में रखवाए । ऊपर का पात्र हटाकर थोड़ी सी क्रव्यदांश अग्नि
                (ज्वलतृण) लेकर नैर्ऋत्यकोण में त्याग कर जल से बुझा दे ।।
            </Text>
        </Box>

        <br />
        <br />
        <Text fontSize='xl' >~ अग्निस्थापन ~</Text><br />
        <Box textAlign={"start"} w={['90%', '80%', '60%']} ml={['5%', '10%', '20%']}>
            <Text fontSize='md'  >ॐ अग्निं दूतं पुरोदधे हव्यवाहमुपब्रुवे । देवां२ आसादयादिह ॥ अग्नानयन पात्र में अक्षत-जल छिरके।</Text><br />
        </Box>
        <br />
        <br />
        <Text fontSize='xl' >~ कुशकण्डिका ~</Text><br />

        <Box textAlign={"start"} w={['90%', '80%', '60%']} ml={['5%', '10%', '20%']}>
            <Text fontSize='md' >
                कुश पवित्रता और प्रखरता के प्रतीक माने जाते हैं। कुशकण्डिका के अन्तर्गत निर्धारित क्षेत्र के चारों दिशाओं में कुश बिछाये जाते हैं। बड़े यज्ञों और विशिष्ट कर्मकाण्डों में यज्ञशाला, यज्ञकुण्ड अथवा पूजा क्षेत्र के चारों ओर मन्त्रों के साथ कुश स्थापित किये जाते हैं
                क्रम व्यवस्था- कुश कण्डिका में प्रत्येक दिशा के लिए चार- चार कुश लिये जाते हैं। पूरे क्षेत्र को इकाई मानकर उसके चारों ओर एक ही व्यक्ति से कुश स्थापित कराने हैं, तो कुल १६ कुशाएँ चाहिए। यदि प्रत्येक कुण्ड या वेदी पर कराना है, तो प्रत्येक के लिए १६- १६ कुशाएँ चाहिए।
                क्रिया और भावना- कुश स्थापना करने वाले व्यक्ति एक बार में चार कुश हाथ में लें। मन्त्रोच्चार के साथ कुशाओं सहित उस दिशा में हाथ जोड़कर मस्तक झुकाएँ और एक- एक करके चारों कुशाएँ उसी दिशा में स्थापित कर दें। कुश स्थापित करते समय कुश का ऊपरी नुकीला भाग पूर्व
                या उत्तर की ओर रहे तथा मूल (जड़) भाग पश्चिम या दक्षिण की ओर रहे। प्रत्येक मन्त्र के साथ दिशा विशेष के लिए यही क्रम अपनाया जाए। भावना की जाए कि इस दिशा में व्याप्त देवशक्तियों को नमस्कार करते हुए उनके सहयोग से दिव्य प्रयोजन के लिए कुशाओं जैसी पवित्रता और प्रखरता का जागरण और स्थापन किया जा रहा है।
            </Text>
        </Box>
        <br />
        <br />

        {
            kushkundika.map((el, i) => {
                return <Box key={i} w={['100%', '100%', '70%']} ml={['0', '0', '15%']}>
                    <Text fontSize='md' >{el.title}</Text><br />
                    <Text fontSize='xl' >{el.des1}</Text><br />
                    <br />
                    <br />
                </Box>
            })
        }

        <Text fontSize='md' as={'b'} >~ अग्निका ध्यान तथा पूजन ~</Text><br /><br />
        <Text fontSize='xl' as={'b'} >
            अग्नि प्रज़्वलितं वन्दे जातवेदं हुताशनम् । सुवर्णवर्णममलं समिध्दं सर्वतोमुखम्   ॥
        </Text><br />
        <Text fontSize='xl' as={'b'} >(ॐ बलवर्धननामाग्नयेनमः - मंत्र द्वारा गन्ध पुष्प आदि से पूजा करे)</Text><br /><br />
        <br />
        <br />

        <Text fontSize='2xl' as={'b'} > ~ होम ~</Text>
        <br />
        <br />
        <Text fontSize='md' as={'b'} > - प्रजापति देवता होम- </Text><br /><br />
        <Text fontSize='xl' as={'b'} >
            ॐ प्रजापतये स्वाहा, इदं प्रजापतये न  मम । <br />
            ॐ इन्द्राय स्वाहा, इदं इन्द्राये न मम । <br />
            ॐ अग्नये स्वाहा, इदं अग्नये न मम । <br />
            ॐ सोमाय स्वाहा, इदं सोमायन मम । <br />
            ॐ भूः स्वाहा, इदं भूः॥ <br />
            ॐ भुवः स्वाहा, इदं भुवः॥ <br />
            ॐ स्वः स्वहा, इदं स्वः ॥ <br />
        </Text>
        <br />
        <br />

        <Text fontSize='md' as={'b'} > - द्रव्यत्याग - (होता के हाँथमे जल देकर निम्न मंत्र से जल छोड़वाएं ) </Text><br /><br />
        <Text fontSize='xl' as={'b'} > अस्मिन् होमकर्मणि याः याः यक्षमाणदेवता: ताभ्य: ताभ्य: ईदं हवानीयद्रव्यं मया परित्यक्तं तत्सद्यथादैवतमस्तु न मम  </Text><br /><br />

        <Text fontSize='sm' as={'b'} > - वराहुति होम - </Text><br /><br />
        <Text fontSize='xl' as={'b'} >
            ॐ गणानांत्वा गणपति गुंग हवामहे प्रियाणां त्वा प्रियपति  गुंग हवामहे, निधीनां त्वा निधिपति गुंग हवामहे। वसो: मम आहमजानि गर्भधम् त्वमजासि गर्भधम् ॥ स्वाहा ॥ <br />
            ॐ अम्बे अम्बिके अम्बालिके नमानयति कश्चन । ससत्स्यश्वकः सुभद्रिकां काम्पीलवासिनीं॥ स्वाहा ॥
        </Text><br /><br />

        <Text fontSize='md' as={'b'} > - नवग्रह होम - </Text><br /><br />
        {
            navgrahDevHom.map((el, i) => {
                return <Box key={i} w={['100%', '100%', '70%']} ml={['0', '0', '15%']}>
                    <Text fontSize='md' as={'b'}>{el.title}</Text><br />
                    <Text fontSize='xl' as={'b'}>{el.des1}</Text><br />
                    <br />
                    <br />
                </Box>
            })
        }

        <br />
        <br />
        <Text fontSize='md' as={'b'} >~ अधिदेवता होम ~</Text><br /> <br />

        <Box textAlign={"start"} w={['90%', '80%', '60%']} ml={['5%', '10%', '20%']}>
            <Text fontSize='xl' as={'b'} >
                ॐ ईश्वराय स्वाहा, इदं ईश्वराय। ॐ उमायै स्वाहा, इदं उमायै। ॐ स्कन्दाय स्वाहा, इदं स्कन्दाय। ॐ विष्णवे स्वाहा, इदं विष्णवे ।
                ॐ ब्रह्मणे स्वाहा, इदं ब्रह्मणे (मानसिक)। ॐ इन्द्राय स्वाहा, इदं इन्द्राय । ॐ यमाय स्वाहा, इदं यमाय । ॐ कालाय स्वाहा, इदं कालाय।
                ॐ चित्रगुप्ताय स्वाहा, इदं चित्रगुप्ताय।
            </Text>
        </Box>

        <br />
        <br />
        <Text fontSize='md' as={'b'} >~ प्रत्यधिदेवता होम ~</Text><br /> <br />

        <Box textAlign={"start"} w={['90%', '80%', '60%']} ml={['5%', '10%', '20%']}>
            <Text fontSize='xl' as={'b'} >
                ॐ अग्नये स्वाहा, इदं अग्नये। ॐ अद्भ्यो स्वाहा, इदं अद्भ्यः। ॐ पृथिव्यै स्वाहा, इदं पृथिव्यै। ॐ विष्णवे स्वाहा, इदं विष्णवे। ॐ इन्द्राय स्वाहा, इदं इन्द्राय।
                ॐ इन्द्राण्यै स्वाहा, इदं इन्द्राण्यै। ॐ प्रजापतये स्वाहा, इदं प्रजापतये (मानसिक)। ॐ सर्पेभ्यो स्वाहा, इदं सर्पेभ्यः। ॐ ब्रह्मणे स्वाहा, इदं ब्रह्मणे (मानसिक)।
                पञ्चलोकपाल होम : ॐ गणपतये स्वाहा, इदं गणपतये । ॐ दुर्गायै स्वाहा, इदं दुर्गायै। ॐ वायवे स्वाहा, इदं वायवे। ॐ आकाशाय स्वाहा, इदं आकाशाय।
                ॐ अश्विभ्यां स्वाहा, इदं अश्विभ्यां।

            </Text>
        </Box>

        <br />
        <br />
        <Text fontSize='xl' as={'b'} >~ पञ्चलोकपाल होम  ~</Text><br /> <br />

        <Box textAlign={"start"} w={['90%', '80%', '60%']} ml={['5%', '10%', '20%']}>
            <Text fontSize='xl' as={'b'} >
                ॐ गणपतये स्वाहा, इदं गणपतये । ॐ दुर्गायै स्वाहा, इदं दुर्गायै। ॐ वायवे स्वाहा, इदं वायवे। ॐ आकाशाय स्वाहा, इदं आकाशाय।
                ॐ अश्विभ्यां स्वाहा, इदं अश्विभ्यां।
            </Text>
        </Box>
        <br />
        <br />
        <br />
        <Text fontSize='xl' as={'b'} >~ वास्तुदेवताहोम  ~</Text><br /> <br />
        <br />
        {
            vastuDevHom.map((el, i) => {
                return <Box key={i} w={['100%', '100%', '70%']} ml={['0', '0', '15%']}>
                    <Text fontSize='xl' as={'b'}>{el.des2}</Text><br /><br />
                </Box>
            })
        }
        <br />
        <br />
        <Text fontSize='xl' as={'b'} >~ सर्वतोभद्र देवता होम  ~</Text><br /> <br />
        {
            sarvatoBhadraHome.map((el, i) => {
                return <Box key={i} w={['100%', '100%', '70%']} ml={['0', '0', '15%']}>
                    <Text fontSize='xl' as={'b'}>{el.des2}</Text><br /><br />
                </Box>
            })
        }
        <br />
        <br />
        <Text fontSize='xl' as={'b'} >~ लिङ्गतोभद्र देवता होम  ~</Text>
        <br />
        <br />
        {
            chaturLingatoHom.map((el, i) => {
                return <Box key={i} w={['100%', '100%', '70%']} ml={['0', '0', '15%']}>
                    <Text fontSize='xl' as={'b'}>{el.des2}</Text><br /><br />
                </Box>
            })
        }

        <br />
        <br />
        <Text fontSize='xl' as={'b'} >~ योगिनी होम  ~</Text>
        <br />
        <br />
        {
            yoginiHom.map((el, i) => {
                return <Box key={i} w={['100%', '100%', '70%']} ml={['0', '0', '15%']}>
                    <Text fontSize='xl' as={'b'}>{el.des2}</Text><br /><br />
                </Box>
            })
        }

        <br />
        <br />
        <Text fontSize='xl' as={'b'} >~ षोडशमातृका होम  ~</Text>
        <br />
        <br />
        {
            sodasmatrikaHom.map((el, i) => {
                return <Box key={i} w={['100%', '100%', '70%']} ml={['0', '0', '15%']}>
                    <Text fontSize='xl' as={'b'}>{el.des2}</Text><br /><br />
                </Box>
            })
        }

        <br />
        <br />
        <Text fontSize='xl' as={'b'} >~ सप्तघृतमातृका होम  ~</Text>
        <br />
        <br />
        {
            saptGhriMatrikaHom.map((el, i) => {
                return <Box key={i} w={['100%', '100%', '70%']} ml={['0', '0', '15%']}>
                    <Text fontSize='xl' as={'b'}>{el.des2}</Text><br /><br />
                </Box>
            })
        }

        <br />
        <br />
        <Text fontSize='xl' as={'b'} >~ क्षेत्रपाल होम  ~</Text>
        <br />
        <br />
        {
            chetrapaalHom.map((el, i) => {
                return <Box key={i} w={['100%', '100%', '70%']} ml={['0', '0', '15%']}>
                    <Text fontSize='xl' as={'b'}>{el.des2}</Text><br /><br />
                </Box>
            })
        }


        <br />
        <Text fontSize='xl' as={'b'} >----- मध्याङ्ग -----</Text>
        <br />
        <br />
        <Text fontSize='xl'  >
            तत्पश्चात मुख्य देवता की निश्चित संख्या में देवता के मंत्र द्वारा विहित द्रव्य से आहूति दे।(ॐ नमो  भगवाते वसुदेवाय )</Text>
        <br />
        <br />

        <Text fontSize='xl' as={'b'} >----- उत्तराङ्ग -----</Text> <br />
        <Text fontSize='xl'  >
            प्रधान हवन के अनन्तर हवन की सफलता की सिद्धि के लिये निम्न मंत्र से अग्नि देव का गन्ध आदि से उत्तर-पूजन करे <br />
            ॐ स्वाहास्वधायुताय बलवर्धननामाग्नये नमः
        </Text>
        <br />
        <br />

        <Text fontSize='md' as={'b'} >- प्रार्थना -</Text> <br />
        <Text fontSize='xl' as={'b'} >
            श्रध्दां मेधां यशः प्रज्ञां विधां बुद्धि बलं श्रियम् । आयुष्यं द्रव्यमारोग्यं देहि मे हव्यवाहन ॥
        </Text>
        <br />

        <Text fontSize='md' as={'b'} >- अग्नि धरण - (हाँथ से निम्न मंत्र द्वारा अग्नि देव को सम्पूर्ण शरीर मे धरण करे ) </Text> <br />
        <Text fontSize='xl' as={'b'} >
            ॐ अङ्गानि च मा आप्यायन्ताम्
        </Text>
        <br />

        <br />

        <Text fontSize='md' as={'b'} >- स्विष्टकृद्धोम  - </Text> <br />
        <Text fontSize='xl' as={'b'} >
            (शेष घृत प्रोक्षणी मे डाले ) <br /> ॐ अग्नये स्विष्टकृते स्वाहा, इदं अग्नयेस्विष्टकृते न मम।
        </Text>
        <br />
        <br />
        <br />
        <br />
        <Text fontSize='md' as={'b'} >- भू : आदि नव आहुतियाँ -</Text>
        <br />
        <br />
        {
            navAhuti.map((el, i) => {
                return <Box key={i} w={['100%', '100%', '70%']} ml={['0', '0', '15%']}>
                    <Text fontSize='xl' as={'b'}>{el.des2}</Text><br /><br />
                </Box>
            })
        }
        <br />
        <br />
        <Text fontSize='2xl' as={'b'} >~ बलिदान ~</Text>
        <br />
        <br />
        {
            bali.map((el, i) => {
                return <Box key={i} w={['100%', '100%', '70%']} ml={['0', '0', '15%']}>
                    <Text fontSize='md' as={'b'}>{el.title}</Text><br /><br />
                    <Text fontSize='xl' as={'b'}>{el.des}</Text><br /><br />
                </Box>
            })
        }

        <Text fontSize='xl' as={'b'} >~ क्षेत्रपाल बलि ~</Text>
        <br />
        <br />
        <Box w={['100%', '100%', '70%']} ml={['0', '0', '15%']}>
            <Text fontSize='md' as={'b'} >- संकल्प -</Text>
            <br />
            <br />
            <Text fontSize='xl' as={'b'} >
                देशकालौ सङ्गीर्त्य, ...अमुकगोत्रोंऽमुकशर्माहम् सपत्नीकोऽहं अस्य ...अमुकयागकर्मणः साङ्गता सिद्धयर्थं  क्षेत्रपालादिप्रीत्यर्थं भूत-प्रेत पिशाचादिनिवृत्यर्थं च सार्वभौतिकबलिप्रदानं कारिष्ये ।
            </Text>
            <br />
            <br />
            <Text fontSize='md' as={'b'} >- आवाहन -</Text>
            <br />
            <Text fontSize='md' >
                (एक मिट्टी का बडा दीपक लेकर उसमें चार मुंह की ज्योत लगावें । दीपक में सरसों का तेल डालें .
                उसमें सिन्दूर , उडद , पापड , दही , गुड , सुपारी आदिरखकर दीप प्रज्वलित करें और क्षेत्रपाल का आवाहन करें ।)
            </Text>
            <br />
            <Text fontSize='xl' as={'b'}>ॐ नहि स्पशमविदन्नन्यमस्माद्वैश्र्वानरात्पुरएतारमग्ने:। एमेनमवृधन्नमृता अमर्त्यं वैश्र्वानरं क्षैत्रजित्याय देवा:।।</Text><br /><br />
            <br />
            <Text fontSize='md' as={'b'} >क्षेत्रपाल पूजा - ॐ क्षेत्रपालाय नमः (मंत्र से गन्ध पुष्प से पूजा करे)</Text>
            <br />
            <br />
            <Text fontSize='md' as={'b'} >- प्रार्थना -</Text>
            <br />
            <Text fontSize='xl' as={'b'} >
                नमो वै क्षेत्रपालस्त्वं भूतप्रेतगणै: सह ।  पुजां बलिं गृहाणेमं सौम्यो भव च सर्वदा ॥
                पुत्रान् देहि धनं देहि सर्वान् कामांश्र्च  देहि मे । आयुरारोग्यं  मे देहि निर्विघ्नं कुरु सर्वदा ॥
            </Text>
            <br />
            <br />
            <Text fontSize='md' as={'b'} >- बलि -</Text>
            <br />
            <Text fontSize='xl' as={'b'} >
                क्षेत्रपालाय साङ्गाय सपरिवाराय सायुधाय सशक्तिकाय मारीगण-भैरव-राक्षस-कूष्माण्ड-वेताल -भूत-प्रेत-पिशाच-डाकिनी-शाकिनी-पिशाचिनी-ब्रह्मराक्षस-गणसहिताय इमं कुङ्कुमरक्तपुष्पादियुतं सदीपं सताम्बूलं सदक्षिणं दधिमाष-भक्तबलिं समर्पयामि । भो क्षेत्रपाल !
                सर्वतो दिशं रक्ष बलिं भक्ष मम सकुटुम्बस्य सपरिवारस्य आयु: कर्ता  क्षेमकर्ता शान्तिकर्ता पुष्टिकर्ता वरदो भव। अनेन बलिदानेन  क्षेत्रपाल: प्रियन्ताम् ।
            </Text>
            <Text fontSize='md' >
                (अब इस दीपक को उठाकर यजमान की तरफ आवृत कर बिना पीछे मुडे बाहर दीपक को चौराहे पर रखावें । ब्राह्मण शांतिपाठ करें । ब्रह्मा जी द्वार तक जल छोडें दीपक को रखकर आने वाला व्यक्ति नहाकर या हाथ पैर धोकर आवे ।)
            </Text>

            <br />
            <br />
            <Text fontSize='md' as={'b'} >- वसोर्धारा -</Text>
            <Text fontSize='md' >(स्रुचि से घृत की अविच्छन्न धारा अग्नि में इस मंत्र से करे)</Text>
            <br />
            <Text fontSize='xl' as={'b'} >
                ॐ वसोः पवित्रमसि शतधारं वसोः पवित्रमसि सहस्रधारम्। देवस्त्वा सविता पुनातु वसोः पवित्रेण शतधारेण सुप्वा कामधुक्षः स्वाहा ॥
            </Text>

            <br />
            <br />
            <Text fontSize='md' as={'b'} >- अग्निप्रार्थना -</Text>
            <br />
            <Text fontSize='xl' as={'b'} >
                ॐ श्रद्धां मेधां यशः प्रज्ञां विद्यां पुष्टिं श्रियं बलम् । तेज आयुष्यमारोग्यं देहि मे हव्यवाहन ॥ १ ॥ भो भो अग्ने ! 
                महाशक्ते सर्वकर्मप्रसाधन । कर्मान्तरेऽपि सम्प्राप्ते सान्निध्यं कुरु सर्वदा ॥
            </Text>

            <br />
            <br />
            <Text fontSize='md' as={'b'} >- भस्म धारण -</Text>
            <br />
            <Text fontSize='xl' as={'b'} >
                ॐ त्र्यायुषम्जमदग्नेः (ललाट पर) । ॐ कश्यपस्य त्र्यायुषं (कंठ में) । ॐ यद्देवेषु त्र्यायुषं (दक्षिण बाहु पर) । 
                ॐ तन्नोऽअस्तु त्र्यायुषं (हृदय में) यदि किसी अन्य को लगाये तो ॐ तत्तेऽअस्तु त्र्यायुषं। और फिर बांये बांह पर भी लगा ले ।
            </Text>

            <br />
            <br />
            <Text fontSize='md' as={'b'} >- संसव प्राशन -</Text>
            <br />
            <Text fontSize='md' >(प्रोक्षणी में प्रक्षिप्त आहुति अवशेष आज्याहुति का अनामिका और अंगुष्ठ से प्राशन करे)</Text>
            <br />
            <Text fontSize='xl' as={'b'} >
                ॐ यस्माद्यज्ञपुरोडाशाद्यज्वानो ब्रह्मरूपिणः । तं संस्रवपुरोडाशं प्राश्नामि सुखपुण्यदम् ।।
            </Text>

            <br />
            <br />
            <Text fontSize='md' as={'b'} >- पूर्णपात्र -</Text>
            <br />
            <br />
            <Text fontSize='xl' as={'b'} >
                ॐ अद्य कृतैतत् ……. होमकर्मणि कृताकृतावेक्षणरूप ब्रह्मकर्मप्रतिष्ठार्थमिदं पूर्णपात्रं प्रजापति दैवतम् …..गोत्राय ……शर्मणे ब्राह्मणाय ब्रह्मणे दक्षिणां तुभ्यमहं सम्प्रददे । ब्रह्मा को पूर्णपात्र प्रदान करे, ब्रह्मा ॐ स्वस्ति (कहकर ग्रहण करे । फिर दाहिना हाथ पकरकर ब्रह्मा को प्रदक्षिणक्रम से उठाए ।)
            </Text>
            <Text fontSize='md' >(कहकर ग्रहण करे । फिर दाहिना हाथ पकरकर ब्रह्मा को प्रदक्षिणक्रम से उठाए ।)</Text>
            <br />
            <br />
            <Text fontSize='md' as={'b'} >- तर्पण -</Text>
            <br />
            <Text fontSize='xl' as={'b'} >
                ॐ साङ्गं सपरिवारं (महामृत्युञ्जयं/विष्णु/शिवं देवतानुसार) तर्पयामि ॥
            </Text>
            <br />
            <br />
            <Text fontSize='md' as={'b'} >- मार्जन -</Text>
            <br />
            <Text fontSize='xl' as={'b'} >
                ॐ मार्जयामि ॥ सिर का मार्जन करे।
            </Text>
            <br />
            <br />
            <Text fontSize='md' as={'b'} >- विसर्जन -</Text>
            <br />
            <Text fontSize='xl' as={'b'} >
                ॐ यान्तु देवगणा: सर्वे पूजामादाय मामकीम्। इष्टकामसमृद्धयर्थं पुनर्अपि पुनरागमनाय च॥ <br/>
                गच्छ गच्छ सुरश्रेष्ठ स्वस्थाने परमेश्वर। यत्र ब्रम्हादयो देवास्तत्र गच्छ हुताशन ॥   
            </Text>

            <br />
            <br />
            <Text fontSize='md' as={'b'} >- छायापात्र दान -</Text>
            <Text fontSize='md' >तिलपुञ्ज पर छायापात्र (कांस्यपात्र) स्थापित करके गोघृत (द्रवित) भरे फिर उसमें मुखावलोकन करे</Text>
            <br />
            <Text fontSize='xl' as={'b'} >
                ॐ आज्यं सुराणामाहारमाज्यं पापहरं परम् । आज्यमध्ये मुखं दृष्ट्वा सर्वपापैः प्रमुच्यते ॥ घृतं नाशयते व्याधि घृतञ्च हरते रुजम् । घृतं तेजोऽधिकरणं घृतमायुः प्रवर्द्धते ॥ इस प्रकार मुखावलोकन करके स्वर्ण या पञ्चरत्न प्रक्षेप करे । छायापात्र पर ३ बार पुष्पाक्षत छिड़के – ॐ छायापात्राय नमः ॥३॥ फिर ३ बार उत्तराग्र कुशा (त्रिकुशा) पर छिड़के – ॐ ब्राह्मणाय नमः ।।३।। जल से सिक्त करे। तिल-जल लेकर उत्सर्ग करे – ॐ अद्य …….. गोत्रोत्पन्नः ……… शर्माऽहं आयुरारोग्यप्राप्त्यर्थं श्री …….. देवताप्रीत्यर्थं च इदं स्वछायावलोकितंघृतपूरितंकांस्यपात्रं चन्द्रप्रजापतिबृहस्पति दैवतं यथानाम गोत्राय ब्राह्मणाय अहं ददे॥ पूर्वपूजित त्रिकुशा पर छोड़ दे । 
                ॐ अद्य कृतैतच्छायापात्र दान प्रतिष्ठार्थं एतावद्द्रव्यमूल्यकहिरण्यं अग्निदैवतं यथानामगोत्रायब्राह्मणाय दक्षिणां दातुमहमुत्सृजे ॥
            </Text>
            <br />
            <br />
            <Text fontSize='md' as={'b'} >- दक्षिणा -</Text><br />
            <Text fontSize='xl' as={'b'}>ॐ अद्य कृतैतच्छायापात्र दान प्रतिष्ठार्थं एतावद्द्रव्यमूल्यकहिरण्यं अग्निदैवतं यथानामगोत्रायब्राह्मणाय दक्षिणां दातुमहमुत्सृजे ॥</Text>
            <br />
            <br />
            <Text fontSize='md' as={'b'} >- क्षमाप्रार्थना -</Text><br />
            <Text fontSize='xl' as={'b'}>
                मंत्रहीनं क्रियाहीनं भक्तिहीनं जनार्दन । यत्पूजितं मया देव परिपूर्णं  तदस्तु मे ॥ 
                नमो ब्रह्मण्देवाय गोब्राह्मणहिताय च । जगद्धिताय कृष्णाय गोविन्दाय नमो नम: ॥ 
                यदक्षरपदभ्रष्टं मात्राहीनं च यद्भवेत् । तत्सर्वं क्षम्यतां देव प्रसीद परमेश्वर ॥     
            </Text>
            <br />
        </Box>
        <br />
        <br />
        <br />
    </div>
}