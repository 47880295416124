import React from "react";
import { Box, Text } from '@chakra-ui/react';

export const PunyVachan = () => {

    return <>
        <div style={{ minHeight: "95vh",paddingTop:'80px' }}>
            <Text fontSize='2xl' as={'b'} >|| पुण्याहवाचन ||</Text>
            <br />
            <br />
            <Box p={"0 5% 0 5%"}>
                <Text fontSize='sm' as={'b'} >- विधि -</Text><br />
                <Text fontSize='md'  >
                    सपत्नीक यजमान पूर्वाभिमुख बैठे और युग्म ब्राह्मण (न्यूनतम २) उत्तराभिमुख बैठें। सर्वप्रथम दूर्वाक्षत आदि वस्तुओं से भूमिपूजन कर ले ।
                    कलशस्थापन-पूजन के बाद कलश के निकट में ही एक अन्य धातुकलश जलयुक्त स्थापित करके उसमें भी अन्य मंगल द्रव्य देकर उसकी पूजा कर ले।
                    कलश के दोनों ओर कुशदूर्वा का आसन देकर एक-एक कटोरी रखे, जिसमें पुण्याहवाचन करते समय जल गिराना होगा।दांयी कटोरी का आकार बांयीं
                    की तुलना में कुछ बड़ा रखे। दांयी कटोरी में पुण्य-जल और बांयीं कटोरी में पापजल को गिराना होता है। फिर अक्षतपुष्पादि लेकर वरुण की प्रार्थना करे <br /><br />
                    वरुण प्रार्थना - ॐ पाशपाणे नमस्तुभ्यं पद्मिनीजीवनायक। पुण्याहवाचनं यावत् तावत् त्वं सुस्थिरो भव ॥ <br /><br />
                    यजमान घुटने टेककर बैठ जाये फिर पुष्पाक्षत आदि लेकर हाथों को जोड़कर कमल की कोढ़ी के आकार का बनाकर सिर में सटाते हुए तीन बार प्रणाम करे।
                    फिर आचार्य दाहिने हाथ से धातुकलश उठाकर यजमान के अञ्जलि में दे। फिर इन मंत्रों को यजमान पढ़े और ब्राह्मण प्रत्युत्तर वचन दे।
                </Text>
                <br />
                <br />
                <br />
                <Text fontSize='sm' as={'b'} >- यजमान -</Text><br />
                <Text fontSize='xl' as={'b'} >
                    ॐ दीर्घा नागा नद्यो गिरयस्त्रीणि विष्णुपदानि च। ॐ त्रीणि पदा विचक्रमे विष्णुर्गोपा अदाभ्यः। अतो धर्माणि धारयन् । <br />
                    तेनायुष्यप्रमाणेन पुण्यं पुण्याहं दीर्घमायुरस्तु , इति भवन्तो ब्रुवन्तु ॥ (यजमान दीर्घायु होने का आशीर्वाद मांगे ।)
                </Text>
                <br />
                <br />
                <br />

                <Text fontSize='sm' as={'b'} >- ब्राह्मण -</Text><br />
                <Text fontSize='xl' as={'b'} >
                    पुण्यं पुण्याहं दीर्घमायुरस्तु ॥
                </Text>
                <br />
                <Text fontSize='sm' >(सभी ब्राह्मण ये आशीर्वाद दें । और यजमान कलश को सिर से लगाये, पत्नी के सिर से भी लगाये,
                    यदि उपनयन, विवाहादि हो तो बच्चे के सिर से भी लगाकर कलश को यथास्थान रख दें ।)</Text><br />
                <br />
                <br />

                <Text fontSize='md' >(यजमान आचार्च के हाथों में क्रमशः जल, पुष्प, अक्षत, चन्दन, पान, सुपारी, द्रव्य आदि दे और ब्राह्मण क्रमशः स्वीकारोक्ति वचन दे)</Text><br />
                <Text fontSize='xl' as={'b'} >
                    जल : (यजमान) - ॐ शिवा आपः सन्तु ॥ <br /> ब्राह्मण - सन्तु शिवा आपः ॥<br /><br />
                    पुष्प : (यजमान) - ॐ सौमनस्यमस्तु ॥<br /> ब्राह्मण - अस्तु सौमनस्यम् ॥<br /><br />
                    अक्षत : (यजमान) - ॐ अक्षतं चारिष्टं चास्तु ॥<br /> ब्राह्मण - अस्त्वक्षतमरिष्टं च।<br /><br />
                    चंदन : (यजमान) - ॐ गन्धाःपान्तु ॥<br /> ब्राह्मण - सौमङ्गल्यं चास्तु ॥<br /><br />
                    अक्षत : (यजमान) - ॐ अक्षताः पान्तु ॥<br /> ब्राह्मण - आयुष्यमस्तु ॥<br /><br />
                    पुष्प : (यजमान) - ॐ पुष्पाणि पान्तु ॥<br /> ब्राह्मण - सौश्रियमस्तु ॥<br /><br />
                    लगा हुआ पान : (यजमान) - ॐ सफलानि ताम्बूलानि पान्तु ॥<br />ब्राह्मण - ऐश्वर्यमस्तु ॥<br /><br />
                    द्रव्य : (यजमान) - ॐ दक्षिणाः पान्तु ॥<br /> ब्राह्मण - बहुधनञ्चास्तु ॥<br /><br />
                    जल : (यजमान) - ॐ स्वर्चितमस्तु ॥<br /> ब्राह्मण - अस्तु स्वर्चितम् ॥<br /><br />
                </Text>
                <br />
                <br />
                <br />

                <Text fontSize='sm' as={'b'} >यजमान - (हाथ जोड़कर प्रार्थना करे) – </Text><br />
                <Text fontSize='xl' as={'b'} >दीर्घमायुः शान्तिः पुष्टिस्तुष्टिः श्रीर्यशो विद्या विनयो वित्तं बहुपुत्रं बहुधनं चायुष्यं चास्तु ॥</Text>
                <br />
                <br />
                <br />

                <Text fontSize='sm' as={'b'} >ब्राह्मण – (यजमान के सिर पर कलश का जल छिड़कते हुए आशीर्वचन कहें)</Text><br />
                <Text fontSize='xl' as={'b'} >ॐ दीर्घमायुः शान्तिः पुष्टिस्तुष्टिश्चास्तु ॥ </Text>
                <br />
                <br />
                <br />

                <Text fontSize='sm' as={'b'} >यजमान – (पुनः अक्षत लेकर प्रार्थना करे)</Text><br />
                <Text fontSize='xl' as={'b'} >
                    यं कृत्वा सर्ववेदयज्ञक्रियाकरणकर्मारम्भाः शुभाः शोभनाः प्रवर्तन्ते, <br />
                    तमहमोङ्कारमादिं कृत्वा ऋग्यजुः सामाथर्वाशीर्वचनं बहुऋषिमतं <br />
                    समनुज्ञातं भवद्भिरनुज्ञातः पुण्यं पुण्याहं वाचयिष्ये ॥
                </Text>
                <br />
                <br />
                <br />

                <Text fontSize='sm' as={'b'} > ब्राह्मण - (मन्त्रों का वाचन करे)</Text><br />
                <Text fontSize='xl' as={'b'} >
                    ॐ वाच्यताम् <br />
                    ॐ द्रविणोदाः पिपीषति जुहोत प्र च तिष्ठत।नेष्ट्रादृतुभिरिष्यत ॥ <br />
                    सविता त्वा सवाना ᳪ सुवतामग्निर्गृहपतीना ᳪ सोमो वनस्पतीनाम्।<br />
                    बृहस्पतिर्वाच इन्द्रो ज्यैष्ठ्याय रुद्रः पशुभ्यो मित्रः सत्यो वरुणो धर्मपतीनाम् ॥ <br />
                    ॐ न तद्रक्षा ᳪ सि न पिशाचास्तरन्ति देवानामोजः प्रथमज ᳪ ह्येतत्। <br />
                    यो बिभर्ति दाक्षायण ᳪ हिरण्य ᳪ स देवेषु कृणुते दीर्घमायुः स मनुष्येषु कृणुते दीर्घमायुः॥ <br />
                    उच्चा ते जातमन्धसो दिवि सद्भूम्या ददे।उग्र ᳪ शर्म महि श्रवः। <br />
                    उपास्मै गायता नरः पवमानायेन्दवे।अभि देवाँ देवाँ इयक्षते ॥
                </Text>
                <br />
                <br />
                <br />


                <Text fontSize='xl' as={'b'} >
                    यजमान – ॐ व्रतजपनियमतपः स्वाध्याय क्रतु शम दम दया दान विशिष्टानां सर्वेषां भवतां ब्राह्मणानां मनः समाधीयताम् ॥<br />
                    ब्राह्मण – ॐ समाहितमनसः स्मः ॥<br />
                    यजमान – ॐ प्रसीदन्तु भवन्तः॥<br />
                    ब्राह्मण – ॐ प्रसन्नाः स्मः॥
                </Text>
                <br />
                <br />
                <br />
                <Text fontSize='md' >
                    हाथों में लिया हुआ अक्षतादि कलश पर अर्पित करके कलश को पुनः हाथों में ले ।
                    पुण्याहवाचनकलश के दायें-बायें रखे दे जलपात्रों में निर्देशानुसार आम्र पल्लव या दूर्वा से,
                    थोड़ा-थोड़ा जल दे, और ब्राह्मण मन्त्र बोलते जायें
                </Text><br />


                <Text fontSize='sm' as={'b'} >दाहिने पात्र में -</Text><br />
                <Text fontSize='xl' as={'b'} >
                    ॐ शान्तिरस्तु। ॐ पुष्टिरस्तु। ॐ तुष्टिरस्तु। ॐ वृद्धिरस्तु। ॐ अविघ्नमस्तु। ॐ आयुष्यमस्तु। ॐ आरोग्यमस्तु।ॐ शिवमस्तु। <br />
                    ॐ शिवं कर्मास्तु। ॐ कर्मसमृद्धिरस्तु। ॐ वेदसमृद्धिरस्तु ॥ ॐ शास्त्रसमृद्धिरस्तु ॥ ॐधनधान्यसमृद्धिरस्तु। ॐ पुत्रपौत्रसमृद्धिरस्तु। ॐ इष्टसम्पदस्तु॥
                </Text>
                <br />
                <br />
                <br />

                <Text fontSize='sm' as={'b'} >बायें पात्र में -</Text><br />
                <Text fontSize='xl' as={'b'} >
                    ॐ अरिष्टनिरसनमस्तु । ॐ यत्पापं यद्रोगमशुभमकल्याणं तद् दूरे प्रतिहतमस्तु ॥
                </Text>
                <br />
                <br />
                <br />

                <Text fontSize='sm' as={'b'} > पुनः दाहिने पात्र में -</Text><br />
                <Text fontSize='xl' as={'b'} >
                    ॐ यच्छ्रेयस्तदस्तु। ॐ उत्तरे कर्मणि निर्विघ्नमस्तु। ॐ उत्तरोत्तरमहरहरभिवद्धिरस्तु। ॐ उत्तरोत्तराः क्रियाः शुभाः शोभनाः सम्पद्यन्ताम्।
                    ॐ तिथिकरणमुहूर्तनक्षत्रग्रहलग्नसम्पदस्तु।ॐ तिथिकरणे मुहूर्तनक्षत्रग्रहलग्नाधिदेवता प्रीयन्ताम्। ॐ तिथिकरणे सुमुहूर्ते सनक्षत्रे सग्रहे सलग्ने साधिदैवते प्रीयेताम्।
                    ॐ दुर्गापाञ्चाल्यौ प्रीयेताम्। ॐ अग्निपुरोगा विश्वेदेवाः प्रीयन्ताम्। ॐ इन्द्रपुरोगा मरुद्गणाःप्रीयन्ताम्। ॐवशिष्ठपुरोगा ऋषिगणाः प्रीयन्ताम्।
                    ॐ माहेश्वरीपुरोगा उमामातरः प्रीयन्ताम्। ॐ अरुन्धतीपुरोगा एकपत्न्यः प्रीयन्ताम्। ॐ ब्रह्मपुरोगाः सर्वे वेदाः प्रीयन्ताम्। ॐ विष्णुपुरोगाः सर्वे देवाः प्रीयन्ताम्।
                    ॐ ऋषयश्छन्दांस्याचार्या वेदा देवा यज्ञाश्च प्रीयन्ताम्। ॐ ब्रह्मा च ब्राह्मणाश्च प्रीयन्ताम्। ॐ अम्बिकासरस्वत्यौ प्रीयेताम्। ॐ श्रद्धामेधे प्रीयेताम्।
                    ॐ भगवती कात्यायनी प्रीयताम्। ॐ भगवती माहेश्वरी प्रीयताम्। ॐ भगवती ऋद्धिकरी प्रीयताम्। ॐ भगवती वृद्धिकरी प्रीयताम्।
                    ॐ भगवती पुष्टिकरी प्रीयताम्। ॐ भगवती तुष्टिकरी प्रीयेताम्। ॐ भगवन्तौ विघ्नविनायकौ प्रीयेताम्। ॐ सर्वाः कुलदेवताः प्रीयन्ताम्।
                    ॐ सर्वाः ग्रामदेवताः प्रीयन्ताम्। ॐ सर्वा इष्टदेवताः प्रीयन्ताम् ॥
                </Text>
                <br />
                <br />
                <br />

                <Text fontSize='sm' as={'b'} >पुनः बायें पात्र में-</Text><br />
                <Text fontSize='xl' as={'b'} >
                    ॐ हताश्च ब्रह्मद्विषः। ॐ हताश्च परिपन्थिनः। ॐ हताश्च विघ्नकर्तारः। ॐ शत्रवः पराभवं यान्तु।
                    ॐ शाम्यन्तु घोराणि। ॐ शाम्यन्तु पापानि। ॐ शाम्यन्तु ईतयः। ॐशाम्यन्तूपद्रवाः ॥
                </Text>
                <br />
                <br />
                <br />

                <Text fontSize='sm' as={'b'} >पुनः दाहिने पात्र में -</Text><br />
                <Text fontSize='xl' as={'b'} >
                    ॐ शुभानि वर्धन्ताम्। ॐ शिवा आपःसन्तु। ॐ शिवा ऋतवः सन्तु। ॐ शिवा ओषधयः सन्तु। ॐ शिवा वनस्पतयः सन्तु। ॐ शिवा अतिथयः सन्तु।
                    ॐ शिवा अग्नयः सन्तु। ॐ शिवा आहुतयः सन्तु। ॐ अहोरात्रे शिवे स्याताम् ॥
                    ॐ निकामे निकामे नः पर्जन्यो वर्षतु फलवत्यो न ओषधयः पच्यन्तां योगक्षेमो नः कल्पताम् ॥
                    ॐ शुक्राङ्गारक बुध बृहस्पति शनैश्चर राहु केतु सोम सहिता आदित्यपुरोगाः सर्वे ग्रहाः प्रीयन्ताम् ॥
                    ॐ भगवान् नारायणः प्रीयताम्। ॐ भगवान् पर्जन्यः प्रीयताम्। ॐ भगवान् स्वामी महासेनः प्रीयताम्।
                    ॐ पुरोऽनुवाक्यया यत्पुण्यं तदस्तु। ॐ याज्यया यत्पुण्यं तदस्तु। ॐ वषट्कारेण यत्पुण्यं तदस्तु। ॐ प्रातः सूर्योदये यत्पुण्यं तदस्तु ॥
                </Text>
                <br />
                <br />
                <br />

                <Text fontSize='md' >
                    ( इसके बाद कलश को यथास्थान रख दे । दाहिने पात्र में गिराये गए जल से यजमान सपरिवार सिर का मार्जन करे । आचार्य मन्त्रोच्चारण करें
                    साथ ही पूरे भवन/भूमि पर भी छिड़काव करे किसी अन्य सदस्य से करा ले या बाद में करे । दूसरे पात्र बायें वाला को जल सहित नापित वा किसी अन्य
                    सेवक के द्वारा बाहर कहीं दूर जाकर एकान्त में रखवा देना चाहिए।)
                </Text>
                <Text fontSize='xl' as={'b'} >
                    ॐ पुनन्तु मा देवजनाः पुनन्तु मनसा धियः। पुनन्तु विश्वा भूतानि जातवेदः पुनीहि मा  ॐ पुण्याहकालान् वाचयिष्ये॥
                </Text>
                <br />
                <br />
                <br />

                <Text fontSize='sm' as={'b'} > पुनः यजमान हाथ जोड़कर ब्राह्मण से प्रार्थना करे-</Text><br /><br />
                <Text fontSize='xl' as={'b'} >
                    यजमान - ॐ पुण्याहकालान् वाचयिष्ये॥<br /><br />
                    ब्राह्मण - ॐ वाच्यताम्॥<br /><br />
                    यजमान – ॐ ब्राह्मं पुण्यमहद्यच्च सृष्ट्युत्पादनकारकम्।वेदवृक्षोद्भवं नित्यं तत्पुण्याहं ब्रुवन्तु नः॥ भो ब्राह्मणाः ! मम सकुटुम्बस्य सपरिवारस्य गृहे क्रियमाणस्य ….(कर्म का नाम) कर्मणः पुण्याहं भवन्तो ब्रुवन्तु ॥<br /><br />
                    ब्राह्मण – ॐ पुण्याहम् ॥ (भो ब्राह्मणाः से २ बार और । कुल ३ बार ) <br /><br />
                    ब्राह्मण – ॐ पुनन्तु मा देवजनाः पुनन्तु मनसा धियः। पुनन्तु विश्वा भूतानि जातवेदः पुनीहि मा॥ <br /><br />
                    यजमान – ॐ पृथिव्यामुद्धृतायां तु यत्कल्याणं पुरा कृतम्। ऋषिभिः सिद्धगन्धर्वैस्तत्कल्याणं ब्रुवन्तु नः ॥ भो ब्राह्मणाः ! मम सकुटुम्बस्य सपरिवारस्य गृहे क्रियमाणस्य .. (कर्म का नाम) कर्मणः पुण्याहं भवन्तो ब्रुवन्तु ॥<br /><br />
                    ब्राह्मण – ॐ कल्याणम् ॥ (भो ब्राह्मणाः से २ बार और । कुल ३ बार )<br /><br />
                    ब्राह्मण – ॐ यथेमां वाचं कल्याणीमावदानि जनेभ्यः। ब्रह्मराजन्या ᳪ शूद्राय चार्याय च स्वाय चारणाय च। प्रियो देवानां दक्षिणायै दातुरिह भूयासमयं मे कामः समृद्ध्यतामुपमादो नमतु ॥ <br /><br />
                    यजमान – ॐ सागरस्य तु या ऋद्धिर्महालक्ष्म्यादिभिः कृता।सम्पूर्णा सुप्रभावा च तामृद्धिं प्रब्रुवन्तु नः ॥ भो ब्राह्मणाः ! मम सकुटुम्बस्य सपरिवारस्य गृहे क्रियमाणस्य .. (कर्म का नाम) कर्मणः ऋद्धिं भवन्तो ब्रुवन्तु ॥ <br /><br />
                    ब्राह्मण – ॐ ऋद्ध्यताम्॥ (भो ब्राह्मणाः से २ बार और । कुल ३ बार ) <br /><br />
                    ब्राह्मण – ॐ सत्रस्य ऋद्धिरस्यगन्म ज्योतिरमृता अभूम । दिवं पृथिव्याऽअध्याऽरुहामाविदाम देवान् स्वर्ज्योतिः ॥ <br /><br />
                    यजमान – ॐ स्वस्तिर्याऽविनाशाख्या पुण्यकल्याणवृद्धिदा।विनायकप्रिया नित्यं तां च स्वस्तिं ब्रुवन्तु नः ॥ भो ब्राह्मणाः ! मम सकुटुम्बस्य सपरिवारस्य गृहे क्रियमाणस्य ….(कर्म का नाम) कर्मणः स्वस्ति भवन्तो ब्रुवन्तु ॥ <br /><br />
                    ब्राह्मण – ॐ आयुष्मते स्वस्ति ॥ (भो ब्राह्मणाः से २ बार और । कुल ३ बार )<br /><br />
                    ब्राह्मण – ॐ स्वस्ति न इन्द्रो वृद्धश्रवाः स्वस्ति नः पूषा विश्ववेदाः। स्वस्तिनस्तार्क्ष्यो अरिष्टनेमिः स्वस्ति नो बृहस्पतिर्दधातु ॥<br /><br />
                    यजमान – ॐ समुद्रमथनाज्जाता जगदानन्दकारिका । हरिप्रिया च माङ्गल्या तां श्रियं च ब्रुवन्तु नः ॥ भो ब्राह्मणाः ! मम सकुटुम्बस्य सपरिवारस्य गृहे क्रियमाणस्य …..(कर्म का नाम) कर्मणः श्रीरस्तु इति भवन्तो ब्रुवन्तु ॥<br /><br />
                    ब्राह्मण – ॐ अस्तु श्रीः ॥ (भो ब्राह्मणाः से २ बार और । कुल ३ बार )<br /><br />
                    ब्राह्मण – ॐ श्रीश्च ते लक्ष्मीश्च पत्न्यावहोरात्रे पार्श्वे नक्षत्राणि रुपमश्विनौ व्यात्तम्। इष्णन्निषाणामुम ऽइषाण सर्वलोकम्म ऽइषाण।।<br /><br />
                    यजमान – ॐ मृकण्डुसूनोरायुर्यद् ध्रुवलोमशयोस्तथा। आयुषा तेन संयुक्ता जीवेम शरदः शतम् ॥<br /><br />
                    ब्राह्मण – ॐ शतं जीवन्तु भवन्तः ॥ ॐ शतमिन्नु शरदो अन्ति देवा यत्रा नश्चक्रा जरसं तनूनाम्।पुत्रासो यत्र पितरो भवन्ति मा नो मध्या रीरिषतायुर्गन्तोः।।<br /><br />
                    यजमान – ॐ शिवगौरीविवाहे या या श्रीरामे नृपात्मजे।धनदस्य गृहे या‌ श्रीरस्माकं सास्तु सद्मनि ॥<br /><br />
                    ब्राह्मण – ॐ अस्तु श्रीः। ॐ मनसः कामनाकूतिं वाचः सत्यमशीय। पशूनाᳪरुपमन्नस्य‌ रसो यशः श्रीः श्रयतां मयि स्वाहा ॥<br /><br />
                    यजमान – प्रजापतिर्लोकपालो धाता ब्रह्मा च देवराट्। भगवाञ्छाश्वतो नित्यं स नो रक्षतु सर्वतः ॥<br /><br />
                    ब्राह्मण – ॐ भगवान् प्रजापतिः प्रीयताम्। ॐ प्रजापते न त्वदेतान्यन्यो विश्वा रुपाणि परि ता बभूव। यत्कामास्ते जुहुमस्तन्नो अस्तुवय ᳪ स्याम पतयो रयीणाम् ॥<br /><br />
                    यजमान – आयुष्मते स्वस्तिमते यजमानाय दाशुषे। कृताः सर्वाशिषः सन्तु ऋत्विग्भिर्वेदपारगैः॥ देवेन्द्रस्य यथा स्वस्तिस्तथा स्वस्तिर्गुरोर्गृहे। एकलिंगे यथा स्वस्तिस्तथा स्वस्तिः सदा मम ॥<br /><br />
                    ब्राह्मण – ॐ आयुष्मते स्वस्ति ॥ ॐ प्रति पन्थामपद्महि स्वस्तिगामनेहसम्। येन विश्वा परिद्विषो वृणक्ति विन्दते वसु ॥ॐ पुण्याहवाचनसमृद्धिरस्तु ॥<br /><br />
                    यजमान – अस्मिन पुण्याहवाचने न्यूनातिरिक्तोयो विधिरुपविष्टब्राह्मणानां वचनात् श्री महागणपतिप्रसादाच्च परिपूर्णोऽस्तु ॥<br /><br />
                    ब्राह्मण – ॐ अस्तु परिपूर्णः ॥
                </Text>
                <br />
                <br />
                <br />

                <Text fontSize='md' >
                    तत्पश्चात् प्रथम (दांये) पात्र के जल से ४ अविधुर ब्राह्मण (जिनकी पत्नी जीवित हो) आपोहिष्ठादि 
                    ऋचा से सपरिवार यजमान का अभिषेक करें। अभिषेक के समय पत्नी यजमान के बांये भाग में बैठे ।
                </Text>
                <br />
                <br />

                <Text fontSize='sm' as={'b'} >यजमान - दक्षिणा  (तिल, जल, दक्षिणा लेकर पढे)</Text><br />
                <Text fontSize='xl' as={'b'} >
                 : ॐ अद्य कृतैतत् अस्मिन् पुण्याहवाचनकर्मणः साङ्गता सिद्ध्यर्थं तत्पूर्णफलप्राप्त्यर्थं च पुण्याहवाचकेभ्यो ब्राह्मणेभ्यो इमां दक्षिणां विभज्य अहं दास्ये ॥
                </Text>
                <br />
                <br />
                <br />
            </Box>
        </div>
    </>
}