const chetrapaalAvahan=[
    {
        title:`१. अजर`,
        des1:`ॐ इमौ ते पक्षावजरौ पतत्त्रिणौ याब्भ्या ᳪ रक्षा ᳪ स्यपह ᳪ स्यग्ग्ने॥ ताब्भ्यां पतेम सुकृतामु लोकं यत्त्र ऽऋषयो जग्मुः प्रथमजाः पुराणाः ॥`,
        des2:`ॐ भूर्भुवः स्वः अजर इहागच्छ इहतिष्ठ । ॐ भूर्भुवः स्वः अजराय नमः अजरामा नमः ॥१॥`
    },
    {
        title:`२. व्यापकाख्य`,
        des1:`ॐ प्रथमा वा ᳪ सरथिना सुवर्णा देवौ पश्यन्तौ भुवनानि विश्वा ॥ अपिप्प्रयं चोदना वा मिमाना होतारा ज्ज्योतिः प्रदिशा दिशन्ता ॥`,
        des2:`ॐ भूर्भुवः स्वः व्यापकाख्य इहागच्छ इहतिष्ठ । ॐ भूर्भुवः स्वः व्यापकाख्याय नमः ॥२॥`
    },
    {
        title:`३. इन्द्रचौर`,
        des1:`ॐ इन्द्रस्य व्वज्ज्रोऽसि मित्त्रावरुणयोस्त्वा प्प्रशास्त्रोः प्प्रशिषा युनज्मि ॥ अव्ययायै त्त्वा स्वधायै त्त्वाऽरिष्टो ऽअर्ज्जुनो मरुतां प्प्रसवेन जयापाम मनसा समिन्द्रियेण ॥`,
        des2:`ॐ भूर्भुवः स्वः इन्द्रचौर इहागच्छ इहतिष्ठ । ॐ भूर्भुवः स्वः इन्द्रचौराय नमः ॥३॥`
    },
    {
        title:`४. इन्द्रमूर्ति`,
        des1:`ॐ एवेदिन्द्रं वृषणं व्वज्रबाहुं व्वसिष्ठासो ऽअब्भ्यर्ज्चन्त्यर्कैः ॥ स न स्तुतो वीरवद्धातु गोमद्यूयं पात स्वस्तिभिः सदा नः ॥`,
        des2:`ॐ भूर्भुवः स्वः इन्द्रमूर्ति इहागच्छ इहतिष्ठ। ॐ भूर्भुवः स्वः इन्द्रमूर्तये नमः ॥४॥`
    },
    {
        title:`५. उक्षभिध`,
        des1:`ॐ उक्षा समुद्रो ऽअरुणः सुपर्णः  पूर्व्वस्य योनि पितुराविवेश ।। मद्ध्ये दिवो निहितः पृश्न्निरश्म्मा व्विचक्क्रमे रजसस्यात्यन्तौ ॥`,
        des2:`ॐ भूर्भुवः स्वः उक्षभिध इहागच्छ इहतिष्ठ । ॐ भूर्भुवः स्वः उक्षाभिधाय नमः ॥५॥`
    },
    {
        title:`६. कूष्माण्ड`,
        des1:`ॐ यद्देवा देवहेडनंं देवासश्रकृमा व्वयम्॥ अग्निर्म्मा तस्मादेनसो व्विश्श्वान्मुञ्चत्वᳪ हसः ॥`,
        des2:`ॐ भूर्भुवः स्वः कूष्माण्ड इहागच्छ इहतिष्ठ । ॐ भूर्भुवः स्वः कूष्माण्डाय नमः ॥६॥`
    },
    {
        title:`७. वरुण`,
        des1:`ॐ स न ऽइन्द्राय वज्ज्यवे व्वरुणाय मरुद्भ्यः ॥ व्वरिवोवित्परि स्रव ॥`,
        des2:`ॐ भूर्भुवः स्वः वरुण इहागच्छ इहतिष्ठ । ॐ भूर्भुवः स्वः वरुणाय नमः॥७॥`
    },
    {
        title:` ८. बाहुकाख्य`,
        des1:`ॐ बाहू मे बलमिन्द्रिय हस्तौ मे कर्म वीर्य्यम् ॥ आत्मा क्षत्त्रमुरो मम ॥`,
        des2:`ॐ भूर्भुवः स्वः बाहुकाख्य इहागच्छ इहतिष्ठ । ॐ भूर्भुवः स्वः बाहुकाख्याय नमः ॥८॥`
    },
    {
        title:`९. विमुक्त `,
        des1:`ॐ मुञ्चतु मा शपथ्यादयो व्वरुण्यादुत । अथो यमस्य पङ्वीशात्सर्व्वस्म्माद्देवकिल्विषात् ॥ `,
        des2:`ॐ भूर्भुवः स्वः विमुक्त इहागच्छ इहतिष्ठ । ॐ भूर्भुवः स्वः विमुक्ताय नमः ॥९॥`
    },
    {
        title:`१०. लिप्तक `,
        des1:` ॐ कुर्व्वन्नेवेह कर्म्माणि भू० जिजीविषेच्छत ᳪ समाः॥ एवं त्वयि नान्न्ययेतो ऽस्ति न कस्म लिप्यते नरे॥`,
        des2:`ॐ भूर्भुवः स्वः लिप्तक इहागच्छ इहतिष्ठ । ॐ भूर्भुवः स्वः लिप्तकाय नमः ॥१०॥`
    },
    {
        title:`११. लीलालोक`,
        des1:`ॐ सन्नः सिन्धु रवभृथायोद्यतः समुद्रोऽब्भ्यवह्नियमाणः सलिलः प्प्रप्लुतो ययो रोजसा स्क्कमिता रजा ᳪ सि वीर्य्येभिर्वीरतमा शविष्ठा॥ या पत्त्येते ऽअप्प्रतीता सहाभिर्विष्णू ऽअगन्वरुणापूर्व्वहूतौ ॥`,
        des2:`ॐ भूर्भुवः स्वः लीलालोक इहागच्छ इहतिष्ठ । ॐ भूर्भुवः स्वः लीलालोकाय नमः ॥११॥ `
    },
    {
        title:`१२. एकदंष्ट्र`,
        des1:`ॐ नमो गणेब्भ्यो गणपतिब्भ्यश्च वो नमोनमो व्व्रातेब्भ्यो व्व्रातपतिब्भ्यश्च वो नमोनमो गृत्सेब्भ्यो गृत्सपतिब्भ्यश्च वो नमोनमो व्विरूपेब्भ्यो व्विश्वरुपेब्भ्यश्च वो नमः ॥`,
        des2:`ॐ भूर्भुवः स्वः एकदंष्ट्र इहागच्छ इहतिष्ठ । ॐ भूर्भुवः स्वः एकदंष्ट्राय नमः ॥१२॥`
    },
    {
        title:`१३. ऐरावताख्य`,
        des1:`ॐ अर्म्मेभ्यो हस्तिपं जवायाश्वपं पुष्ट्यै गोपालं वीर्य्यायाविपालं तेजसेऽजपालमिरायै कोनाशं कीलालाय सुराकारं भद्राय गृहपᳪ श्रेयसे व्वित्तधमाद्ध्यक्ष्यायानुक्षत्तारम् ॥`,
        des2:`ॐ भूर्भुवः स्वः ऐरावताख्य इहागच्छ इहतिष्ठ । ॐ भूर्भुवः स्वः ऐरावताख्याय नमः ॥१३॥`
    },
    {
        title:`१४. ओषधीघ्न`,
        des1:`ॐ या ऽओषधीः पूर्व्वा जाता देवेब्भ्यस्त्रियुगं पुरा ॥ मनैनु बब्भ्रूणामहᳪ शतं धामानि सप्त च ॥`,
        des2:`ॐ भूर्भुवः स्वः ओषधीघ्न इहागच्छ इहतिष्ठ । ॐ भूर्भुवः स्वः ओषधीघ्नाय नमः ॥१४॥`
    },
    {
        title:` १५. बन्धनाख्य`,
        des1:`ॐ त्र्यम्बकं यजामहे सुगन्धिम्पुष्टिवर्द्धनम्। उर्वारुकमिव बन्धनादितो मुक्षीय मामुतः ॥ ॐ भूर्भुवः स्वः कौमारि इहागच्छ इह तिष्ठ॥`,
        des2:`ॐ भूर्भुवः स्वः बन्धनाख्य इहागच्छ इहतिष्ठ । ॐ भूर्भुवः स्वः बन्धनाख्याय नमः ॥१५॥`
    },
    {
        title:`१६.  दिव्यकाय`,
        des1:`ॐ देव सवितः प्रसुव यज्ञं प्प्रसुव यज्ञपतिं भगाय ।। दिव्यो गन्धर्व्वः केतपूः केतं नः पुनातु व्वाचस्पतिर्व्वाजं नः स्वदतु स्वाहा ॥`,
        des2:`ॐ भूर्भुवः स्वः दिव्यकाय इहागच्छ इहतिष्ठ । ॐ भूर्भुवः स्वः दिव्यकायाय नमः ॥१६॥`
    },
    {
        title:`१७. कम्बलाख्य`,
        des1:`ॐ सीसेन तन्त्रं मनसा मनीषिण ऽऊर्णात्त्रेण कवयो व्वयन्ति ॥ अश्विना यज्ञ ᳪ सविता सरस्वतीन्द्रस्य रूपं व्वरुणो भिषज्ज्यन् ॥`,
        des2:`ॐ भूर्भुवः स्वः कम्बलाख्याय इहागच्छ इहतिष्ठ । ॐ भूर्भुवः स्वः कम्बलाख्य नमः ॥१७॥`
    },
    {
        title:` १८. क्षोभणाख्य`,
        des1:`ॐ आशुः शिशानो वृषभो न भीमो घनाघनः क्षोभणश्चर्षणीनाम् । संक्रन्दनोऽनिमिष ऽएकवीरः शत ᳪ सेना अजयत्साकमिन्द्रः ॥`,
        des2:`ॐ भूर्भुवः स्वः क्षोभणाख्य इहागच्छ इह तिष्ठ। ॐ भूर्भुवः स्वः  क्षोभणाख्याय नमः ॥१८॥`
    },
    {
        title:` १९. गव`,
        des1:` ॐ इमᳪ साहस्रᳪ शतधारमुत्सं व्यच्च्यमानᳪ सरिरस्य मद्ध्ये ॥ घृतं दुहानामदितिं जनायाग्ग्ने मा हिᳪ सीः परमे व्योमन्॥ गवयमारण्यमनु ते दिशामि तेन चिन्वानस्तन्वो निषीद॥ गवयं ते शुगृच्छतु यं द्विष्ष्मस्तं ते शुगृच्छतु।।`,
        des2:`ॐ भूर्भुवः स्वः गव इहागच्छ इहतिष्ठ । ॐ भूर्भुवः स्वः गवे नमः ॥१९॥`
    },
    {
        title:`२०. घण्टाभिध`,
        des1:`ॐ कुम्भो व्वनिष्ठुर्ज्जनिता शचीभिर्य्यस्म्मिन्नग्ग्रे योन्यां गर्भो ऽअन्तः॥ प्लाशिर्व्यक्तः शतधार ऽउत्सो दुहे न कुम्भी स्वधां पितृब्भ्यः ॥`,
        des2:`ॐ भूर्भुवः स्वः घण्टाभिध इहागच्छ इहतिष्ठ । ॐ भूर्भुवः स्वः घण्टाभिधाय नमः ॥२०॥ `
    },
    {
        title:`२१. व्याल `,
        des1:`ॐ आ वक्रन्दय बलमोजो न ऽआधा निष्ट्टनिहि दुरिता बाधमानः॥ अप प्रोथ दुन्दुभे दुच्छुना ऽइत ऽइन्द्रस्य मुष्टिरसि वीडयस्व ॥`,
        des2:`ॐ भूर्भुवः स्वः व्याल इहागच्छ इहतिष्ठ । ॐ भूर्भुवः स्वः व्यालाय नमः ॥२१॥`
    },
    {
        title:`२२. अणुस्वरूप`,
        des1:`ॐ इन्द्रायाहि तूतुजानऽउप ब्जह्माणि हरिवः ॥ सुते दधिष्ष्व नश्श्चनः ॥`,
        des2:`ॐ भूर्भुवः स्वः अणुस्वरूप इहागच्छ इहतिष्ठ । ॐ भूर्भुवः स्वः अणुस्वरूपाय नमः ॥२२॥`
    },
    {
        title:` २३. चन्द्रवारुण`,
        des1:`ॐ चन्द्रमा ऽअप्स्वन्तरा सुपर्णो धावते दिवि ॥ रयिं पिशङ्गं बहुलं पुरुस्पृहᳪ हरि रेति कनिवक्रदत् ॥ `,
        des2:`ॐ भूर्भुवः स्वः चन्द्रवारुण इहागच्छ इहतिष्ठ । ॐ भूर्भुवः स्वः चन्द्रवारुणाय नमः ॥२३॥ `
    },
    {
        title:` २४. घटाटोप `,
        des1:` ॐ प्रतिश्रुत्काया ऽअर्त्तनं घोषाय भषमन्ताय बहुवादिनमनन्ताय मूकᳪ शब्दायाडम्बराघातं महसे व्वीणावादं क्रोशाय तूणवध्ममवरस्पराय शङ्खध्मं व्वनाय व्वनपमन्न्यतो ऽरण्याय दावपम् ॥ `,
        des2:`ॐ भूर्भुवः स्वः घटाटोप इहागच्छ इहतिष्ठ । ॐ भूर्भुवः स्वः घटाटोपाय नमः ॥२४॥ `
    },
    {
        title:`२५.जटाल`,
        des1:`ॐ उग्ग्रँ लोहितेन मित्रᳪ सौव्व्रत्येन रुद्रं दौर्व्व्रत्येनेन्द्रं प्रक्रीडेन मरुतो बलेन साद्ध्यान् प्प्रमुदा॥ भवस्य कष्ठयᳪ रुद्रस्यान्तः पार्श्वं महादेवस्य यकृच्छर्व्वस्य व्वनिष्ठुः पशुपतेः पुरीतत् ॥ `,
        des2:`ॐ भूर्भुवः स्वः जटाल इहागच्छ इहतिष्ठ । ॐ भूर्भुवः स्वः जटालाय नमः ॥२५॥`
    },
    {
        title:`२६. क्रतु`,
        des1:` ॐ पवित्रेण पुनीहि मा शुवक्रेण देव दीद्यत् ॥ अग्ग्ने क्रत्वा वक्रतूँ२ ॥ रनु ॥ `,
        des2:`ॐ भूर्भुवः स्वः क्रतु इहागच्छ इहतिष्ठ । ॐ भूर्भुवः स्वः क्रतवे नमः ॥२६॥ `
    },
    {
        title:` २७. घण्टेश्वर`,
        des1:`ॐ आजिघ्र कलशं मह्या त्वा विशन्त्विन्दवः पुनरुर्जा निवर्तस्व सा नः। सहस्रं धुक्ष्वोरु धारा पयस्वतिः पुनर्म्मा विशताद्रयिः ॥`,
        des2:` ॐ भूर्भुवः स्वः घण्टेश्वर इहागच्छ इहतिष्ठ । ॐ भूर्भुवः स्वः घण्टेश्वराय नमः ॥२७॥ `
    },
    {
        title:`२८. विटङ्क `,
        des1:` ॐ व्वाय शुवक्रो ऽ अयामि ते मध्वो ऽअग्ग्रं दिविष्टिषु॥ आयाहि सोमपीतये स्प्पार्हो देव नियुत्त्वता ॥ `,
        des2:`ॐ भूर्भुवः स्वः विटङ्क इहागच्छ इहतिष्ठ । ॐ भूर्भुवः स्वः विटङ्काय नमः ॥२८॥ `
    },
    {
        title:`२९. मणिमति`,
        des1:`ॐ दैव्या होतारा ऽऊर्ध्वमद्ध्वरं नोऽग्नेर्ज्जिह्वामभि गृणीतम्॥ कृणुतं नः स्विष्टि्टम् ॥`,
        des2:` ॐ भूर्भुवः स्वः मणिमति इहागच्छ इहतिष्ठ । ॐ भूर्भुवः स्वः मणिमतये नमः ॥२९॥`
    },
    {
        title:` ३०. गणबन्ध`,
        des1:`ॐ त्रीणिऽ त आहुर्द्दिवि बन्धनानि त्रीण्यप्पसु त्रीण्यन्तः समुद्रे ॥ उतेव मे व्वरुण श्छन्त्स्यर्व्वन्यत्त्रा त आहुः परमं जनित्रम् ॥`,
        des2:`ॐ भूर्भुवः स्वः गणबन्ध इहागच्छ इहतिष्ठ । ॐ भूर्भुवः स्वः गणबन्धाय नमः ॥३०॥`
    },
    {
        title:` ३१. डामर`,
        des1:`ॐ प्रतिश्रुत्काया ऽअर्त्तनं घोषाय भषमन्ताय बहुवादिनमनन्ताय मूकᳪ शब्दयाडम्बराघातं महसेव्वीणावादं क्रोशाय तूणवध्ममवरस्पराय शङ्खद्ध्मं व्वनाय व्वनपमन्यतोऽरण्याय दावपम् ॥`,
        des2:`ॐ भूर्भुवः स्वः डामर इहागच्छ इहतिष्ठ । ॐ भूर्भुवः स्वः डामराय नमः ॥३१॥`
    },
    {
        title:` ३२. दुण्ढिकर्ण `,
        des1:`ॐ शुद्धव्वालः सर्व्वशुद्धवालो मणिवालस्त ऽआश्विनाः श्येतः श्येताक्षोऽरुणस्तं रुद्राय पशुपतये कर्णा यामाऽअवलिप्ता रौद्रा नभोरूपाः पार्ज्जन्याः ॥`,
        des2:` ॐ भूर्भुवः स्वः दुण्ढिकर्ण इहागच्छ इहतिष्ठ । ॐ भूर्भुवः स्वः दुण्ढिकर्णाय नमः ॥३२॥`
    },
    {
        title:` ३३. स्थवीर`,
        des1:`ॐ व्वनस्पते वीड्वङ्गो हि भूया ऽअस्मत्सखा प्प्रतरणः सुवीरः ॥ गोभिः सनद्धो ऽअसि व्वीडयस्वास्त्थाता ते जयतु जेत्वानि ॥`,
        des2:`ॐ भूर्भुवः स्वः स्थवीर इहागच्छ इहतिष्ठ । ॐ भूर्भुवः स्वः स्थविराय नमः ॥३३॥ `
    },
    {
        title:` ३४. दन्तुर`,
        des1:` ॐ सुपर्ण व्वस्ते मृगो ऽअस्या दन्तो गोभिः सनद्धापतति प्रसूता ॥ यत्त्रा नरः सं च व्वि च द्रवन्ति तत्त्रास्म्मब्भ्यषिवः शर्म्म यᳪ सन् ॥`,
        des2:`ॐ भूर्भुवः स्वः दन्तुर इहागच्छ इहतिष्ठ । ॐ भूर्भुवः स्वः दन्तुराय नमः ॥३४॥`
    },
    {
        title:` ३५. धनद`,
        des1:`ॐ अग्ग्ने ऽअच्छा व्वदेह नः प्रति नः सुमना भव ॥ प्प्र नो यच्छ सहस्रजित् त्वᳪ हि धनदा ऽअसि स्वाहा ॥`,
        des2:`ॐ भूर्भुवः स्वः धनद इहागच्छ इहतिष्ठ । ॐ भूर्भुवः स्वः धनदाय नमः ॥३५॥`
    },
    {
        title:` ३६. नागकर्ण`,
        des1:`ॐ भद्रं कर्ण्णेभिः शृणुयाम देवा भद्रं पश्येमाक्ष भिर्यजत्राः। स्थिरैरङ्गैस्तुष्टुवा ᳪ सस्तनूभिर्व्य शेमहि देवहितं यदायुः ॥`,
        des2:`ॐ भूर्भुवः स्वः नागकर्ण इहागच्छ इहतिष्ठ । ॐ भूर्भुवः स्वः नागकर्णाय नमः ॥३६॥ `
    },
    {
        title:`३७. मारीग`,
        des1:`ॐ बाहू मे बलमिन्द्रियᳪ हस्तौ मे कर्म्म वीर्य्यम् ॥ आत्मा क्षत्त्रमुरो मम ॥`,
        des2:`ॐ भूर्भुवः स्वः मारीग इहागच्छ इहतिष्ठ । ॐ भूर्भुवः स्वः मारीगणाय नमः ॥३७॥`
    },
    {
        title:`३८. फेत्कार`,
        des1:`ॐ अपां फेनेन नमुचे शिर ऽइन्द्रोदवर्त्तयः ॥ व्विश्वा यदजयः स्पृधः ।`,
        des2:`ॐ भूर्भुवः स्वः फेत्कार इहागच्छ इहतिष्ठ । ॐ भूर्भुवः स्वः फेत्काराय नमः ॥३८॥`
    },
    {
        title:` ३९. चीकर`,
        des1:`ॐ इदᳪ हविः प्प्रजननं मे ऽअस्तु दशवीरᳪ सर्व्वगण ᳪ स्वस्तये । आत्मसनि प्प्रजासनि पशुसनि लोकसन्यभयसनि। अग्निः प्प्रजां बहुलां में करोत्वन्नं पयो रेतो ऽअस्म्मासु धत्त॥`,
        des2:`ॐ भूर्भुवः स्वः चीकर इहागच्छ इहतिष्ठ । ॐ भूर्भुवः स्वः चीकराय नमः ॥३९॥`
    },
    {
        title:`४०. सिंहाकृति`,
        des1:`ॐ या व्याघ्रं व्विषूचिकोभौ व्वृकं च रक्षति ॥ श्येनं पतत्त्रिण सिᳪ हᳪ सेमं पात्त्वᳪ हसः ॥`,
        des2:`ॐ भूर्भुवः स्वः  सिंहाकृति इहागच्छ इहतिष्ठ । ॐ भूर्भुवः स्वः सिंहाकृतये नमः ॥४०॥ `
    },
    {
        title:`४१. मृग`,
        des1:`ॐ मृगो न भीमः कुचरो गिरिष्ठाः परावत ऽआजगन्था परस्याः ॥ सृक ᳪ स ᳪ शाय पविमिन्द्र तिग्मं व्वि शत्रून् ताड्डिव्वि मृधो नुदस्व ॥`,
        des2:`ॐ भूर्भुवः स्वः मृग इहागच्छ इहतिष्ठ । ॐ भूर्भुवः स्वः मृगाय नमः ॥४१॥`
    },
    {
        title:`४२. यक्ष्मप्रिय`,
        des1:`ॐ इन्दुर्दक्षः श्येन ऽऋतावा हिरण्यपक्षः शकुनो भुरण्युः ॥ महान्त्सधस्थे ध्रुव ऽआ निषत्तो नमस्ते ऽअस्तु मा मा हिᳪ सीः ॥`,
        des2:` ॐ भूर्भुवः स्वः यक्ष्मप्रिय इहागच्छ इहतिष्ठ । ॐ भूर्भुवः स्वः यक्ष्मप्रियाय नमः ॥४२॥`
    },
    {
        title:`४३.मेघवाहन`,
        des1:`ॐ जीमूतस्येव भवति प्प्रतीकं य्यद्वर्म्मी याति समदामुपस्थे॥ अनाविद्धया तन्वा जय त्वᳪ स त्त्वा व्वर्म्मणो महिमा पिपर्त्तु ॥`,
        des2:`ॐ भूर्भुवः स्वः मेघवाहन इहागच्छ इहतिष्ठ । ॐ भूर्भुवः स्वः मेघवाहनाय नमः ॥४३॥`
    },
    {
        title:`४४. तीक्ष्णोष्ट्र`,
        des1:`ॐ तीव्व्रान् घोषान् कृण्ण्वते व्वृषपाणयोऽश्वा रथेभिः सह वामयन्तः ॥ अवक्र्क्रामन्तः प्प्रपदैरमित्त्रान्क्षिणन्ति शत्त्रूँ ऽ रनपव्ययन्तः ॥`,
        des2:`ॐ भूर्भुवः स्वः तीक्ष्णोष्ट्र इहागच्छ इहतिष्ठ । ॐ भूर्भुवः स्वः तीक्ष्णोष्ट्राय नमः ॥४४॥`
    },
    {
        title:`४५. अनल`,
        des1:`ॐ व्वायुष्ट्वा पचतैरवत्वसितग्ग्री वश्छागैर्न्यग्ग्रोधश्चमसौः शल्मलिर्वृद्ध्या ॥ एष स्य रात्थ्यो व्वृषा षड्भिश्चतुर्भिरेदगन्ब्रह्माऽकृष्णश्च नोऽवतु नमोऽग्ग्नये ॥`,
        des2:`ॐ भूर्भुवः स्वः अनल इहागच्छ इहतिष्ठ । ॐ भूर्भुवः स्वः अनलाय नमः ॥४५॥`
    },
    {
        title:`४६. शुक्लतुण्ड`,
        des1:`ॐ अदित्यास्त्वा पृष्ठे सादयाम्यऽन्तरिक्षस्य धर्त्रीं व्विष्ट्टम्भनीं दिशामधिपत्नीं भुवनानाम्॥ ऊर्मिर्द्रप्सो ऽअपामसि व्विश्वकर्म्मा त ऽऋषिरश्विनाध्वर्यू सादयतामिह त्वा ॥`,
        des2:`ॐ भूर्भुवः स्वः शुक्लतुण्ड इहागच्छ इहतिष्ठ । ॐ भूर्भुवः स्वः शुक्लतुण्डाय नमः ॥४६॥ `
    },
    {
        title:`४७. अन्तरिक्ष`,
        des1:` ॐ द्यौस्तै पृथिव्वयन्तरिक्षं व्वायुश्छिद्रं पृणातु ते॥ सूर्य्यस्ते नक्षत्रैः सह लोकं कृणोतु साधुया ॥`,
        des2:`ॐ भूर्भुवः स्वः अन्तरिक्ष इहागच्छ इहतिष्ठ । ॐ भूर्भुवः स्वः अन्तरिक्षाय नमः ॥४७॥ `
    },
    {
        title:`४८. बर्बरक`,
        des1:`ॐ सं बर्हिरङ्क्ताᳪ हविषा घृतेन समादित्यैर्वसुभिः सम्मरुद्भिः। समिन्द्रो व्विश्वदेवेभिरङ्क्तां दिव्यं नभो गच्छतु यत्स्वाहा ॥`,
        des2:`ॐ भूर्भुवः स्वः बर्बरक इहागच्छ इहतिष्ठ । ॐ भूर्भुवः स्वः बर्बरकाय नमः ॥४८॥ `
    },
    {
        title:` ४९. पावन `,
        des1:`ॐ पवमानः सो ऽअद्य नः पवित्रेण व्विचर्षणिः ॥ यः पोता स पुनातु मा ॥`,
        des2:` ॐ भूर्भुवः स्वः पावन इहागच्छ इहतिष्ठ । ॐ भूर्भुवः स्वः पावनाय नमः ॥४९॥`
    },

]

export {chetrapaalAvahan}