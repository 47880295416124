export const arti = [
    {
        title:"भगवान् गणेश जी की आरती - जय गणेश जय गणेश",
        description:[
            "जय गणेश जय गणेश, जय गणेश देवा । माता जाकी पार्वती, पिता महादेवा ॥",
            "एक दंत दयावंत, चार भुजा धारी । माथे सिंदूर सोहे, मूसे की सवारी ॥",
            "जय गणेश जय गणेश, जय गणेश देवा । माता जाकी पार्वती, पिता महादेवा ॥",
            "पान चढ़े फल चढ़े, और चढ़े मेवा । लड्डुअन का भोग लगे, संत करें सेवा ॥",
            "जय गणेश जय गणेश, जय गणेश देवा । माता जाकी पार्वती, पिता महादेवा ॥",
            "अंधन को आंख देत, कोढ़िन को काया । बांझन को पुत्र देत, निर्धन को माया ॥",
            "जय गणेश जय गणेश, जय गणेश देवा । माता जाकी पार्वती, पिता महादेवा ॥",
            "सूर श्याम शरण आए, सफल कीजे सेवा । माता जाकी पार्वती, पिता महादेवा ॥",
            "जय गणेश जय गणेश, जय गणेश देवा । माता जाकी पार्वती, पिता महादेवा ॥",
            "दीनन की लाज रखो, शंभु सुतकारी । कामना को पूर्ण करो, जाऊं बलिहारी ॥",
            "जय गणेश जय गणेश, जय गणेश देवा । माता जाकी पार्वती, पिता महादेवा ॥"
        ]
    },
    {
        title: `श्री सत्यनारायण जी की आरती - जय लक्ष्मीरमणा`,
        description: [
            "जय लक्ष्मी रमणा, श्री लक्ष्मी रमणा, सत्यनारायण स्वामी जन-पातक-हरणा।। जय.।।",
            "रत्नजटित सिंहासन अद्भुत छबि राजै । नारद करत निराजन घंटा ध्वनि बाजै।। जय.।।",
            "प्रकट भये कलि कारण, द्विज को दरस दियो।बूढ़े ब्राह्मण बनकर कंचन-महल कियो।। जय.।।",
            "दुर्बल भील कठारो, जिनपर कृपा करी। चन्द्रचूड़ एक राजा, जिनकी बिपति हरी।। जय.।।",
            "वैश्य मनोरथ पायो, श्रद्धा तज दीन्हीं। सो फल भोग्यो प्रभुजी फिर अस्तुति कीन्हीं।। जय.।।",
            "भाव-भक्ति के कारण छिन-छिन रूप धरयो। श्रद्धा धारण कीनी, तिनको काज सरयो।। जय.।।",
            "ग्वाल-बाल संग राजा वन में भक्ति करी। मनवांछित फल दीन्हों दीनदयालु हरी।। जय.।।",
            "चढ़त प्रसाद सवायो कदलीफल, मेवा। धूप-दीप-तुलसी से राजी सत्यदेवा।। जय.।।",
            "श्री सत्यनारायण जी की आरती जो कोई नर गावै। तन-मन-सुख-संपत्ति मन-वांछित फल पावै।। जय.।।"
        ]
    },
    {
        title: "भगवान् जगदीश्वर जी की आरती - ॐ जय जगदीश हरे",
        description: [
            "ॐ जय जगदीश हरे, स्वामी जय जगदीश हरे भक्त जनों के संकट, क्षण में दूर करे ॥ ॐ जय..॥",
            "जो ध्यावे फल पावे, दुःख बिनसे मन का, सुख सम्पति घर आवे,कष्ट मिटे तन का ॥ ॐ जय..॥",
            "मात पिता तुम मेरे, शरण गहूं किसकी, तुम बिन और न दूजा, आस करूं मैं जिसकी ॥ ॐ जय..॥",
            "तुम पूरण परमात्मा, तुम अन्तर्यामी, पारब्रह्म परमेश्वर,तुम सब के स्वामी ॥ ॐ जय..॥",
            "तुम करुणा के सागर, तुम पालनकर्ता, मैं मूरख फलकामी, कृपा करो भर्ता॥ ॥ ॐ जय..॥",
            "तुम हो एक अगोचर, सबके प्राणपति, किस विधि मिलूं दयामय, मैं तुमको कुमति ॥ ॐ जय..॥",
            "दीन-बन्धु दुःख-हर्ता, ठाकुर तुम मेरे, अपने हाथ उठाओ, द्वार पड़ा तेरे ॥॥ ॐ जय..॥",
            "विषय-विकार मिटाओ, पाप हरो देवा, श्रद्धा भक्ति बढ़ाओ, सन्तन की सेवा ॥ॐ जय..॥"
        ]
    },
    {
        title: "भगवान् शिव जी की आरती - ॐ जय शिव ओंकारा",
        description: [
            "ॐ जय शिव ओंकारा, स्वामी जय शिव ओंकारा। ब्रह्मा, विष्णु, सदाशिव,अर्द्धांगी धारा ॥ ॐ जय...॥",
            "एकानन चतुरानन पंचानन राजे । हंसासन गरूड़ासन वृषवाहन साजे ॥ ॐ जय...॥",
            "दो भुज चार चतुर्भुज दसभुज अति सोहे । त्रिगुण रूप निरखते त्रिभुवन जन मोहे ॥ ॐ जय...॥",
            " अक्षमाला वनमाला, मुण्डमाला धारी । चंदन मृगमद सोहै, भाले शशिधारी ॥ ॐ जय...॥",
            "श्वेताम्बर पीताम्बर बाघम्बर अंगे । सनकादिक गरुणादिक भूतादिक संगे ॥ ॐ जय...॥",
            "कर के मध्य कमंडल चक्र त्रिशूलधारी । सुखकारी दुखहारी जगपालन कारी ॥ ॐ जय...॥",
            "ब्रह्मा विष्णु सदाशिव जानत अविवेका । प्रणवाक्षर में शोभित ये तीनों एका ॥ ॐ जय...॥",
            "त्रिगुणस्वामी जी की आरति जो कोइ नर गावे । कहत शिवानंद स्वामी सुख संपति पावे ॥ ॐ जय...॥"
        ]
    },
    {
        title: "अम्बे माता की आरती - जय अम्बे गौरी",
        description: [
            "जय अम्बे गौरी, मैया जय श्यामा गौरी । तुमको निशदिन ध्यावत, हरि ब्रह्मा शिवरी ॥ ॐ जय अम्बे गौरी..॥",
            "मांग सिंदूर विराजत, टीको मृगमद को । उज्ज्वल से दोउ नैना, चंद्रवदन नीको ॥ ॐ जय अम्बे गौरी..॥",
            "कनक समान कलेवर, रक्ताम्बर राजै । रक्तपुष्प गल माला, कंठन पर साजै ॥ ॐ जय अम्बे गौरी..॥",
            "कानन कुण्डल शोभित, नासाग्रे मोती । कोटिक चंद्र दिवाकर, सम राजत ज्योती ॥ ॐ जय अम्बे गौरी..॥",
            "शुंभ - निशुंभ बिदारे, महिषासुर घाती । धूम्र विलोचन नैना, निशदिन मदमाती ॥ ॐ जय अम्बे गौरी..॥",
            "चण्ड - मुण्ड संहारे, शोणित बीज हरे । मधु - कैटभ दोउ मारे, सुर भयहीन करे ॥ ॐ जय अम्बे गौरी..॥",
            "ब्रह्माणी, रूद्राणी, तुम कमला रानी । आगम निगम बखानी, तुम शिव पटरानी ॥ॐ जय अम्बे गौरी..॥",
            "चौंसठ योगिनी मंगल गावत, नृत्य करत भैरों । बाजत ताल मृदंगा, अरू बाजत डमरू ॥ ॐ जय अम्बे गौरी..॥",
            "तुम ही जग की माता, तुम ही हो भरता, भक्तन की दुख हरता । सुख संपति करता ॥ ॐ जय अम्बे गौरी..॥",
            "भुजा चार अति शोभित, वर मुद्रा धारी । मनवांछित फल पावत, सेवत नर नारी ॥ ॐ जय अम्बे गौरी..॥",
            "कंचन थाल विराजत, अगर कपूर बाती । श्रीमालकेतु में राजत, कोटि रतन ज्योती ॥ ॐ जय अम्बे गौरी..॥",
            "श्री अंबेजी की आरति, जो कोइ नर गावे । कहत शिवानंद स्वामी, सुख - संपति पावे ॥ ॐ जय अम्बे गौरी..॥"
        ]
    },
    {
        title:"कृष्ण भगवान् जी की आरती - आरती कुंजबिहारी की",
        description:[
            "आरती कुंजबिहारी की, श्री गिरिधर कृष्ण मुरारी की ॥",
            "गले में बैजंती माला, बजावै मुरली मधुर बाला । श्रवण में कुण्डल झलकाला, नंद के आनंद नंदलाला । गगन सम अंग कांति काली, राधिका चमक रही आली । लतन में ठाढ़े बनमाली भ्रमर सी अलक, कस्तूरी तिलक, चंद्र सी झलक, ललित छवि श्यामा प्यारी की, श्री गिरिधर कृष्ण मुरारी की ॥",
            "आरती कुंजबिहारी की, श्री गिरिधर कृष्ण मुरारी की ॥",
            "कनकमय मोर मुकुट बिलसै, देवता दरसन को तरसैं । गगन सों सुमन रासि बरसै । बजे मुरचंग, मधुर मिरदंग, ग्वालिन संग, अतुल रति गोप कुमारी की, श्री गिरिधर कृष्णमुरारी की ॥",
            "आरती कुंजबिहारी की, श्री गिरिधर कृष्ण मुरारी की ॥",
            "जहां ते प्रकट भई गंगा, सकल मन हारिणि श्री गंगा । स्मरन ते होत मोह भंगा बसी शिव सीस, जटा के बीच, हरै अघ कीच, चरन छवि श्रीबनवारी की, श्री गिरिधर कृष्णमुरारी की ॥",
            "आरती कुंजबिहारी की, श्री गिरिधर कृष्ण मुरारी की ॥",
            "चमकती उज्ज्वल तट रेनू, बज रही वृंदावन बेनू । चहुं दिसि गोपि ग्वाल धेनू हंसत मृदु मंद, चांदनी चंद, कटत भव फंद, टेर सुन दीन दुखारी की, श्री गिरिधर कृष्णमुरारी की ॥",
            "आरती कुंजबिहारी की, श्री गिरिधर कृष्ण मुरारी की ॥ आरती कुंजबिहारी की, श्री गिरिधर कृष्ण मुरारी की ॥ आरती कुंजबिहारी की, श्री गिरिधर कृष्ण मुरारी की ॥ ॥"
        ]
    },
    {
        title:"हनुमान जी की आरती -आरती कीजै हनुमान लला की ",
        description:[
            "आरती कीजै हनुमान लला की । दुष्ट दलन रघुनाथ कला की ॥",
            "जाके बल से गिरवर काँपे । रोग-दोष जाके निकट न झाँके ॥",
            "अंजनि पुत्र महा बलदाई । संतन के प्रभु सदा सहाई ॥",
            "आरती कीजै हनुमान लला की ॥",
            "दे वीरा रघुनाथ पठाए । लंका जारि सिया सुधि लाये ॥",
            "लंका सो कोट समुद्र सी खाई । जात पवनसुत बार न लाई ॥",
            "आरती कीजै हनुमान लला की ॥",
            "लंका जारि असुर संहारे । सियाराम जी के काज सँवारे ॥",
            "लक्ष्मण मुर्छित पड़े सकारे । लाये संजिवन प्राण उबारे ॥",
            "आरती कीजै हनुमान लला की ॥",
            "पैठि पताल तोरि जमकारे । अहिरावण की भुजा उखारे ॥",
            "बाईं भुजा असुर दल मारे । दाहिने भुजा संतजन तारे ॥",
            "आरती कीजै हनुमान लला की ॥",
            "सुर-नर-मुनि जन आरती उतरें । जय जय जय हनुमान उचारें ॥",
            "कंचन थार कपूर लौ छाई । आरती करत अंजना माई ॥",
            "आरती कीजै हनुमान लला की ॥",
            "जो हनुमानजी की आरती गावे । बसहिं बैकुंठ परम पद पावे ॥",
            "लंक विध्वंस किये रघुराई । तुलसीदास स्वामी कीर्ति गाई ॥",
            "आरती कीजै हनुमान लला की । दुष्ट दलन रघुनाथ कला की ॥"
        ]  
    },
    {
        title:"श्री गंगा मैया आरती - ॐ जय गंगे माता",
        description:[
            "ॐ जय गंगे माता, श्री जय गंगे माता । जो नर तुमको ध्याता, मनवांछित फल पाता ॥ ॐ जय गंगे माता..॥",
            "चंद्र सी जोत तुम्हारी, जल निर्मल आता । शरण पडें जो तेरी, सो नर तर जाता ॥ ॐ जय गंगे माता..॥",
            "पुत्र सगर के तारे, सब जग को ज्ञाता । कृपा दृष्टि तुम्हारी, त्रिभुवन सुख दाता ॥ ॐ जय गंगे माता..॥",
            "एक ही बार जो तेरी, शारणागति आता । यम की त्रास मिटा कर, परमगति पाता ॥ ॐ जय गंगे माता..॥",
            "आरती मात तुम्हारी, जो जन नित्य गाता । दास वही सहज में, मुक्त्ति को पाता ॥ ॐ जय गंगे माता..॥",
            "ॐ जय गंगे माता, श्री जय गंगे माता । जो नर तुमको ध्याता, मनवांछित फल पाता ॥ ॐ जय गंगे माता..॥"
        ]
    },
    {
        title:"माता गायत्री जी की आरती - जयति जय गायत्री माता",
        description:[
            "जयति जय गायत्री माता, जयति जय गायत्री माता । सत् मारग पर हमें चलाओ, जो है सुखदाता ॥",
            "जयति जय गायत्री माता..॥",
            "आदि शक्ति तुम अलख निरंजन जगपालक क‌र्त्री । दु:ख शोक, भय, क्लेश कलश दारिद्र दैन्य हत्री ॥",
            "जयति जय गायत्री माता..॥",
            "ब्रह्म रूपिणी, प्रणात पालिन जगत धातृ अम्बे । भव भयहारी, जन-हितकारी, सुखदा जगदम्बे ॥",
            "जयति जय गायत्री माता..॥",
            "भय हारिणी, भवतारिणी, अनघेअज आनन्द राशि । अविकारी, अखहरी, अविचलित, अमले, अविनाशी ॥",
            "जयति जय गायत्री माता..॥",
            "कामधेनु सतचित आनन्द जय गंगा गीता । सविता की शाश्वती, शक्ति तुम सावित्री सीता ॥",
            "जयति जय गायत्री माता..॥",
            "ऋग, यजु साम, अथर्व प्रणयनी, प्रणव महामहिमे । कुण्डलिनी सहस्त्र सुषुमन शोभा गुण गरिमे ॥",
            "जयति जय गायत्री माता..॥",
            "स्वाहा, स्वधा, शची ब्रह्माणी राधा रुद्राणी । जय सतरूपा, वाणी, विद्या, कमला कल्याणी ॥",
            "जयति जय गायत्री माता..॥",
            "जननी हम हैं दीन-हीन, दु:ख-दरिद्र के घेरे । यदपि कुटिल, कपटी कपूत तउ बालक हैं तेरे ॥",
            "जयति जय गायत्री माता..॥",
            "स्नेहसनी करुणामय माता चरण शरण दीजै । विलख रहे हम शिशु सुत तेरे दया दृष्टि कीजै ॥",
            "जयति जय गायत्री माता..॥",
            "काम, क्रोध, मद, लोभ, दम्भ, दुर्भाव द्वेष हरिये । शुद्ध बुद्धि निष्पाप हृदय मन को पवित्र करिये ॥",
            "जयति जय गायत्री माता..॥",
            "जयति जय गायत्री माता, जयति जय गायत्री माता । सत् मारग पर हमें चलाओ, जो है सुखदाता ॥"
        ] 
    },
    {
        title:"श्री शनि देव जी की आरती - आरती कीजै नरसिंह कुंवर की",
        description:[
            "आरती कीजै नरसिंह कुंवर की । वेद विमल यश गाउँ मेरे प्रभुजी ॥",
            "पहली आरती प्रह्लाद उबारे । हिरणाकुश नख उदर विदारे ॥",
            "दुसरी आरती वामन सेवा । बल के द्वारे पधारे हरि देवा ॥",
            "तीसरी आरती ब्रह्म पधारे । सहसबाहु के भुजा उखारे ॥",
            "चौथी आरती असुर संहारे । भक्त विभीषण लंक पधारे ॥",
            "पाँचवीं आरती कंस पछारे । गोपी ग्वाल सखा प्रतिपाले ॥",
            "तुलसी को पत्र कंठ मणि हीरा । हरषि-निरखि गावे दास कबीरा ॥"
        ]      
    },
    {
        title:"सन्तोषी माता आरती - जय सन्तोषी माता",
        description:[
            "जय सन्तोषी माता, मैया जय सन्तोषी माता । अपने सेवक जन की, सुख सम्पति दाता ॥",
            "जय सन्तोषी माता, मैया जय सन्तोषी माता ॥",
            "सुन्दर चीर सुनहरी, मां धारण कीन्हो । हीरा पन्ना दमके, तन श्रृंगार लीन्हो ॥",
            "जय सन्तोषी माता, मैया जय सन्तोषी माता ॥",
            "गेरू लाल छटा छबि, बदन कमल सोहे । मंद हंसत करुणामयी, त्रिभुवन जन मोहे ॥",
            "जय सन्तोषी माता, मैया जय सन्तोषी माता ॥",
            "स्वर्ण सिंहासन बैठी, चंवर दुरे प्यारे । धूप, दीप, मधु, मेवा, भोज धरे न्यारे ॥",
            "जय सन्तोषी माता, मैया जय सन्तोषी माता ॥",
            "गुड़ अरु चना परम प्रिय, तामें संतोष कियो । संतोषी कहलाई, भक्तन वैभव दियो ॥",
            "जय सन्तोषी माता, मैया जय सन्तोषी माता ॥",
            "शुक्रवार प्रिय मानत, आज दिवस सोही । भक्त मंडली छाई, कथा सुनत मोही ॥",
            "जय सन्तोषी माता, मैया जय सन्तोषी माता ॥",
            "मंदिर जग मग ज्योति, मंगल ध्वनि छाई । विनय करें हम सेवक, चरनन सिर नाई ॥",
            "जय सन्तोषी माता, मैया जय सन्तोषी माता ॥",
            "भक्ति भावमय पूजा, अंगीकृत कीजै । जो मन बसे हमारे, इच्छित फल दीजै ॥",
            "जय सन्तोषी माता, मैया जय सन्तोषी माता ॥",
            "दुखी दारिद्री रोगी, संकट मुक्त किए । बहु धन धान्य भरे घर, सुख सौभाग्य दिए ॥",
            "जय सन्तोषी माता, मैया जय सन्तोषी माता ॥",
            "ध्यान धरे जो तेरा, वांछित फल पायो । पूजा कथा श्रवण कर, घर आनन्द आयो ॥",
            "जय सन्तोषी माता, मैया जय सन्तोषी माता ॥",
            "चरण गहे की लज्जा, रखियो जगदम्बे । संकट तू ही निवारे, दयामयी अम्बे ॥",
            "जय सन्तोषी माता, मैया जय सन्तोषी माता ॥",
            "सन्तोषी माता की आरती, जो कोई जन गावे । रिद्धि सिद्धि सुख सम्पति, जी भर के पावे ॥",
            "जय सन्तोषी माता, मैया जय सन्तोषी माता । अपने सेवक जन की, सुख सम्पति दाता ॥",
            "जय सन्तोषी माता, मैया जय सन्तोषी माता ॥",
        ]
    },
    {
        title:"श्री बृहस्पति देव की आरती - जय वृहस्पति देवा",
        description:[
            "जय वृहस्पति देवा, ऊँ जय वृहस्पति देवा । छिन छिन भोग लगा‌ऊँ, कदली फल मेवा ॥",
            "ऊँ जय वृहस्पति देवा, जय वृहस्पति देवा ॥",
            "तुम पूरण परमात्मा, तुम अन्तर्यामी । जगतपिता जगदीश्वर, तुम सबके स्वामी ॥",
            "ऊँ जय वृहस्पति देवा, जय वृहस्पति देवा ॥",
            "चरणामृत निज निर्मल, सब पातक हर्ता । सकल मनोरथ दायक, कृपा करो भर्ता ॥",
            "ऊँ जय वृहस्पति देवा, जय वृहस्पति देवा ॥",
            "तन, मन, धन अर्पण कर, जो जन शरण पड़े । प्रभु प्रकट तब होकर, आकर द्घार खड़े ॥",
            "ऊँ जय वृहस्पति देवा, जय वृहस्पति देवा ॥",
            "दीनदयाल दयानिधि, भक्तन हितकारी । पाप दोष सब हर्ता, भव बंधन हारी ॥",
            "ऊँ जय वृहस्पति देवा, जय वृहस्पति देवा ॥",
            "सकल मनोरथ दायक, सब संशय हारो । विषय विकार मिटा‌ओ, संतन सुखकारी ॥",
            "ऊँ जय वृहस्पति देवा, जय वृहस्पति देवा ॥",
            "जो को‌ई आरती तेरी, प्रेम सहित गावे । जेठानन्द आनन्दकर, सो निश्चय पावे ॥",
            "ऊँ जय वृहस्पति देवा, जय वृहस्पति देवा ॥"
        ]
    },
    {
        title:"लक्ष्मीजी की आरती - जय लक्ष्मी माता",
        description:[
            "ॐ जय लक्ष्मी माता, मैया जय लक्ष्मी माता । तुमको निसदिन सेवत, हर विष्णु विधाता ॥ ॐ जय लक्ष्मी माता...॥",
            "उमा, रमा, ब्रम्हाणी, तुम ही जग माता । सूर्य चद्रंमा ध्यावत, नारद ऋषि गाता ॥ ॐ जय लक्ष्मी माता...॥",
            "दुर्गा रुप निरंजनि, सुख-संपत्ति दाता । जो कोई तुमको ध्याता, ऋद्धि-सिद्धि धन पाता ॥ ॐ जय लक्ष्मी माता...॥",
            "तुम ही पाताल निवासनी, तुम ही शुभदाता । कर्म-प्रभाव-प्रकाशनी, भव निधि की त्राता ॥ ॐ जय लक्ष्मी माता...॥",
            "जिस घर तुम रहती हो, ताँहि में हैं सद्‍गुण आता । सब सभंव हो जाता, मन नहीं घबराता ॥ ॐ जय लक्ष्मी माता...॥",
            "तुम बिन यज्ञ ना होता, वस्त्र न कोई पाता । खान पान का वैभव, सब तुमसे आता ॥ ॐ जय लक्ष्मी माता...॥",
            "शुभ गुण मंदिर सुंदर, क्षीरोदधि जाता । रत्न चतुर्दश तुम बिन, कोई नहीं पाता ॥ ॐ जय लक्ष्मी माता...॥",
            "महालक्ष्मी जी की आरती, जो कोई नर गाता । उँर आंनद समाता, पाप उतर जाता ॥ ॐ जय लक्ष्मी माता...॥",
            "ॐ जय लक्ष्मी माता, मैया जय लक्ष्मी माता । तुमको निसदिन सेवत, हर विष्णु विधाता ॥ ॐ जय लक्ष्मी माता...॥"
        ]
    },
    {
        title:"नृसिंह भगवान जी की आरती - ॐ जय नरसिंह हरे",
        description:[
            "ॐ जय नरसिंह हरे, प्रभु जय नरसिंह हरे । स्तंभ फाड़ प्रभु प्रकटे, स्तंभ फाड़ प्रभु प्रकटे, जनका ताप हरे ॥",
            "तुम हो दिन दयाला, भक्तन हितकारी, प्रभु भक्तन हितकारी । अद्भुत रूप बनाकर, अद्भुत रूप बनाकर, प्रकटे भय हारी ॥ ॐ जय नरसिंह हरे ॥",
            "सबके ह्रदय विदारण, दुस्यु जियो मारी, प्रभु दुस्यु जियो मारी । दास जान आपनायो, दास जान आपनायो, जनपर कृपा करी ॥ ॐ जय नरसिंह हरे ॥",
            "ब्रह्मा करत आरती, माला पहिनावे, प्रभु माला पहिनावे । शिवजी जय जय कहकर, पुष्पन बरसावे ॥ ॐ जय नरसिंह हरे ॥"
        ]
    },
    {
        title:"श्री बालाजी जी की आरती - ॐ जय हनुमत वीरा",
        description:[
            "ॐ जय हनुमत वीरा, स्वामी जय हनुमत वीरा । संकट मोचन स्वामी, तुम हो रनधीरा ॥",
            "पवन पुत्र अंजनी सूत, महिमा अति भारी । दुःख दरिद्र मिटाओ, संकट सब हारी ॥ ॐ जय हनुमत वीरा..॥",
            "बाल समय में तुमने, रवि को भक्ष लियो । देवन स्तुति किन्ही, तुरतहिं छोड़ दियो ॥ ॐ जय हनुमत वीरा..॥",
            "कपि सुग्रीव राम संग, मैत्री करवाई। अभिमानी बलि मेटयो, कीर्ति रही छाई ॥ ॐ जय हनुमत वीरा..॥",
            "जारि लंक सिय-सुधि ले आए, वानर हर्षाये । कारज कठिन सुधारे, रघुबर मन भाये ॥ ॐ जय हनुमत वीरा..॥",
            "शक्ति लगी लक्ष्मण को, भारी सोच भयो । लाय संजीवन बूटी, दुःख सब दूर कियो ॥ ॐ जय हनुमत वीरा..॥",
            "रामहि ले अहिरावण, जब पाताल गयो । ताहि मारी प्रभु लाय, जय जयकार भयो ॥ ॐ जय हनुमत वीरा..॥",
            "राजत मेहंदीपुर में, दर्शन सुखकारी । मंगल और शनिश्चर, मेला है जारी ॥ ॐ जय हनुमत वीरा..॥",
            "श्री बालाजी की आरती, जो कोई नर गावे । कहत इन्द्र हर्षित, मनवांछित फल पावे ॥ ॐ जय हनुमत वीरा..॥"
        ]
    },
    {
        title:"तुलसी जी की आरती - महारानी नमो-नमो",
        description: [
            "तुलसी महारानी नमो-नमो, हरि की पटरानी नमो-नमो ।",
            "धन तुलसी पूरण तप कीनो, शालिग्राम बनी पटरानी । जाके पत्र मंजरी कोमल, श्रीपति कमल चरण लपटानी ॥",
            "तुलसी महारानी नमो-नमो, हरि की पटरानी नमो-नमो ।",
            "धूप-दीप-नवैद्य आरती, पुष्पन की वर्षा बरसानी । छप्पन भोग छत्तीसों व्यंजन, बिन तुलसी हरि एक ना मानी ॥",
            "तुलसी महारानी नमो-नमो, हरि की पटरानी नमो-नमो ।",
            "सभी सखी मैया तेरो यश गावें, भक्तिदान दीजै महारानी । नमो-नमो तुलसी महारानी, तुलसी महारानी नमो-नमो ॥",
            "तुलसी महारानी नमो-नमो, हरि की पटरानी नमो-नमो ।"
        ]
    },
    {
        title:"शीतला माता की आरती - जय शीतला माता",
        description:[
            "जय शीतला माता, मैया जय शीतला माता ।",
            "आदि ज्योति महारानी, सब फल की दाता ॥",
            "ॐ जय शीतला माता..॥",
            "रतन सिंहासन शोभित, श्वेत छत्र भाता । ऋद्धि-सिद्धि चँवर ढुलावें, जगमग छवि छाता ॥",
            "ॐ जय शीतला माता, मैया जय शीतला माता ।",
            "विष्णु सेवत ठाढ़े, सेवें शिव धाता । वेद पुराण वरणत, पार नहीं पाता ॥",
            "ॐ जय शीतला माता, मैया जय शीतला माता ।",
            "इन्द्र मृदङ्ग बजावत, चन्द्र वीणा हाथा । सूरज ताल बजावै, नारद मुनि गाता ॥",
            "ॐ जय शीतला माता, मैया जय शीतला माता ।",
            "घण्टा शङ्ख शहनाई, बाजै मन भाता । करै भक्तजन आरती, लखि लखि हर्षाता ॥",
            "ॐ जय शीतला माता, मैया जय शीतला माता ।",
            "ब्रह्म रूप वरदानी, तुही तीन काल ज्ञाता । भक्तन को सुख देती, मातु पिता भ्राता ॥",
            "ॐ जय शीतला माता, मैया जय शीतला माता ।",
            "जो जन ध्यान लगावे, प्रेम शक्ति पाता । सकल मनोरथ पावे, भवनिधि तर जाता ॥",
            "ॐ जय शीतला माता, मैया जय शीतला माता ।",
            "रोगों से जो पीड़ित कोई, शरण तेरी आता । कोढ़ी पावे निर्मल काया, अन्ध नेत्र पाता ॥",
            "ॐ जय शीतला माता, मैया जय शीतला माता ।",
            "बांझ पुत्र को पावे, दारिद्र कट जाता । ताको भजै जो नाहीं, सिर धुनि पछताता ॥",
            "ॐ जय शीतला माता, मैया जय शीतला माता ।",
            "शीतल करती जननी, तू ही है जग त्राता । उत्पत्ति व्याधि बिनाशन, तू सब की घाता ॥",
            "ॐ जय शीतला माता, मैया जय शीतला माता ।",
            "दास विचित्र कर जोड़े, सुन मेरी माता । भक्ति आपनी दीजै, और न कुछ भाता ॥",
            "जय शीतला माता, मैया जय शीतला माता । आदि ज्योति महारानी, सब फल की दाता ॥",
            "ॐ जय शीतला माता..॥"
        ]
        
    },
    {
        title:"भगवान श्री चित्रगुप्त जी की आरती - ॐ जय चित्रगुप्त हरे",
        description:[
            "ॐ जय चित्रगुप्त हरे, स्वामीजय चित्रगुप्त हरे । भक्तजनों के इच्छित, फलको पूर्ण करे॥",
            "विघ्न विनाशक मंगलकर्ता, सन्तनसुखदायी । भक्तों के प्रतिपालक, त्रिभुवनयश छायी ॥",
            "ॐ जय चित्रगुप्त हरे...॥",
            "रूप चतुर्भुज, श्यामल मूरत, पीताम्बरराजै । मातु इरावती, दक्षिणा, वामअंग साजै ॥",
            "ॐ जय चित्रगुप्त हरे...॥",
            "कष्ट निवारक, दुष्ट संहारक, प्रभुअंतर्यामी । सृष्टि सम्हारन, जन दु:ख हारन, प्रकटभये स्वामी ॥",
            "ॐ जय चित्रगुप्त हरे...॥",
            "कलम, दवात, शंख, पत्रिका, करमें अति सोहै । वैजयन्ती वनमाला, त्रिभुवनमन मोहै ॥",
            "ॐ जय चित्रगुप्त हरे...॥",
            "विश्व न्याय का कार्य सम्भाला, ब्रम्हाहर्षाये । कोटि कोटि देवता तुम्हारे, चरणनमें धाये ॥",
            "ॐ जय चित्रगुप्त हरे...॥",
            "नृप सुदास अरू भीष्म पितामह, यादतुम्हें कीन्हा । वेग, विलम्ब न कीन्हौं, इच्छितफल दीन्हा ॥",
            "ॐ जय चित्रगुप्त हरे...॥",
            "दारा, सुत, भगिनी, सबअपने स्वास्थ के कर्ता । जाऊँ कहाँ शरण में किसकी, तुमतज मैं भर्ता ॥",
            "ॐ जय चित्रगुप्त हरे...॥",
            "बन्धु, पिता तुम स्वामी, शरणगहूँ किसकी । तुम बिन और न दूजा, आसकरूँ जिसकी ॥",
            "ॐ जय चित्रगुप्त हरे...॥",
            "जो जन चित्रगुप्त जी की आरती, प्रेम सहित गावैं । चौरासी से निश्चित छूटैं, इच्छित फल पावैं ॥",
            "ॐ जय चित्रगुप्त हरे...॥",
            "न्यायाधीश बैंकुंठ निवासी, पापपुण्य लिखते । 'नानक' शरण तिहारे, आसन दूजी करते ॥",
            "ॐ जय चित्रगुप्त हरे, स्वामीजय चित्रगुप्त हरे । भक्तजनों के इच्छित, फलको पूर्ण करे ॥"
        ]
        
    },
    {
        title:"श्री ललिता माता की आरती - श्री मातेश्वरी जय त्रिपुरेश्वरी",
        description:[
            "श्री मातेश्वरी जय त्रिपुरेश्वरी । राजेश्वरी जय नमो नमः ॥",
            "करुणामयी सकल अघ हारिणी । अमृत वर्षिणी नमो नमः ॥",
            "जय शरणं वरणं नमो नमः । श्री मातेश्वरी जय त्रिपुरेश्वरी ॥",
            "अशुभ विनाशिनी, सब सुख दायिनी । खल-दल नाशिनी नमो नमः ॥",
            "भण्डासुर वधकारिणी जय माँ । करुणा कलिते नमो नम: ॥",
            "जय शरणं वरणं नमो नमः । श्री मातेश्वरी जय त्रिपुरेश्वरी ॥",
            "भव भय हारिणी, कष्ट निवारिणी । शरण गति दो नमो नमः ॥",
            "शिव भामिनी साधक मन हारिणी । आदि शक्ति जय नमो नमः ॥",
            "जय शरणं वरणं नमो नमः । जय त्रिपुर सुन्दरी नमो नमः ॥",
            "श्री मातेश्वरी जय त्रिपुरेश्वरी । राजेश्वरी जय नमो नमः ॥"
        ]
        
    },
    {
        title:"नर्मदा जी की आरती - ॐ जय जगदानन्दी",
        description:[
            "ॐ जय जगदानन्दी, मैया जय आनंद कन्दी । ब्रह्मा हरिहर शंकर, रेवा शिव हर‍ि शंकर, रुद्रौ पालन्ती ॥",
            "देवी नारद सारद तुम वरदायक, अभिनव पदण्डी । सुर नर मुनि जन सेवत, सुर नर मुनि... शारद पदवाचन्ती ॥",
            "देवी धूमक वाहन राजत, वीणा वाद्यन्ती । झुमकत-झुमकत-झुमकत, झननन झमकत रमती राजन्ती ॥",
            "देवी बाजत ताल मृदंगा, सुर मण्डल रमती । तोड़ीतान-तोड़ीतान-तोड़ीतान, तुरड़ड़ रमती सुरवन्ती ॥",
            "देवी सकल भुवन पर आप विराजत, निशदिन आनन्दी । गावत गंगा शंकर, सेवत रेवा शंकर तुम भट मेटन्ती ॥",
            "मैयाजी को कंचन थार विराजत, अगर कपूर बाती । अमर कंठ में विराजत, घाटन घाट बिराजत, कोटि रतन ज्योति ॥",
            "मैयाजी की आरती, निशदिन पढ़ गा‍वरि, हो रेवा जुग-जुग नरगावे, भजत शिवानन्द स्वामी जपत हर‍ि नंद स्वामी मनवांछित पावे।",
            "ॐ जय जगदानन्दी, मैया जय आनंद कन्दी । ब्रह्मा हरिहर शंकर, रेवा शिव हर‍ि शंकर, रुद्रौ पालन्ती"
        ]
        
    },
    {
        title:"सरस्वती जी की आरती - ॐ जय सरस्वती माता",
        description:[
            "ॐ जय सरस्वती माता, जय जय सरस्वती माता।",
            "सद्‍गुण वैभव शालिनी, त्रिभुवन विख्याता॥ जय..... ",
            "चंद्रवदनि पद्मासिनी, ध्रुति मंगलकारी।",
            "सोहें शुभ हंस सवारी, अतुल तेजधारी ॥ जय.....",
            "बाएं कर में वीणा, दाएं कर में माला।",
            "शीश मुकुट मणी सोहें, गल मोतियन माला ॥ जय.....",
            "देवी शरण जो आएं, उनका उद्धार किया।",
            "पैठी मंथरा दासी, रावण संहार किया ॥ जय.....",
            "विद्या ज्ञान प्रदायिनी, ज्ञान प्रकाश भरो।",
            "मोह, अज्ञान, तिमिर का जग से नाश करो ॥ जय.....",
            "धूप, दीप, फल, मेवा मां स्वीकार करो।",
            "ज्ञानचक्षु दे माता, जग निस्तार करो ॥ जय.....",
            "मां सरस्वती की आरती जो कोई जन गावें।",
            "हितकारी, सुखकारी, ज्ञान भक्ती पावें ॥ जय.....",
            "जय सरस्वती माता, जय जय सरस्वती माता।",
            "सद्‍गुण वैभव शालिनी, त्रिभुवन विख्याता॥ जय…"
        ]
        
    },
    {
        title:"आरती श्री रामायण जी की - कीरति कलित ललित सिय पी की ",
        description:[
            "आरती श्री रामायण जी की ।",
            "कीरति कलित ललित सिय पी की ॥",
            "गावत ब्रहमादिक मुनि नारद ।",
            "बाल्मीकि बिग्यान बिसारद ॥",
            "शुक सनकादिक शेष अरु शारद ।",
            "बरनि पवनसुत कीरति नीकी ॥",
            "॥ आरती श्री रामायण जी की..॥",
            "गावत बेद पुरान अष्टदस ।",
            "छओं शास्त्र सब ग्रंथन को रस ॥",
            "मुनि जन धन संतान को सरबस ।",
            "सार अंश सम्मत सब ही की ॥",
            "॥ आरती श्री रामायण जी की..॥",
            "गावत संतत शंभु भवानी ।",
            "अरु घटसंभव मुनि बिग्यानी ॥",
            "ब्यास आदि कबिबर्ज बखानी ।",
            "कागभुशुंडि गरुड़ के ही की ॥",
            "॥ आरती श्री रामायण जी की..॥",
            "कलिमल हरनि बिषय रस फीकी ।",
            "सुभग सिंगार मुक्ति जुबती की ॥",
            "दलनि रोग भव मूरि अमी की ।",
            "तात मातु सब बिधि तुलसी की ॥",
            "आरती श्री रामायण जी की ।",
            "कीरति कलित ललित सिय पी की ॥"
        ]
        
    },
    {
        title:"भगवद्‍ गीता आरती - जय भगवद् गीते",
        description:[
            "जय भगवद् गीते, जय भगवद् गीते ।",
            "हरि-हिय-कमल-विहारिणि, सुन्दर सुपुनीते ॥",
            "कर्म-सुमर्म-प्रकाशिनि, कामासक्तिहरा ।",
            "तत्त्वज्ञान-विकाशिनि, विद्या ब्रह्म परा ॥",
            "जय भगवद् गीते...॥",
            "निश्चल-भक्ति-विधायिनि, निर्मल मलहारी ।",
            "शरण-सहस्य-प्रदायिनि, सब विधि सुखकारी ॥",
            "जय भगवद् गीते...॥",
            "राग-द्वेष-विदारिणि, कारिणि मोद सदा ।",
            "भव-भय-हारिणि, तारिणि परमानन्दप्रदा ॥",
            "जय भगवद् गीते...॥",
            "आसुर-भाव-विनाशिनि, नाशिनि तम रजनी ।",
            "दैवी सद् गुणदायिनि, हरि-रसिका सजनी ॥",
            "जय भगवद् गीते...॥",
            "समता, त्याग सिखावनि, हरि-मुख की बानी ।",
            "सकल शास्त्र की स्वामिनी, श्रुतियों की रानी ॥",
            "जय भगवद् गीते...॥",
            "दया-सुधा बरसावनि, मातु! कृपा कीजै ।",
            "हरिपद-प्रेम दान कर, अपनो कर लीजै ॥",
            "जय भगवद् गीते...॥",
            "जय भगवद् गीते, जय भगवद् गीते ।",
            "हरि-हिय-कमल-विहारिणि, सुन्दर सुपुनीते ॥",
            "जय भगवद् गीते, जय भगवद् गीते ॥"
        ] 
    },
    {
        title:"विश्वकर्मा जी की आरती",
        description:[
            "जय श्री विश्वकर्मा प्रभु, जय श्री विश्वकर्मा | सकल सृष्टि के करता, रक्षक स्तुति धर्मा ॥",
            "जय श्री विश्वकर्मा प्रभु, जय श्री विश्वकर्मा | आदि सृष्टि मे विधि को, श्रुति उपदेश दिया । जीव मात्र का जग में, ज्ञान विकास किया ॥",
            "जय श्री विश्वकर्मा प्रभु, जय श्री विश्वकर्मा | ऋषि अंगीरा तप से, शांति नहीं पाई । ध्यान किया जब प्रभु का, सकल सिद्धि आई ॥",
            "जय श्री विश्वकर्मा प्रभु, जय श्री विश्वकर्मा | रोग ग्रस्त राजा ने, जब आश्रय लीना । संकट मोचन बनकर, दूर दुःखा कीना ॥",
            "जय श्री विश्वकर्मा प्रभु, जय श्री विश्वकर्मा | जब रथकार दंपति, तुम्हारी टेर करी । सुनकर दीन प्रार्थना, विपत सगरी हरी ॥",
            "जय श्री विश्वकर्मा प्रभु, जय श्री विश्वकर्मा | एकानन चतुरानन, पंचानन राजे। त्रिभुज चतुर्भुज दशभुज, सकल रूप साजे ॥",
            "जय श्री विश्वकर्मा प्रभु, जय श्री विश्वकर्मा | ध्यान धरे तब पद का, सकल सिद्धि आवे । मन द्विविधा मिट जावे, अटल शक्ति पावे ॥",
            "जय श्री विश्वकर्मा प्रभु, जय श्री विश्वकर्मा | श्री विश्वकर्मा की आरती, जो कोई गावे । भजत गजानांद स्वामी, सुख संपति पावे ॥",
            "जय श्री विश्वकर्मा प्रभु, जय श्री विश्वकर्मा | सकल सृष्टि के करता, रक्षक स्तुति धर्मा ॥"
        ]
        
    },
    {
        title:"श्रीदेवीजी की आरती - जगजननी जय! जय",
        description:[
            "जगजननी जय! जय!!",
            "माँ! जगजननी जय! जय!!",
            "भयहारिणि, भवतारिणि, माँ भवभामिनि जय! जय ॥",
            "जगजननी जय जय..॥",
            "तू ही सत-चित-सुखमय, शुद्ध ब्रह्मरूपा । सत्य सनातन सुन्दर, पर-शिव सुर-भूपा ॥",
            "जगजननी जय जय..॥",
            "आदि अनादि अनामय, अविचल अविनाशी । अमल अनन्त अगोचर, अज आनँदराशी ॥",
            "जगजननी जय जय..॥",
            "अविकारी, अघहारी, अकल, कलाधारी । कर्त्ता विधि, भर्त्ता हरि, हर सँहारकारी ॥",
            "जगजननी जय जय..॥",
            "तू विधिवधू, रमा, तू उमा, महामाया । मूल प्रकृति विद्या तू, तू जननी, जाया ॥",
            "जगजननी जय जय..॥",
            "राम, कृष्ण तू, सीता, व्रजरानी राधा । तू वांछाकल्पद्रुम, हारिणि सब बाधा ॥",
            "जगजननी जय जय..॥",
            "दश विद्या, नव दुर्गा, नानाशस्त्रकरा । अष्टमातृका, योगिनि, नव नव रूप धरा ॥",
            "जगजननी जय जय..॥",
            "तू परधामनिवासिनि, महाविलासिनि तू । तू ही श्मशानविहारिणि, ताण्डवलासिनि तू ॥",
            "जगजननी जय जय..॥",
            "सुर-मुनि-मोहिनि सौम्या, तू शोभाऽऽधारा । विवसन विकट-सरुपा, प्रलयमयी धारा ॥",
            "जगजननी जय जय..॥",
            "तू ही स्नेह-सुधामयि, तू अति गरलमना । रत्‍‌नविभूषित तू ही, तू ही अस्थि-तना ॥",
            "जगजननी जय जय..॥",
            "मूलाधारनिवासिनि, इह-पर-सिद्धिप्रदे । कालातीता काली, कमला तू वरदे ॥",
            "जगजननी जय जय..॥",
            "शक्ति शक्तिधर तू ही, नित्य अभेदमयी । भेदप्रदर्शिनि वाणी, विमले! वेदत्रयी ॥",
            "जगजननी जय जय..॥",
            "हम अति दीन दुखी माँ!, विपत-जाल घेरे । हैं कपूत अति कपटी, पर बालक तेरे ॥",
            "जगजननी जय जय..॥",
            "निज स्वभाववश जननी!, दयादृष्टि कीजै । करुणा कर करुणामयि! चरण-शरण दीजै ॥",
            "जगजननी जय जय..॥",
            "जगजननी जय! जय!!",
            "माँ! जगजननी जय! जय!!",
            "भयहारिणि, भवतारिणि, माँ भवभामिनि जय! जय ॥",
            "जगजननी जय जय..॥"
        ]
        
    },
    {
        title:"श्री बांके बिहारी तेरी आरती गाऊं - आरती ",
        description:[
            "श्री बांके बिहारी तेरी आरती गाऊं, हे गिरिधर तेरी आरती गाऊं ।",
            "आरती गाऊं प्यारे आपको रिझाऊं, श्याम सुन्दर तेरी आरती गाऊं ।",
            "॥ श्री बांके बिहारी तेरी आरती गाऊं..॥",
            "मोर मुकुट प्यारे शीश पे सोहे, प्यारी बंसी मेरो मन मोहे ।",
            "देख छवि बलिहारी मैं जाऊं ।",
            "॥ श्री बांके बिहारी तेरी आरती गाऊं..॥",
            "चरणों से निकली गंगा प्यारी, जिसने सारी दुनिया तारी ।",
            "मैं उन चरणों के दर्शन पाऊं ।",
            "॥ श्री बांके बिहारी तेरी आरती गाऊं..॥",
            "दास अनाथ के नाथ आप हो, दुःख सुख जीवन प्यारे साथ आप हो ।",
            "हरी चरणों में शीश झुकाऊं ।",
            "॥ श्री बांके बिहारी तेरी आरती गाऊं..॥",
            "श्री हरीदास के प्यारे तुम हो ।",
            "मेरे मोहन जीवन धन हो।",
            "देख युगल छवि बलि बलि जाऊं ।",
            "॥ श्री बांके बिहारी तेरी आरती गाऊं..॥",
            "श्री बांके बिहारी तेरी आरती गाऊं, हे गिरिधर तेरी आरती गाऊं ।",
            "आरती गाऊं प्यारे आपको रिझाऊं, श्याम सुन्दर तेरी आरती गाऊं ।"
        ]
        
    },
    {
        title:"जय शनि देवा - श्री शनिदेव आरती",
        description:[
            "जय शनि देवा, जय शनि देवा, जय जय जय शनि देवा ।",
            "अखिल सृष्टि में कोटि-कोटि जन, करें तुम्हारी सेवा ।",
            "जय शनि देवा, जय शनि देवा, जय जय जय शनि देवा ॥",
            "जा पर कुपित होउ तुम स्वामी, घोर कष्ट वह पावे ।",
            "धन वैभव और मान-कीर्ति, सब पलभर में मिट जावे ।",
            "राजा नल को लगी शनि दशा, राजपाट हर लेवा ।",
            "जय शनि देवा, जय शनि देवा, जय जय जय शनि देवा ॥",
            "जा पर प्रसन्न होउ तुम स्वामी, सकल सिद्धि वह पावे ।",
            "तुम्हारी कृपा रहे तो, उसको जग में कौन सतावे ।",
            "ताँबा, तेल और तिल से जो, करें भक्तजन सेवा ।",
            "जय शनि देवा, जय शनि देवा, जय जय जय शनि देवा ॥",
            "हर शनिवार तुम्हारी, जय-जय कार जगत में होवे ।",
            "कलियुग में शनिदेव महात्तम, दु:ख दरिद्रता धोवे ।",
            "करू आरती भक्ति भाव से, भेंट चढ़ाऊं मेवा ।",
            "जय शनि देवा, जय शनि देवा, जय जय जय शनि देवा ॥"
        ]
        
    },
    {
        title:"श्री भैरव देव जी आरती - जय भैरव देवा, प्रभु जय भैरव देवा ",
        description:[
            "जय भैरव देवा, प्रभु जय भैरव देवा ।",
            "जय काली और गौर देवी कृत सेवा ॥",
            "॥ जय भैरव देवा...॥",
            "तुम्ही पाप उद्धारक दुःख सिन्धु तारक ।",
            "भक्तो के सुख कारक भीषण वपु धारक ॥",
            "॥ जय भैरव देवा...॥",
            "वाहन श्वान विराजत कर त्रिशूल धारी ।",
            "महिमा अमित तुम्हारी जय जय भयहारी ॥",
            "॥ जय भैरव देवा...॥",
            "तुम बिन देवा सेवा सफल नहीं होवे ।",
            "चौमुख दीपक दर्शन दुःख खोवे ॥",
            "॥ जय भैरव देवा...॥",
            "तेल चटकी दधि मिश्रित भाषावाली तेरी ।",
            "कृपा कीजिये भैरव, करिए नहीं देरी ॥",
            "॥ जय भैरव देवा...॥",
            "पाँव घुँघरू बाजत अरु डमरू दम्कावत ।",
            "बटुकनाथ बन बालक जल मन हरषावत ॥",
            "॥ जय भैरव देवा...॥",
            "बटुकनाथ जी की आरती जो कोई नर गावे ।",
            "कहे धरनी धर नर मनवांछित फल पावे ॥",
            "॥ जय भैरव देवा...॥"
        ]
        
    },
    {
        title:"पार्वती माता की आरती - जय पार्वती माता",
        description: [
            "जय पार्वती माता, जय पार्वती माता",
            "ब्रह्मा सनातन देवी, शुभ फल की दाता ।",
            "॥ जय पार्वती माता... ॥",
            "अरिकुल कंटक नासनि, निज सेवक त्राता,",
            "जगजननी जगदम्बा, हरिहर गुण गाता ।",
            "॥ जय पार्वती माता... ॥",
            "सिंह को वहान साजे,कुंडल है साथा,",
            "देव वधू जस गावत, नृत्य करत ता था ।",
            "॥ जय पार्वती माता... ॥",
            "सतयुग रूप शील अतिसुंदर,नाम सती कहलाता,",
            "हेमाचंल घर जन्मी, सखियाँ संगराता ।",
            "॥ जय पार्वती माता... ॥",
            "शुम्भ निशुम्भ विदारे, हेमाचंल स्थाता,",
            "सहस्त्र भुजा तनु धरिके, चक्र लियो हाथा ।",
            "॥ जय पार्वती माता... ॥",
            "सृष्टि रूप तुही है जननी, शिव संग रंगराता,",
            "नन्दी भृंगी बीन लही, सारा जग मदमाता ।",
            "॥ जय पार्वती माता... ॥",
            "देवन अरज करत हम, चरण ध्यान लाता,",
            "तेरी कृपा रहे तो, मन नहीं भरमाता ।",
            "॥ जय पार्वती माता... ॥",
            "मैया जी की आरती, भक्ति भाव से जो नर गाता,",
            "नित्य सुखी रह करके, सुख संपत्ति पाता ।",
            "॥ जय पार्वती माता... ॥",
            "जय पार्वती माता, जय पार्वती माता,",
            "ब्रह्मा सनातन देवी, शुभ फल की दाता ।",
            "जय पार्वती माता, जय पार्वती माता",
            "ब्रह्मा सनातन देवी, शुभ फल की दाता ।",
            "॥ जय पार्वती माता... ॥"
        ]
        
    },
    {
        title:"माँ महाकाली की आरती - जय काली माता",
        description:[
            "जय काली माता, माँ जय महा काली माँ।",
            "रतबीजा वध कारिणी माता।",
            "सुरनर मुनि ध्याता, माँ जय महा काली माँ॥",
            "दक्ष यज्ञ विदवंस करनी माँ शुभ निशूंभ हरलि।",
            "मधु और कैितभा नासिनी माता।",
            "महेशासुर मारदिनी, ओ माता जय महा काली माँ॥",
            "हे हीमा गिरिकी नंदिनी प्रकृति रचा इत्ठि।",
            "काल विनासिनी काली माता।",
            "सुरंजना सूख दात्री हे माता॥",
            "अननधम वस्तराँ दायनी माता आदि शक्ति अंबे।",
            "कनकाना कना निवासिनी माता।",
            "भगवती जगदंबे, ओ माता जय महा काली माँ॥",
            "दक्षिणा काली आध्या काली, काली नामा रूपा।",
            "तीनो लोक विचारिती माता धर्मा मोक्ष रूपा॥",
            "॥ जय महा काली माँ ॥"
        ]
        
    },
    {
        title:"वैष्णो माता आरती",
        description: [
            "जय वैष्णवी माता, मैया जय वैष्णवी माता ।",
            "हाथ जोड़ तेरे आगे, आरती मैं गाता ॥ जय वैष्णवी माता..॥",
            "शीश पे छत्र विराजे, मूरतिया प्यारी । गंगा बहती चरनन ज्योति जगे न्यारी ॥",
            "॥ जय वैष्णवी माता..॥",
            "ब्रह्मा वेद पढ़े नित द्वारे, शंकर ध्यान धरे । सेवक चंवर डुलावत, नारद नृत्य करे ॥",
            "॥ जय वैष्णवी माता..॥",
            "सुन्दर गुफा तुम्हारी, मन को अति भावे । बार-बार देखन को, ऐ माँ मन चावे ॥",
            "॥ जय वैष्णवी माता..॥",
            "भवन पे झण्डे झूलें, घंटा ध्वनि बाजे । ऊँचा पर्वत तेरा, माता प्रिय लागे ॥",
            "॥ जय वैष्णवी माता..॥",
            "पान सुपारी ध्वजा नारियल, भेंट पुष्प मेवा  दास खड़े चरणों में, दर्शन दो देवा ॥",
            "॥ जय वैष्णवी माता..॥",
            "जो जन निश्चय करके, द्वार तेरे आवे । उसकी इच्छा पूरण, माता हो जावे ॥",
            "॥ जय वैष्णवी माता..॥",
            "इतनी स्तुति निश-दिन, जो नर भी गावे । कहते सेवक ध्यानू, सुख सम्पत्ति पावे ॥",
            "जय वैष्णवी माता, मैया जय वैष्णवी माता ।",
            "हाथ जोड़ तेरे आगे, आरती मैं गाता ॥ ॥ जय वैष्णवी माता..॥"
        ]
        
    },
    {
        title:" श्री सूर्य देव जी की आरती - ऊँ जय कश्यप नन्दन",
        description: [
            "ऊँ जय कश्यप नन्दन, प्रभु जय अदिति नन्दन।",
            "त्रिभुवन तिमिर निकंदन, भक्त हृदय चन्दन॥",
            "॥ ऊँ जय कश्यप...॥",
            "सप्त अश्वरथ राजित, एक चक्रधारी।",
            "दु:खहारी, सुखकारी, मानस मलहारी॥",
            "॥ ऊँ जय कश्यप...॥",
            "सुर मुनि भूसुर वन्दित, विमल विभवशाली।",
            "अघ-दल-दलन दिवाकर, दिव्य किरण माली॥",
            "॥ ऊँ जय कश्यप...॥",
            "सकल सुकर्म प्रसविता, सविता शुभकारी।",
            "विश्व विलोचन मोचन, भव-बंधन भारी॥",
            "॥ ऊँ जय कश्यप...॥",
            "कमल समूह विकासक, नाशक त्रय तापा।",
            "सेवत सहज हरत अति, मनसिज संतापा॥",
            "॥ ऊँ जय कश्यप...॥",
            "नेत्र व्याधि हर सुरवर, भू-पीड़ा हारी।",
            "वृष्टि विमोचन संतत, परहित व्रतधारी॥",
            "॥ ऊँ जय कश्यप...॥",
            "सूर्यदेव करुणाकर, अब करुणा कीजै।",
            "हर अज्ञान मोह सब, तत्वज्ञान दीजै॥",
            "ऊँ जय कश्यप नन्दन, प्रभु जय अदिति नन्दन।",
            "त्रिभुवन तिमिर निकंदन, भक्त हृदय चन्दन॥"
        ]
        
    }
]























